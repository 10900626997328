import type {
  ConfiguratorFieldState,
  ConfiguratorStepType,
  ConfiguratorStoreState,
  SerializedConfigurationState,
  SerializedOperation,
  SerializedStep,
} from '@ui/features/configurator/types';
import type { ConfiguratorResourceOptionMeta } from '../../data/field-definitions/resource';
import { FIELD_IDS, STEP_KEYS } from '../../data/fixtures/cold-headed-fastener';
import { calculateOperationSequenceNumber } from '../helpers/index-helpers';
import { blueprintStepMap } from './blueprint-helpers';
import {
  ensureCalculatedNumberField,
  ensureDescriptionField,
  ensureNumberField,
  ensureSingleChoiceField,
  ensureTextField,
  getFieldByStepId,
  getNumberFieldValue,
  getOnlyFieldByStepKey,
  getTextareaFieldValue,
  maybeGetFieldByStepId,
} from './field-helpers';

export function serializeConfigurationState(
  state: ConfiguratorStoreState,
): SerializedConfigurationState {
  const quantityField = ensureNumberField(
    getOnlyFieldByStepKey(
      STEP_KEYS.base_fields,
      FIELD_IDS.quantity,
      state.fields,
      state.blueprint,
    ),
  );

  const customerPartIdField = ensureTextField(
    getOnlyFieldByStepKey(
      STEP_KEYS.base_fields,
      FIELD_IDS.customer_part_id,
      state.fields,
      state.blueprint,
    ),
  );

  const productCodeField = ensureSingleChoiceField(
    getOnlyFieldByStepKey(
      STEP_KEYS.base_fields,
      FIELD_IDS.product_code,
      state.fields,
      state.blueprint,
    ),
  );

  const descriptionField = ensureDescriptionField(
    getOnlyFieldByStepKey(
      STEP_KEYS.description,
      FIELD_IDS.description,
      state.fields,
      state.blueprint,
    ),
  );

  const costField = getOnlyFieldByStepKey(
    STEP_KEYS.computed_fields,
    FIELD_IDS.cost,
    state.fields,
    state.blueprint,
  );

  const volumeCalculations = serializeStep(state, 'volume_calculations');

  return {
    /**
     * @NOTE(shawk): it seems useful to pull some of our prerequisites out into
     * top-level properties in the serialized data, but I'd rather not duplicate
     * that data (it'll also be serialized as part of the `prerequisites` key
     * below).
     */
    quantity: quantityField.value ?? 0,
    customerPartId: customerPartIdField.value ?? '',
    productCode: productCodeField.value?.value ?? 'NA',
    description: descriptionField.value?.value ?? '',
    unitCost: getNumberFieldValue(costField, 0),
    operations: serializeOperations(state),
    volumeCalculations,
  };
}

function serializeOperations(
  state: ConfiguratorStoreState,
): SerializedOperation[] {
  return state.blueprint.operations.map(
    (operation, index): SerializedOperation => {
      const notesField = getFieldByStepId(
        operation.stepId,
        FIELD_IDS.notes,
        state.fields,
      );

      const sequenceNumber = calculateOperationSequenceNumber(index);
      const operationFullName = `${sequenceNumber} - ${operation.name}`;

      let resourceId = '';

      if (operation.operationType === 'internal') {
        const resourceField =
          ensureSingleChoiceField<ConfiguratorResourceOptionMeta>(
            getFieldByStepId(
              operation.stepId,
              FIELD_IDS.resource,
              state.fields,
            ),
          );

        if (!resourceField.value?.meta?.resourceId) {
          throw new Error(`Operation "${operationFullName}" has no resourceId`);
        }
        resourceId = resourceField.value.meta.resourceId;
      }

      if (!state.costs) {
        throw new Error('Costs have not been calculated');
      }

      if (!state.costs.output.resolvedOpCosts[index]) {
        throw new Error(
          `Operation "${operationFullName}" has no resolved costs input`,
        );
      }

      return {
        name: operation.name,
        sequenceNum: sequenceNumber,
        resourceId,
        description: operation.description,
        notes: getTextareaFieldValue(notesField, undefined),
        fields: operation.fieldKeys.map((fieldId) => {
          const field = state.fields[fieldId];

          return {
            name: field.name,
            description: field.description,
            inputType: field.inputType,
            value: field.value,
          };
        }),

        resolvedCostsInput: state.costs.output.resolvedOpCosts[index],

        // @TODO(shawk): pull these from the calculated costs
        costs: {
          // internal costs (from the Resource)
          setupCostPerHour: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.setup_cost_per_hour,
            state.fields,
          ),
          setupBurdenPerHour: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.setup_burden_per_hour,
            state.fields,
          ),
          runCostPerHour: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.run_cost_per_hour,
            state.fields,
          ),
          runBurdenPerHour: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.run_burden_per_hour,
            state.fields,
          ),

          // external costs
          baseCharge: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.base_charge,
            state.fields,
          ),
          minimumCharge: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.minimum_charge,
            state.fields,
          ),
          pricePerUnit: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.price_per_unit,
            state.fields,
          ),

          // estimated costs
          estimatedLaborCost: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.estimated_labor_cost,
            state.fields,
          ),
          estimatedMaterialCost: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.estimated_material_cost,
            state.fields,
          ),
          estimatedServiceCost: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.estimated_service_cost,
            state.fields,
          ),
          estimatedBurdenCost: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.estimated_burden_cost,
            state.fields,
          ),

          // aggregates (for the operation)
          contributionCost: getFieldValueForSerialization(
            operation.stepId,
            FIELD_IDS.contribution_cost,
            state.fields,
          ),
          grossCost: getCalculatedValueForSerialization(
            operation.stepId,
            FIELD_IDS.gross_cost,
            state.fields,
          ),
        },
      };
    },
  );
}

function getFieldValueForSerialization(
  stepId: string,
  fieldId: string,
  fields: ConfiguratorFieldState,
) {
  const field = maybeGetFieldByStepId(stepId, fieldId, fields);

  if (!field) {
    return 0;
  }

  return getNumberFieldValue(field, 0);
}

function getCalculatedValueForSerialization(
  stepId: string,
  fieldId: string,
  fields: ConfiguratorFieldState,
) {
  const field = maybeGetFieldByStepId(stepId, fieldId, fields);

  if (!field) {
    return 0;
  }

  return ensureCalculatedNumberField(field).value ?? 0;
}

function serializeStep(
  state: ConfiguratorStoreState,
  stepType: ConfiguratorStepType,
): SerializedStep {
  const steps = state.blueprint[blueprintStepMap[stepType]];

  return Object.fromEntries(
    steps.map((step) => {
      return [
        step.stepId,
        {
          name: step.name,
          stepId: step.stepId,
          description: step.description,
          fields: Object.fromEntries(
            step.fieldKeys.map((fieldId) => {
              const field = state.fields[fieldId];

              return [
                field.fieldId,
                {
                  name: field.name,
                  description: field.description,
                  inputType: field.inputType,
                  value: field.value,
                },
              ];
            }),
          ),
        },
      ];
    }),
  );
}
