import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Container,
  Flex,
  Image,
} from '@chakra-ui/react';
import logo from '../assets/logo.svg';

export function GlobalError() {
  return (
    <Flex
      bgColor="mw.black"
      minH="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxW="container.sm" centerContent p={8}>
        <Box maxW="md">
          <Alert
            bg="white"
            status="error"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            rounded={4}
            p={8}
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={2} fontSize="lg">
              Whoops!
            </AlertTitle>

            <AlertDescription mb={4}>
              Something went wrong, but we're on it.
              <br />A report has been forwarded to the team.
            </AlertDescription>

            <AlertDescription fontSize="x-small">
              Thank you for your patience. 🙏
            </AlertDescription>
          </Alert>

          <Center p={4}>
            <Image
              src={logo}
              alt="MW Components Logo"
              title="QuickQuote"
              maxWidth={'30px'}
            />
          </Center>
        </Box>
      </Container>
    </Flex>
  );
}
