import { ConfiguratorDisplay } from '@ui/features/configurator/components/inputs/Display';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { memo } from 'react';
import { getUntrackedObject } from 'react-tracked';
import { match } from 'ts-pattern';
import { ConfiguratorBooleanInput } from '../BooleanInput';
import { ConfiguratorCalculatedNumberInput } from '../CalculatedNumberInput';
import { ConfiguratorDescriptionInput } from '../DescriptionInput';
import { ConfiguratorMaterialInput } from '../MaterialInput';
import { ConfiguratorNumberInput } from '../NumberInput';
import { ConfiguratorOperationTypeInput } from '../OperationTypeInput';
import { ConfiguratorServiceInput } from '../ServiceInput';
import { ConfiguratorSingleChoiceInput } from '../SingleChoiceInput';
import { ConfiguratorTextInput } from '../TextInput';
import type { ConfiguratorWrapperInputProps } from '../types';
import { ConfiguratorFieldErrorBoundary } from './ErrorBoundary';

function ConfiguratorFieldComponent({
  fieldKey,
  ...props
}: ConfiguratorWrapperInputProps) {
  const { fields } = useConfiguratorStore();
  const field = fields[fieldKey];

  if (field.isHidden || field.isAlwaysHidden) {
    return null;
  }

  return (
    <ConfiguratorFieldErrorBoundary>
      {match(field)
        .with({ inputType: 'text' }, (field) => (
          <ConfiguratorTextInput field={field} {...props} />
        ))
        .with({ inputType: 'number' }, (field) => (
          <ConfiguratorNumberInput field={field} {...props} />
        ))
        .with({ inputType: 'calculated_number' }, (field) => (
          <ConfiguratorCalculatedNumberInput field={field} {...props} />
        ))
        .with({ inputType: 'single_choice' }, (field) => (
          <ConfiguratorSingleChoiceInput field={field} {...props} />
        ))
        .with({ inputType: 'boolean' }, (field) => (
          <ConfiguratorBooleanInput field={field} {...props} />
        ))
        .with({ inputType: 'operation_type' }, (field) => (
          <ConfiguratorOperationTypeInput field={field} {...props} />
        ))
        .with({ inputType: 'material' }, (field) => (
          <ConfiguratorMaterialInput field={field} {...props} />
        ))
        .with({ inputType: 'service' }, (field) => (
          <ConfiguratorServiceInput field={field} {...props} />
        ))
        .with({ inputType: 'description' }, (field) => (
          <ConfiguratorDescriptionInput field={field} {...props} />
        ))
        .with({ inputType: 'display' }, (field) => (
          <ConfiguratorDisplay field={field} {...props} />
        ))
        .otherwise(() => {
          if (import.meta.env.DEV) {
            console.warn(
              `Unknown input type "${field.inputType}"`,
              getUntrackedObject(field),
            );
          }

          return null;
        })}
    </ConfiguratorFieldErrorBoundary>
  );
}

export const ConfiguratorField = memo(ConfiguratorFieldComponent);
