import { paginationSchema } from '@lib/models/pagination';
import { z } from 'zod';
import type { SerializedConfigurationState } from '../models/configurator';

export const configuratorFieldOptionsQuerySchema = z
  .object({
    q: z.string().optional(),
    params: z
      .object({
        productCode: z.string().optional(),
        serviceId: z.string().optional(),
      })
      .default({}),
  })
  .merge(paginationSchema);

export type ConfiguratorFieldOptionsQuery = z.infer<
  typeof configuratorFieldOptionsQuerySchema
>;

export type ConfiguratorCreatePartRequest = SerializedConfigurationState & {
  quoteId: string;
  quoteUuid: string;
  lineNumber: number;
  configuratorSnapshotJSON: string;
};

export const ConfiguratorSnapshotGetManyQuery = paginationSchema;

export type ConfiguratorSnapshotGetManyQuery = z.infer<
  typeof ConfiguratorSnapshotGetManyQuery
>;

export const ConfiguratorSnapshotSearchQuery =
  ConfiguratorSnapshotGetManyQuery.extend({
    search: z.string().optional(),
    site: z.string().optional(),
  });
export type ConfiguratorSnapshotSearchQuery = z.infer<
  typeof ConfiguratorSnapshotSearchQuery
>;
