import { z } from 'zod';

// NOTE: (bb) Be extremely careful altering this schema as a custom database
//  migration may be required to update existing JSON records to stay valid
export const partOptionMetadataSchema = z.object({
  name: z.string(),
  code: z.string().nullable(),
  price: z.number().nullable(),
  minimumLotCharge: z.number().nullable(),
  lotPrices: z.array(
    z.object({
      qty: z.number(),
      unitPrice: z.number(),
    }),
  ),
  calculationMode: z.enum(['default', 'batch-weight', 'batch-quantity', 'lot']),
  subOptionLabel: z.string().nullable(),
  unitsPerBatch: z.number().nullable(),
  subOptions: z.array(
    z.object({
      name: z.string(),
      code: z.string().nullable(),
    }),
  ),
});

export type PartOptionMetadata = z.infer<typeof partOptionMetadataSchema>;
