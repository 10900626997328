import { reduce } from 'lodash-es';
import type { CalcCostsInput, ContributionCosts, CostBuckets } from './types';

export interface CostCalculations extends CostBuckets {
  /** Total combined contr costs */
  contributionCost: number;
  /** Total contr + total burd */
  grossCost: number;
}

/** @returns defaults to zeros */
export const calcCosts = (costs: CalcCostsInput): CostCalculations => {
  const {
    laborCost: labRaw,
    materialCost: matRaw,
    serviceCost: servcRaw,
    burdenCost: burRaw,
  } = costs;

  const laborCost = Number(labRaw) || 0;
  const materialCost = Number(matRaw) || 0;
  const serviceCost = Number(servcRaw) || 0;
  const burdenCost = Number(burRaw) || 0;

  const contrCosts: ContributionCosts = {
    laborCost,
    materialCost,
    serviceCost,
  };

  return {
    get contributionCost(): number {
      return reduce(contrCosts, (sum, cost) => sum + cost, 0);
    },
    get grossCost(): number {
      return this.burdenCost + this.contributionCost;
    },
    ...contrCosts,
    burdenCost,
  };
};

export const zeroCostCalcs = (): CostCalculations => ({
  laborCost: 0,
  materialCost: 0,
  serviceCost: 0,
  burdenCost: 0,
  contributionCost: 0,
  grossCost: 0,
});

export const aggCostCalcs = (
  agg: CostCalculations,
  calcs: CostCalculations,
): CostCalculations => {
  agg.laborCost += calcs.laborCost;
  agg.materialCost += calcs.materialCost;
  agg.serviceCost += calcs.serviceCost;
  agg.burdenCost += calcs.burdenCost;
  agg.contributionCost += calcs.contributionCost;
  agg.grossCost += calcs.grossCost;

  return agg;
};
