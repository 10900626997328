import { round } from '@lib/calculations';
import { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import { convertFromDisplayMagnitude } from '@lib/util/display-magnitude';
import type { DisplayMagnitude } from '@prisma/client';
import {
  type ConfiguratorCostsInput,
  type ConfiguratorCostsOpInput,
  type ConfiguratorCostsOutput,
  calculateConfiguratorCosts,
} from '@ui/features/configurator/calculations/cost';
import { getResourceFieldValue } from '@ui/features/configurator/data/field-definitions/resource';
import {
  FIELD_IDS,
  STEP_KEYS,
} from '@ui/features/configurator/data/fixtures/cold-headed-fastener';
import type {
  ConfiguratorBlueprintState,
  ConfiguratorCalculatedNumberFieldType,
  ConfiguratorFieldState,
  ConfiguratorFieldType,
  ConfiguratorMaterialFieldType,
  ConfiguratorNumberFieldType,
  ConfiguratorServiceFieldType,
  ConfiguratorSingleChoiceFieldType,
  ConfiguratorStoreState,
} from '@ui/features/configurator/types';
import { getServiceFieldValue } from '../../data/field-definitions/service';
import { calculateOperationSequenceNumber } from '../helpers/index-helpers';
import {
  ensureCalculatedNumberField,
  ensureMaterialField,
  ensureNumberField,
  ensureServiceField,
  ensureSingleChoiceField,
  getFieldByStepId,
  getNumberFieldValue,
  getOnlyFieldByStepKey,
  getScrapYieldTypeValue,
  getSingleChoiceFieldValue,
} from './field-helpers';
import { checkForFieldValueChange } from './resolve-dependencies';

interface BaseRequiredFields {
  valid: boolean;
}

interface InternalRequiredFields extends BaseRequiredFields {
  operationType: 'internal';
  fields: {
    resource: ConfiguratorSingleChoiceFieldType;
    setupHours: ConfiguratorNumberFieldType;
    run: ConfiguratorNumberFieldType;
    runType: ConfiguratorSingleChoiceFieldType;
    setupCostPerHour: ConfiguratorNumberFieldType;
    setupBurdenPerHour: ConfiguratorNumberFieldType;
    runCostPerHour: ConfiguratorNumberFieldType;
    runBurdenPerHour: ConfiguratorNumberFieldType;
    estimatedLaborCost: ConfiguratorNumberFieldType;
    estimatedMaterialCost: ConfiguratorNumberFieldType;
    estimatedBurdenCost: ConfiguratorNumberFieldType;
    contributionCost: ConfiguratorNumberFieldType;
    contributionPercent: ConfiguratorCalculatedNumberFieldType;
    grossCost: ConfiguratorCalculatedNumberFieldType;
    operationCost: ConfiguratorCalculatedNumberFieldType;
    scrapYieldType: ConfiguratorSingleChoiceFieldType;
    scrapYieldPercent: ConfiguratorNumberFieldType;
    fixedScrap: ConfiguratorNumberFieldType;
  };
}

interface ExternalRequiredFields extends BaseRequiredFields {
  operationType: 'external';
  fields: {
    service: ConfiguratorServiceFieldType;
    baseCharge: ConfiguratorNumberFieldType;
    minimumCharge: ConfiguratorNumberFieldType;
    pricePerUnit: ConfiguratorNumberFieldType;
    estimatedServiceCost: ConfiguratorNumberFieldType;
    contributionCost: ConfiguratorNumberFieldType;
    contributionPercent: ConfiguratorCalculatedNumberFieldType;
    grossCost: ConfiguratorCalculatedNumberFieldType;
    scrapYieldType: ConfiguratorSingleChoiceFieldType;
    scrapYieldPercent: ConfiguratorNumberFieldType;
    fixedScrap: ConfiguratorNumberFieldType;
  };
}

type RequiredOperationFieldsType =
  | InternalRequiredFields
  | ExternalRequiredFields;

type RequiredOperations = Record<number, RequiredOperationFieldsType>;
type AggregateDependenciesType = {
  quantity: ConfiguratorNumberFieldType;
  material: ConfiguratorMaterialFieldType;
  matUnitCost: ConfiguratorNumberFieldType;
  matQtyPer: ConfiguratorNumberFieldType;
  washerMaterial: ConfiguratorMaterialFieldType;
  washerMatUnitCost: ConfiguratorNumberFieldType;
  operations: RequiredOperations;
  cost: ConfiguratorNumberFieldType;
};

/**
 * Maybe calculates costs for all operations, based on the fields that affects
 * costs. If none of the required fields have changed, doesn't do anything. When
 * new costs are calculated, sets the calculated values for each operation in
 * the store state.
 *
 * - Requires a `quantity` field in the `prerequisites`
 *
 * - Expects `resource`, `run`, and `run_type` to be fields within each operation
 *
 * - Expects `setupCost`, `setupHours`, and `runHours` to be inputs within the
 *   operation summary (not part of the Configuration data, but hard-coded in
 *   `packages/ui/src/features/configurator/store/helpers/create-operation-summary.ts`)
 *
 * - We assume that in order to calculate the costs for an operation, all of the
 *   required fields for that operation must be non-empty.
 *
 * - We assume that `quantity`, `run`, `runHours`, `setupCost`, and `setupHours`
 *   should default to 0 if not present
 */
export function calculateCosts(
  state: ConfiguratorStoreState,
  previousFields: ConfiguratorFieldState,
  force: boolean = false,
) {
  const {
    site,
    blueprint,
    fields: currentFields,
    quantityDisplayMagnitude,
  } = state;

  try {
    const dependencies = aggregateDependencies(blueprint, currentFields);
    const needsCalculating =
      force || checkForDependencyChanges(dependencies, previousFields);

    if (!needsCalculating) {
      return;
    }

    const {
      quantity,
      matPartId,
      matUnitCost,
      matQtyPer,
      washerMatPartId,
      washerMatUnitCost,
      operations: operationCalcInputs,
    } = gatherValues(dependencies, quantityDisplayMagnitude);

    const costs = calculateConfiguratorCosts(
      site,
      quantity,
      operationCalcInputs,
      matPartId,
      matUnitCost,
      matQtyPer,
      washerMatPartId,
      washerMatUnitCost,
      quantityDisplayMagnitude,
    );

    state.costs = {
      input: operationCalcInputs,
      output: costs,
    };

    setConfiguratorCostValues(dependencies, costs);
  } catch (e) {
    // @TODO(shawk): Surface form/page-level errors to the UI somehow
    console.error(e);
  }
}

/**
 * Collects all of the fields that are dependencies for operation cost
 * calculations. If an operation does not have all of the required fields
 * it is marked as `valid: false`.
 */
function aggregateDependencies(
  blueprint: ConfiguratorBlueprintState,
  currentFields: ConfiguratorFieldState,
): AggregateDependenciesType {
  const quantity = ensureNumberField(
    getOnlyFieldByStepKey(
      STEP_KEYS.base_fields,
      FIELD_IDS.quantity,
      currentFields,
      blueprint,
    ),
  );

  const material = ensureMaterialField(
    getOnlyFieldByStepKey(
      STEP_KEYS.material,
      FIELD_IDS.material,
      currentFields,
      blueprint,
    ),
  );

  const matUnitCost = ensureNumberField(
    getOnlyFieldByStepKey(
      STEP_KEYS.material,
      FIELD_IDS.material_cost,
      currentFields,
      blueprint,
    ),
  );

  const matQtyPer = ensureNumberField(
    getOnlyFieldByStepKey(
      STEP_KEYS.material,
      FIELD_IDS.material_quantity_per,
      currentFields,
      blueprint,
    ),
  );

  const washerMaterial = ensureMaterialField(
    getOnlyFieldByStepKey(
      STEP_KEYS.sems,
      FIELD_IDS.washer_material,
      currentFields,
      blueprint,
    ),
  );

  const washerMatUnitCost = ensureNumberField(
    getOnlyFieldByStepKey(
      STEP_KEYS.sems,
      FIELD_IDS.washer_material_cost,
      currentFields,
      blueprint,
    ),
  );

  const operationDependencies: RequiredOperations = {};

  blueprint.operations.forEach((operation, index) => {
    let valid = true;
    const operationType = operation.operationType;

    if (!operationType) {
      throw new Error(`No operation type for operation ${operation.name}`);
    }

    const sequenceNumber = calculateOperationSequenceNumber(index);

    if (operationType === 'internal') {
      const resource = ensureSingleChoiceField(
        getFieldByStepId(operation.stepId, FIELD_IDS.resource, currentFields),
      );
      const setupHours = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.setup_hours,
          currentFields,
        ),
      );
      const run = ensureNumberField(
        getFieldByStepId(operation.stepId, FIELD_IDS.run, currentFields),
      );
      const runType = ensureSingleChoiceField(
        getFieldByStepId(operation.stepId, FIELD_IDS.run_type, currentFields),
      );
      const setupCostPerHour = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.setup_cost_per_hour,
          currentFields,
        ),
      );
      const setupBurdenPerHour = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.setup_burden_per_hour,
          currentFields,
        ),
      );
      const runCostPerHour = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.run_cost_per_hour,
          currentFields,
        ),
      );
      const runBurdenPerHour = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.run_burden_per_hour,
          currentFields,
        ),
      );
      const estimatedLaborCost = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.estimated_labor_cost,
          currentFields,
        ),
      );
      const estimatedMaterialCost = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.estimated_material_cost,
          currentFields,
        ),
      );
      const estimatedBurdenCost = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.estimated_burden_cost,
          currentFields,
        ),
      );
      const contributionCost = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.contribution_cost,
          currentFields,
        ),
      );
      const contributionPercent = ensureCalculatedNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.contribution_percent,
          currentFields,
        ),
      );
      const grossCost = ensureCalculatedNumberField(
        getFieldByStepId(operation.stepId, FIELD_IDS.gross_cost, currentFields),
      );
      const operationCost = ensureCalculatedNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.operation_cost,
          currentFields,
        ),
      );
      const scrapYieldType = ensureSingleChoiceField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.scrap_yield_type,
          currentFields,
        ),
      );
      const scrapYieldPercent = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.scrap_yield_percentage,
          currentFields,
        ),
      );
      const fixedScrap = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.fixed_scrap,
          currentFields,
        ),
      );

      if (
        !resource.value ||
        resource.isInvalid ||
        run.isInvalid ||
        runType.isInvalid
      ) {
        valid = false;
      }

      operationDependencies[sequenceNumber] = {
        valid,
        operationType,
        fields: {
          resource,
          run,
          runType,
          setupHours,
          setupCostPerHour,
          setupBurdenPerHour,
          runCostPerHour,
          runBurdenPerHour,
          estimatedLaborCost,
          estimatedMaterialCost,
          estimatedBurdenCost,
          contributionCost,
          contributionPercent,
          grossCost,
          operationCost,
          scrapYieldType,
          scrapYieldPercent,
          fixedScrap,
        },
      };
    } else {
      const service = ensureServiceField(
        getFieldByStepId(operation.stepId, FIELD_IDS.service, currentFields),
      );
      const baseCharge = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.base_charge,
          currentFields,
        ),
      );
      const minimumCharge = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.minimum_charge,
          currentFields,
        ),
      );
      const pricePerUnit = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.price_per_unit,
          currentFields,
        ),
      );
      const estimatedServiceCost = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.estimated_service_cost,
          currentFields,
        ),
      );
      const contributionCost = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.contribution_cost,
          currentFields,
        ),
      );
      const contributionPercent = ensureCalculatedNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.contribution_percent,
          currentFields,
        ),
      );
      const grossCost = ensureCalculatedNumberField(
        getFieldByStepId(operation.stepId, FIELD_IDS.gross_cost, currentFields),
      );
      const scrapYieldType = ensureSingleChoiceField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.scrap_yield_type,
          currentFields,
        ),
      );
      const scrapYieldPercent = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.scrap_yield_percentage,
          currentFields,
        ),
      );
      const fixedScrap = ensureNumberField(
        getFieldByStepId(
          operation.stepId,
          FIELD_IDS.fixed_scrap,
          currentFields,
        ),
      );

      if (!service.value || service.isInvalid) {
        valid = false;
      }

      operationDependencies[sequenceNumber] = {
        valid,
        operationType,
        fields: {
          service,
          baseCharge,
          minimumCharge,
          pricePerUnit,
          estimatedServiceCost,
          contributionCost,
          contributionPercent,
          grossCost,
          scrapYieldType,
          scrapYieldPercent,
          fixedScrap,
        },
      };
    }
  });

  const cost = ensureNumberField(
    getOnlyFieldByStepKey(
      STEP_KEYS.computed_fields,
      FIELD_IDS.cost,
      currentFields,
      blueprint,
    ),
  );

  return {
    quantity,
    material,
    matUnitCost,
    matQtyPer,
    washerMaterial,
    washerMatUnitCost,
    operations: operationDependencies,
    cost,
  };
}

function checkForDependencyChanges(
  dependencies: AggregateDependenciesType,
  previousFields: ConfiguratorFieldState,
) {
  for (const field of [
    dependencies.quantity,
    dependencies.matUnitCost,
    dependencies.matQtyPer,
    dependencies.washerMatUnitCost,
  ]) {
    if (checkForFieldValueChange(field, previousFields)) {
      return true;
    }
  }

  for (const operation of Object.values(dependencies.operations)) {
    for (const field of Object.values(operation.fields)) {
      if (checkForFieldValueChange(field, previousFields)) {
        return true;
      }
    }
  }

  return false;
}

/**
 * Iterates over the aggregated operation fields and maps their field values to
 * inputs for `calculateConfiguratorCosts`.
 */
function gatherValues(
  dependencies: AggregateDependenciesType,
  quantityDisplayMagnitude: DisplayMagnitude,
) {
  const quantity = dependencies.quantity.value || 0;

  const operations: ConfiguratorCostsInput = [];
  const matPartId = dependencies.material.value?.meta?.partId ?? null;
  // needs to be cost per "display magnitude" units
  const matUnitCost = dependencies.matUnitCost.value || 0;
  const matQtyPer = convertFromDisplayMagnitude(
    dependencies.matQtyPer.value || 0,
    quantityDisplayMagnitude,
  );
  const washerMatPartId =
    dependencies.washerMaterial.value?.meta?.partId ?? null;
  const washerMatUnitCost = dependencies.washerMatUnitCost.value ?? null;

  for (const [sequenceNumber, operationGroup] of Object.entries(
    dependencies.operations,
  )) {
    /**
     * @TODO(shawk): Should we calculate any costs if some of the operations
     * are incomplete (not valid)? Or should we require that all operations are
     * complete and valid before we do any calculations (scrap affects this).
     */
    if (!operationGroup.valid) {
      continue;
    }

    if (operationGroup.operationType === 'internal') {
      operations.push(
        gatherInternalOperationValues(sequenceNumber, operationGroup.fields),
      );
    } else {
      operations.push(
        gatherExternalOperationValues(
          sequenceNumber,
          operationGroup.fields,
          quantityDisplayMagnitude,
        ),
      );
    }
  }

  return {
    quantity,
    matPartId,
    matUnitCost,
    matQtyPer,
    washerMatPartId,
    washerMatUnitCost,
    operations,
  };
}

function gatherInternalOperationValues(
  sequenceNumber: string,
  fields: Record<string, ConfiguratorFieldType>,
): ConfiguratorCostsOpInput {
  const resource = getResourceFieldValue(fields.resource);
  const run = getNumberFieldValue(fields.run, 0);
  /**
   * @TODO(shawk): this field is required, so if we get here and it's empty
   * unexpected. We probably shouldn't need to specify a default value.
   */
  const runType = getSingleChoiceFieldValue<VisOpRunRateType>(
    fields.runType,
    VisOpRunRateType.PCS_PER_HR,
  );
  const setupHours = getNumberFieldValue(fields.setupHours, 0);
  const setupCostPerHour = getNumberFieldValue(fields.setupCostPerHour, 0);
  const setupBurdenPerHour = getNumberFieldValue(fields.setupBurdenPerHour, 0);
  const runCostPerHour = getNumberFieldValue(fields.runCostPerHour, 0);
  const runBurdenPerHour = getNumberFieldValue(fields.runBurdenPerHour, 0);
  const scrapYieldType = getScrapYieldTypeValue(fields.scrapYieldType);
  const scrapYieldPercent = getNumberFieldValue(fields.scrapYieldPercent);
  const fixedScrap = getNumberFieldValue(fields.fixedScrap);

  /**
   * @NOTE(shawk): Right now we shouldn't get here without a resource because
   * the operation was marked as valid (otherwise we would hit the early
   * return above), but TS doesn't know that.
   *
   * @TODO(shawk): Improve this error message
   */
  if (!resource?.meta) {
    throw new Error(`No resource for operation ${sequenceNumber}`);
  }

  return {
    sequenceNum: Number(sequenceNumber),
    resourceId: resource.meta.resourceId,
    run,
    runType,
    burdenPerOperation: resource.meta.burdenPerOperation,
    burdenPerHrRun: runBurdenPerHour,
    burdenPerUnitRun: resource.meta.burdenPerUnitRun,
    burdenPerHrSetup: setupBurdenPerHour,
    runCostPerHr: runCostPerHour,
    runCostPerUnit: resource.meta.runCostPerUnit,
    setupCostPerHr: setupCostPerHour,
    setupHrs: setupHours,
    scrapYieldType,
    scrapYieldPercent,
    fixedScrap,
    serviceChargePerUnit: 0,
    serviceBaseCharge: 0,
    serviceMinCharge: 0,
    serviceId: '',
  };
}

function gatherExternalOperationValues(
  sequenceNumber: string,
  fields: Record<string, ConfiguratorFieldType>,
  quantityDisplayMagnitude: DisplayMagnitude,
): ConfiguratorCostsOpInput {
  const service = getServiceFieldValue(fields.service);
  const baseCharge = getNumberFieldValue(fields.baseCharge, 0);
  const minimumCharge = getNumberFieldValue(fields.minimumCharge, 0);
  // needs to be price per "display magnitude" units
  const pricePerUnit = convertFromDisplayMagnitude(
    getNumberFieldValue(fields.pricePerUnit, 0),
    quantityDisplayMagnitude,
  );
  const scrapYieldType = getScrapYieldTypeValue(fields.scrapYieldType);
  const scrapYieldPercent = getNumberFieldValue(fields.scrapYieldPercent);
  const fixedScrap = getNumberFieldValue(fields.fixedScrap);

  /**
   * @NOTE(nt): See comment in `gatherInternalOperationValues`
   */
  if (!service?.meta) {
    throw new Error(`No service for operation ${sequenceNumber}`);
  }

  return {
    sequenceNum: Number(sequenceNumber),
    resourceId: 'OSP', // TODO: configurator - this will always be OSP, but we don't know the resource ID yet
    run: 0,
    runType: VisOpRunRateType.PCS_PER_HR, // TODO: configurator - what is this type for external operations?
    burdenPerOperation: 0,
    burdenPerHrRun: 0,
    burdenPerUnitRun: 0,
    burdenPerHrSetup: 0,
    runCostPerHr: 0,
    runCostPerUnit: 0,
    setupCostPerHr: 0,
    setupHrs: 0,
    serviceChargePerUnit: pricePerUnit,
    serviceBaseCharge: baseCharge,
    serviceMinCharge: minimumCharge,
    serviceId: service.meta.id,
    scrapYieldType,
    scrapYieldPercent,
    fixedScrap,
  };
}

function setConfiguratorCostValues(
  dependencies: AggregateDependenciesType,
  costs: ConfiguratorCostsOutput,
) {
  if (costs.nested) {
    for (const [sequenceNumber, operation] of Object.entries(costs.nested)) {
      const seq = dependencies.operations[Number(sequenceNumber)];

      const operationCost = round(
        operation.grossCost - operation.materialCost,
        { min: 2, small: 2 },
      );

      if (seq.operationType === 'internal') {
        seq.fields.estimatedLaborCost.value = operation.laborCost;
        seq.fields.estimatedMaterialCost.value = operation.materialCost;
        seq.fields.estimatedBurdenCost.value = operation.burdenCost;
        seq.fields.operationCost.value = operationCost;
      } else {
        seq.fields.estimatedServiceCost.value = operation.serviceCost;
      }

      seq.fields.contributionCost.value = operation.contributionCost;
      seq.fields.grossCost.value = operation.grossCost;

      if (costs.agg && costs.agg.grossCost > 0) {
        seq.fields.contributionPercent.value = round(
          (operationCost / (costs.agg.grossCost - costs.agg.materialCost)) *
            100,
          {
            min: 2,
            small: 2,
          },
        );
      }
    }
  }

  if (costs.agg) {
    dependencies.cost.value = costs.agg.grossCost;
  }
}
