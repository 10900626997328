import { z } from 'zod';

export const costBucketsSchema = z.object({
  laborCost: z.number(),
  materialCost: z.number(),
  burdenCost: z.number(),
  serviceCost: z.number(),
});

export const costCalculationsSchema = z
  .object({
    /** Total combined variable costs */
    contributionCost: z.number(),
    /** Total combined costs */
    grossCost: z.number(),
  })
  .merge(costBucketsSchema);

export const runRateTypeSchema = z.enum([
  'PCS_PER_HR',
  'HRS_PER_PC',
  'MIN_PER_PC',
  'DAYS_PER_PC',
  'HRS_PER_LOAD',
  'DAYS_PER_LOAD',
  'PCS_PER_MIN',
  'PCS_PER_DAY',
  'LOADS_PER_HR',
  'LOADS_PER_DAY',
]);

export const scrapYieldTypeSchema = z.enum(['SCRAP', 'YIELD']);
