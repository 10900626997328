import type { PaginationParams } from '@lib/models/pagination';
import type { PartSearchQuery } from '@lib/requests/part';
import type { InventoryResponse } from '@lib/responses/inventory';
import type { PaginatedOrderLineHistoryResponse } from '@lib/responses/order-line';
import type {
  PartHistoryResponse,
  PartSearchResponse,
} from '@lib/responses/part';
import type { PaginatedQuoteHistoryResponse } from '@lib/responses/quote';
import type { API } from './api';

export class PartService {
  constructor(private api: API) {}

  searchParts = (query: PartSearchQuery) => {
    return this.api.get<PartSearchResponse>('/part', query);
  };

  getPartHistory = (partId: string) => {
    return this.api.get<PartHistoryResponse>(
      `/part/history/${encodeURIComponent(partId)}`,
    );
  };

  getPartOrderLineHistory = (
    partId: string,
    { page, perPage }: PaginationParams,
  ) => {
    return this.api.get<PaginatedOrderLineHistoryResponse>(
      `/part/history/${encodeURIComponent(partId)}/order-line`,
      { page, perPage },
    );
  };

  getPartQuoteHistory = (
    partId: string,
    { page, perPage }: PaginationParams,
  ) => {
    return this.api.get<PaginatedQuoteHistoryResponse>(
      `/part/history/${encodeURIComponent(partId)}/quote`,
      { page, perPage },
    );
  };

  getPartOptions = (siteCode: string, partId: string) => {
    return this.api.get('/part-options', { siteCode, partId });
  };

  getInventory = (partId: string) => {
    return this.api.get<InventoryResponse>('/part-inventory', { partId });
  };
}
