import type { visOperationTypeSelect } from '@lib/kysely/visual/queries';
import { VisOperationTypeScrapYieldType } from '@lib/kysely/visual/tables/operation-type';
import { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import type { TupleElements } from '@lib/util';
import { z } from 'zod';

export const configuratorOperationTypeSchema = z.object({
  id: z.string().transform((s) => s.toUpperCase()),
  description: z.string().nullable(),
  resource_id: z
    .string()
    .nullable()
    .transform((s) => s?.toUpperCase() ?? null),
  setup_hrs: z.number().nullable(),
  setup_cost_per_hr: z.number().nullable(),
  run: z.number().nullable(),
  run_type: z
    .string()
    .nullable()
    .transform((v) => (v ? v.trim() : null))
    .pipe(z.nativeEnum(VisOpRunRateType))
    .nullable(),
  run_cost_per_hr: z.number().nullable(),
  run_cost_per_unit: z.number().nullable(),
  bur_per_hr_run: z.number().nullable(),
  bur_per_hr_setup: z.number().nullable(),
  bur_per_operation: z.number().nullable(),
  bur_per_unit_run: z.number().nullable(),
  bur_percent_run: z.number().nullable(),
  bur_percent_setup: z.number().nullable(),
  scrap_yield_type: z
    .string()
    .nullable()
    .transform((v) => (v ? v.trim() : null))
    .pipe(z.nativeEnum(VisOperationTypeScrapYieldType))
    .nullable(),
  scrap_yield_pct: z.number().nullable(),
  fixed_scrap_units: z.number().nullable(),
} satisfies Record<
  TupleElements<typeof visOperationTypeSelect>,
  Zod.ZodTypeDef
>);

export type ConfiguratorOperationTypeSchemaOutput = z.output<
  typeof configuratorOperationTypeSchema
>;
