import { UnreachableCaseError } from '@lib/validation';
import type {
  ConfiguratorDependentOnType,
  ConfiguratorFieldState,
  ConfiguratorFieldType,
  ConfiguratorStoreState,
} from '@ui/features/configurator/types';
import { createFieldKey } from './create-field-key';
import {
  getBooleanFieldValue,
  getFieldByStepId,
  getMaterialFieldValue,
  getOperationTypeFieldValue,
  getServiceFieldValue,
  getSingleChoiceFieldValue,
  maybeGetField,
} from './field-helpers';
import { getStaticOptions } from './get-static-options';
import { validateField } from './validate-field';

export function resolveDependencies(
  { fields: currentFields }: ConfiguratorStoreState,
  previousFields: ConfiguratorFieldState,
) {
  for (const field of Object.values(currentFields)) {
    if (checkForFieldValueChange(field, previousFields)) {
      checkDependencies(field, currentFields);
    }
  }
}

export function checkForFieldValueChange(
  field: ConfiguratorFieldType,
  previousFields: ConfiguratorFieldState,
): boolean {
  const previousField = maybeGetField(field.fieldKey, previousFields);

  if (previousField) {
    /**
     * @NOTE(shawk): this must account for custom input types that are
     * 'single_choice'-like (i.e. contain non-primitive values).
     *
     * `material`, `service`, `operation_type` all have the same value type -
     * ConfiguratorSingleChoiceValue<T>.
     */
    switch (field.inputType) {
      case 'single_choice':
        return (
          getSingleChoiceFieldValue(previousField, '') !==
          getSingleChoiceFieldValue(field, '')
        );

      case 'material':
        return (
          getMaterialFieldValue(previousField, '') !==
          getMaterialFieldValue(field, '')
        );

      case 'service':
        return (
          getServiceFieldValue(previousField, '') !==
          getServiceFieldValue(field, '')
        );

      case 'operation_type':
        return (
          getOperationTypeFieldValue(previousField, '') !==
          getOperationTypeFieldValue(field, '')
        );

      case 'number':
      case 'boolean':
      case 'calculated_number':
      case 'description':
      case 'text':
      case 'textarea':
        return previousField.value !== field.value;

      case 'display':
        return false;

      default:
        throw new UnreachableCaseError(field);
    }
  }

  return false;
}

function checkDependencies(
  field: ConfiguratorFieldType,
  currentFields: ConfiguratorFieldState,
) {
  for (const requiredBy of field.requiredBy) {
    const fieldKey = createFieldKey(requiredBy.stepId, requiredBy.fieldId);
    const dependentField = currentFields[fieldKey];

    if (
      checkForRequiredFieldValidity(dependentField.dependentOn, currentFields)
    ) {
      setInteractivityByMode(dependentField, true);

      validateSingleChoiceField(fieldKey, dependentField, currentFields);
    } else {
      setInteractivityByMode(dependentField, false);

      clearFieldValue(dependentField, currentFields);
    }
  }
}

export function checkForRequiredFieldValidity(
  requiredFields: ConfiguratorDependentOnType[],
  fields: ConfiguratorFieldState,
): boolean {
  let allRequiredFieldsValid = true;

  for (const requiredField of requiredFields) {
    if (!checkForRequiredFieldValidityByMode(requiredField, fields)) {
      allRequiredFieldsValid = false;
      break;
    }
  }

  return allRequiredFieldsValid;
}

function checkForRequiredFieldValidityByMode(
  dependency: ConfiguratorDependentOnType,
  fields: ConfiguratorFieldState,
) {
  const requiredField = getFieldByStepId(
    dependency.stepId,
    dependency.fieldId,
    fields,
  );

  switch (dependency.dependencyType) {
    case 'boolean':
      return getBooleanFieldValue(requiredField) === dependency.booleanValue;

    case 'selected_value':
      return dependency.selectedValueOptions.includes(
        getSingleChoiceFieldValue(requiredField, ''),
      );

    default:
      return requiredField.value && !requiredField.isInvalid;
  }
}

function setInteractivityByMode(
  field: ConfiguratorFieldType,
  interactive: boolean,
) {
  switch (field.dependencyMode) {
    case 'enable':
      field.isDisabledFromDependency = !interactive;
      return;

    default:
      field.isHidden = !interactive;
      return;
  }
}

function validateSingleChoiceField(
  fieldKey: string,
  field: ConfiguratorFieldType,
  fields: ConfiguratorFieldState,
) {
  if (field.inputType === 'single_choice' && field.value) {
    if (field.inputSource?.source_type === 'static') {
      const options = getStaticOptions(field, fields);

      if (!options.find((o) => o.value === field.value?.value)) {
        field.isInvalid = true;

        field.errors = ['Selected option is no longer valid.'];
      }
    } else {
      field.value = null;

      validateField(fields, fieldKey);
    }
    checkDependencies(field, fields);
  }
}

function clearFieldValue(
  field: ConfiguratorFieldType,
  fields: ConfiguratorFieldState,
) {
  if (field.value && field.clearOnNonInteractive) {
    field.value = null;

    field.isInvalid = false;

    checkDependencies(field, fields);
  }
}
