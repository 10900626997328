import { Center, Spinner } from '@chakra-ui/react';

const Loading = ({ loadingText = 'Loading...' }: { loadingText?: string }) => {
  return (
    <Center>
      <Spinner aria-label={loadingText} />
    </Center>
  );
};

export default Loading;
