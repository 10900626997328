export type DescriptionInputs = {
  partId: string;
  headSlotStyle: string;
  headStyle: string;
  recessStyle: string;
  pointStyles: string[];
  serrationTypes: string[];
  shankLength: number;
  shankLengthFractional: string;
  shankStyle: string;
  threadsPerInch: string;
  threadDiameter: string;
  threadType: string;
  uom: 'English' | 'Metric';
  washerStyle: string;
  torxDesc: string;
  torxDescRec: string;
  material: string;
  materialGrade: string;
};

function isPresent(
  input: string | null,
  opts: { none?: string } = {},
): boolean {
  if (input === null) {
    return false;
  }

  // Check for explicit "none" value
  if (
    opts.none !== undefined &&
    input.toUpperCase().trim() === opts.none.toUpperCase().trim()
  ) {
    return false;
  }

  return !!input;
}

function isAbsent(input: string | null, opts: { none?: string } = {}): boolean {
  return !isPresent(input, opts);
}

export function createDescription(inputs: DescriptionInputs): string {
  if (isAbsent(inputs.threadDiameter) || isAbsent(inputs.threadsPerInch)) {
    if (inputs.partId) {
      return `${inputs.partId} Part Description`;
    }

    return '';
  }

  let result = '';

  // Thread Diameter & TPI
  result += `${inputs.threadDiameter}-${inputs.threadsPerInch}`;

  // Shank Length
  const isShankLengthFractionalPresent = isPresent(
    inputs.shankLengthFractional,
    { none: '0' },
  );

  if (inputs.shankLength > 0 || isShankLengthFractionalPresent) {
    result += ' X ';
  }

  if (inputs.shankLength > 0) {
    result += `${inputs.shankLength}`;
  }

  if (inputs.uom === 'English') {
    if (isShankLengthFractionalPresent) {
      if (inputs.shankLength > 0) {
        result += '-';
      }

      result += `${inputs.shankLengthFractional}`;
    }
  }

  // Head Slot Style
  if (isPresent(inputs.headSlotStyle, { none: 'None' })) {
    result += ` ${inputs.headSlotStyle.toUpperCase()}`;
  }

  // Recess Style
  if (isPresent(inputs.recessStyle, { none: 'None' })) {
    result += `, ${inputs.recessStyle.toUpperCase()}`;
  }

  // TORX Desc Rec
  // @TODO(cj): What is this?
  if (isPresent(inputs.torxDescRec)) {
    result += `, ${inputs.torxDescRec.toUpperCase()}`;
  }

  // Head Style
  if (isPresent(inputs.headStyle, { none: 'None' })) {
    result += `, ${inputs.headStyle.toUpperCase()}`;
  }

  // TORX Rec
  // @TODO(cj): What is this?
  if (isPresent(inputs.torxDesc)) {
    result += `, ${inputs.torxDesc.toUpperCase()}`;
  }

  // Material
  if (isPresent(inputs.material)) {
    result += `, ${inputs.material.toUpperCase()}`;
  }

  if (isPresent(inputs.materialGrade)) {
    result += `, ${inputs.materialGrade.toUpperCase()}`;
  }

  // Thread Type
  if (isPresent(inputs.threadType)) {
    result += `, ${inputs.threadType.toUpperCase()}`;
  }

  // Point Style
  const pointStyles = inputs.pointStyles.reduce((acc, pointStyle) => {
    if (isPresent(pointStyle, { none: 'None' })) {
      acc.push(pointStyle.split('/')[0]);
    }

    return acc;
  }, [] as string[]);

  if (pointStyles.length > 0) {
    result += ` ${pointStyles.join(' ').toUpperCase()}`;
  }

  // Shank Style
  if (isPresent(inputs.shankStyle, { none: 'No Shoulder' })) {
    result += ` W/${inputs.shankStyle.toUpperCase()}`;
  }

  // Serrations
  const serrationTypes = inputs.serrationTypes.reduce((acc, serrationType) => {
    if (isPresent(serrationType, { none: 'None' })) {
      acc.push(serrationType);
    }

    return acc;
  }, [] as string[]);

  if (serrationTypes.length > 0) {
    result += ` ${serrationTypes.join(' ').toUpperCase()}`;
  }

  // Washer Style
  if (isPresent(inputs.washerStyle, { none: 'None' })) {
    result += ` W/${inputs.washerStyle.toUpperCase()}`;
  }

  return result;
}
