import {
  Box,
  ListItem as ChakraListItem,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Text,
  UnorderedList,
  useModalContext,
} from '@chakra-ui/react';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import is from '@sindresorhus/is';
import {
  type UseComboboxActions,
  type UseComboboxPropGetters,
  type UseComboboxProps,
  useCombobox,
} from 'downshift';
import type React from 'react';
import { memo, useCallback, useRef } from 'react';
import { P, match } from 'ts-pattern';
import type { Nullable } from 'vitest';

type Item = {
  label: string;
  value: string;
  description?: string | null;
};

export const Combobox = memo(ComboboxComponent);

function ComboboxComponent<T extends Item>({
  items,
  placeholder,
  isLoading = false,
  isDisabled = false,
  onFocus,
  onScrollToBottom,
  itemToString = (item) => item?.label ?? '',
  popoverContentProps = {},
  ...props
}: UseComboboxProps<T> & {
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onFocus?: () => void;
  onScrollToBottom?: () => void;
  popoverContentProps?: React.ComponentProps<typeof PopoverContent>;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    reset,
  } = useCombobox({
    ...props,
    items,
    itemToString,
  });

  return (
    <Root isOpen={isOpen}>
      <InputWrapper>
        <SearchInput
          placeholder={placeholder}
          isDisabled={isDisabled}
          inputProps={getInputProps({
            ref: inputRef,
            onFocus,
          })}
        />
        <SearchInputAdornment
          inputRef={inputRef}
          isOpen={isOpen}
          isLoading={isLoading}
          isDisabled={isDisabled}
          selectedItem={selectedItem}
          toggleButtonProps={getToggleButtonProps()}
          reset={reset}
        />
      </InputWrapper>

      <List
        isOpen={isOpen}
        isLoading={isLoading}
        items={items}
        selectedItem={selectedItem}
        highlightedIndex={highlightedIndex}
        menuProps={getMenuProps()}
        getItemProps={getItemProps}
        onScrollToBottom={onScrollToBottom}
        popoverContentProps={popoverContentProps}
      />
    </Root>
  );
}

const DEFAULT_POPOVER_OFFSET = [0, 0] as const;

function Root({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) {
  return (
    <Popover
      isOpen={isOpen}
      autoFocus={false}
      closeOnEsc={false}
      returnFocusOnClose={false}
      closeOnBlur={false}
      offset={DEFAULT_POPOVER_OFFSET as [number, number]}
      lazyBehavior="keepMounted"
      isLazy
    >
      <Box>{children}</Box>
    </Popover>
  );
}

function InputWrapper({ children }: { children: React.ReactNode }) {
  return (
    <PopoverTrigger>
      <InputGroup>{children}</InputGroup>
    </PopoverTrigger>
  );
}

function SearchInput<T>({
  placeholder,
  isDisabled = false,
  inputProps,
}: {
  placeholder?: string;
  isDisabled?: boolean;
  inputProps: ReturnType<UseComboboxPropGetters<T>['getInputProps']>;
}) {
  return (
    <Input
      type="text"
      placeholder={placeholder}
      backgroundColor="white"
      borderColor="mw.lightGrey"
      borderRadius={0}
      outlineOffset={0}
      outline="1px solid transparent"
      pr={10}
      _hover={{
        borderColor: 'mw.blue',
      }}
      _focus={{
        outlineColor: 'mw.blue',
      }}
      _invalid={{
        borderColor: 'snap.red.600',
        backgroundColor: 'snap.red.600/20',

        _hover: {
          backgroundColor: 'snap.red.600/10',
        },

        _focus: {
          backgroundColor: 'snap.red.600/10',
          outlineColor: 'snap.red.600',
        },
      }}
      disabled={isDisabled}
      {...inputProps}
    />
  );
}

function SearchInputAdornment<T>({
  isOpen,
  isLoading,
  isDisabled,
  selectedItem,
  toggleButtonProps,
  reset,
  inputRef,
}: {
  isOpen: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  selectedItem: Nullable<T>;
  toggleButtonProps: ReturnType<
    UseComboboxPropGetters<T>['getToggleButtonProps']
  >;
  reset: UseComboboxActions<T>['reset'];
  inputRef: React.RefObject<HTMLInputElement>;
}) {
  return (
    <InputRightElement>
      {match([isLoading, is.null(selectedItem)])
        .returnType<React.ReactElement | null>()
        .with([true, P._], () => (
          <Flex px={2} alignItems="center">
            <Spinner size="sm" color="mw.grey" aria-label="Loading..." />
          </Flex>
        ))
        .with([P._, true], () => (
          <IconButton
            aria-label="toggle menu"
            colorScheme="transparent"
            variant="ghost"
            isDisabled={isDisabled}
            icon={
              <Icon
                as={FontAwesomeIcon}
                icon={isOpen ? faChevronUp : faChevronDown}
                w={3}
              />
            }
            {...toggleButtonProps}
          />
        ))
        .with([P._, false], () => (
          <IconButton
            aria-label="clear choice"
            colorScheme="transparent"
            variant="ghost"
            isDisabled={isDisabled}
            color="mw.grey"
            _focus={{
              color: 'mw.darkRed',
            }}
            _hover={{
              color: 'mw.darkRed',
            }}
            icon={<Icon as={FontAwesomeIcon} icon={faCircleXmark} w={4} />}
            onClick={() => {
              reset();
              inputRef.current?.focus();
            }}
          />
        ))
        .otherwise(() => null)}
    </InputRightElement>
  );
}

function List<T extends Item>({
  isOpen,
  isLoading,
  items,
  selectedItem,
  highlightedIndex,
  menuProps,
  getItemProps,
  onScrollToBottom,
  popoverContentProps,
}: {
  isOpen: boolean;
  isLoading: boolean;
  items: T[];
  selectedItem: T | null;
  highlightedIndex: number;
  menuProps: ReturnType<UseComboboxPropGetters<T>['getMenuProps']>;
  getItemProps: UseComboboxPropGetters<T>['getItemProps'];
  onScrollToBottom?: () => void;
  popoverContentProps?: React.ComponentProps<typeof PopoverContent>;
}) {
  const modalContext = useModalContext();
  const handleScroll = useCallback(
    (e: React.UIEvent) => {
      if (
        e.currentTarget.scrollTop ===
        e.currentTarget.scrollHeight - e.currentTarget.clientHeight
      ) {
        onScrollToBottom?.();
      }
    },
    [onScrollToBottom],
  );

  return (
    <Box {...menuProps} hidden={!(isOpen && items.length)}>
      <Portal containerRef={modalContext?.dialogRef}>
        <PopoverContent
          maxH={80}
          w={200}
          bg="white"
          borderTopRadius={0}
          boxShadow="md"
          overflow="auto"
          onScroll={onScrollToBottom ? handleScroll : undefined}
          {...popoverContentProps}
        >
          <UnorderedList listStyleType="none" m={0}>
            {items.map((item, index) => {
              return (
                <ListItem
                  key={`${item.label}-${item.value}`}
                  label={item.label}
                  description={item.description}
                  isLoading={isLoading}
                  isHighlighted={highlightedIndex === index}
                  isSelected={selectedItem?.value === item.value}
                  itemProps={getItemProps({ item, index })}
                />
              );
            })}

            {!isLoading && items.length === 0 && <Empty />}
          </UnorderedList>
        </PopoverContent>
      </Portal>
    </Box>
  );
}

function ListItem<T>({
  label,
  description,
  isHighlighted,
  isSelected,
  isLoading,
  itemProps,
}: {
  label: string;
  description?: string | null;
  isHighlighted: boolean;
  isSelected: boolean;
  isLoading: boolean;
  itemProps: ReturnType<UseComboboxPropGetters<T>['getItemProps']>;
}) {
  return (
    <ChakraListItem
      bg={isHighlighted ? 'mw.lightGrey' : undefined}
      _even={{
        bg: isHighlighted ? 'mw.lightGrey' : 'mw.panelGrey',
      }}
      opacity={isLoading ? 0.5 : 1}
      fontWeight={isSelected ? 'bold' : undefined}
      cursor="pointer"
      py={2}
      px={4}
      {...itemProps}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Text>{label}</Text>
        {description && (
          <Text fontSize="sm" color="mw.grey">
            {description}
          </Text>
        )}
      </Box>
    </ChakraListItem>
  );
}

function Empty({ isLoading = false }: { isLoading?: boolean }) {
  return (
    <ChakraListItem opacity={isLoading ? 0.5 : 1} py={2} px={4}>
      <Text fontStyle="italic" fontSize={14} color="mw.grey">
        No choices available
      </Text>
    </ChakraListItem>
  );
}
