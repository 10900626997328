import {
  Button as ChakraButton,
  type ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';

// TODO: MW-558
// To do this the _most_ correct way, variants should be
// handled in the theme, but this universally adjust all
// buttons. We don't want to do that yet, so this will
// do for now.

type ButtonProps = Omit<ChakraButtonProps, 'variant'> & {
  variant?: 'primary' | 'secondary' | 'ghost' | 'warning' | 'base';
};

const baseStyles = {
  borderRadius: '0px',
  px: 4,
};

const primaryVariant = {
  backgroundColor: 'snap.teal.600',
  color: 'white',
  _hover: {
    backgroundColor: 'snap.teal.700',
  },
};

const secondaryVariant = {
  backgroundColor: 'snap.yellow.600',
  color: 'black',
  _hover: {
    backgroundColor: 'snap.yellow.700',
  },
};

const ghostVariant = {
  backgroundColor: 'transparent',
  color: 'snap.teal.600',
  _hover: {
    backgroundColor: 'snap.gray.100',
  },
};

const warningVariant = {
  backgroundColor: 'snap.red.700',
  color: 'white',
  _hover: {
    backgroundColor: 'snap.red.800',
  },
};

const baseVariant = {};

const variantMap = {
  primary: primaryVariant,
  secondary: secondaryVariant,
  ghost: ghostVariant,
  warning: warningVariant,
  base: baseVariant,
};

export function Button({ variant = 'primary', ...props }: ButtonProps) {
  return <ChakraButton {...baseStyles} {...variantMap[variant]} {...props} />;
}
