import type { PricingConfigResponse } from '@lib/responses';
import { requiredDecimalString } from '@lib/validation/base';
import {
  ConfigEntities,
  FixedPricingSources,
  PricingStrategy,
} from '@prisma/client';
import { z } from 'zod';

const pricingConfigBaseSchema = z.object({
  name: z.string().min(1, { message: 'Name can’t be blank' }),
  configEntityPriorities: z.array(z.nativeEnum(ConfigEntities)),
});

// TODO: we don't want to be persisting any strategies that don't fully validate
//  against the full discrim union; can just keep locally as draft until
//  validation passes when creating new
export const pricingConfigCreateSchema = z.object({
  name: z.string().min(1, { message: 'Name can’t be blank' }),
  pricingStrategy: z.nativeEnum(PricingStrategy, {
    required_error: 'Pricing strategy is required',
  }),
});

export type PricingConfigCreateDefaultFormValues = z.input<
  typeof pricingConfigCreateSchema
>;
export type PricingConfigCreateFormData = z.output<
  typeof pricingConfigCreateSchema
>;

// TODO: Store these on strategy implementations as static properties so we can
//  access from the main map
const strategyOptSchemas = {
  [PricingStrategy.FIXED]: z.object({
    fixedPricingSrc: z.nativeEnum(FixedPricingSources),
  }),
  [PricingStrategy.TARGET_CONTR_MARGIN]: z.object({
    targetContrMarginPct: requiredDecimalString('Target contr. margin %'),
  }),
  [PricingStrategy.TARGET_GROSS_MARGIN]: z.object({
    targetGrossMarginPct: requiredDecimalString('Target contr. margin %'),
  }),
  [PricingStrategy.RAF_CUSTOM]: z.object({
    fixedPricingSrc: z.nativeEnum(FixedPricingSources),
  }),
} satisfies Record<
  PricingStrategy,
  z.ZodSchema<Partial<PricingConfigResponse>>
>;

const buildStratSchema = <K extends PricingStrategy>(stratKey: K) => {
  const optsSchema = strategyOptSchemas[stratKey];

  return pricingConfigBaseSchema
    .extend({ pricingStrategy: z.literal(stratKey) })
    .merge(optsSchema);
};

export const pricingConfigUpdateSchema = z.discriminatedUnion(
  'pricingStrategy',
  [
    buildStratSchema(PricingStrategy.FIXED),
    buildStratSchema(PricingStrategy.TARGET_CONTR_MARGIN),
    buildStratSchema(PricingStrategy.TARGET_GROSS_MARGIN),
    buildStratSchema(PricingStrategy.RAF_CUSTOM),
  ],
  {
    errorMap: () => ({
      message: 'Not a valid pricing strategy',
    }),
  },
);

export type PricingConfigUpdateDefaultFormValues = z.input<
  typeof pricingConfigUpdateSchema
>;
export type PricingConfigUpdateFormData = z.output<
  typeof pricingConfigUpdateSchema
>;
