import {
  nullableDecimalString,
  requiredDecimalString,
} from '@lib/validation/base';
import { ConfigEntities, CostInputSources } from '@prisma/client';
import { z } from 'zod';

export const costingConfigCreateSchema = z.object({
  name: z.string().min(1, { message: 'Name can’t be blank' }),
  srvBaseChargeDefault: requiredDecimalString('Service Base Charge Default'),
  srvMinChargeDefault: nullableDecimalString,
  matMinCostDefault: nullableDecimalString,
});

export type CostingConfigCreateDefaultFormValues = z.input<
  typeof costingConfigCreateSchema
>;
export type CostingConfigCreateFormData = z.output<
  typeof costingConfigCreateSchema
>;

export const costingConfigUpdateSchema = z.object({
  name: z.string().min(1, { message: 'Name can’t be blank' }),
  configEntityPriorities: z.array(z.nativeEnum(ConfigEntities)),
  labRunCostPerHrSrc: z.array(z.nativeEnum(CostInputSources)),
  labRunCostPerUnitSrc: z.array(z.nativeEnum(CostInputSources)),
  labSetupCostPerHrSrc: z.array(z.nativeEnum(CostInputSources)),
  burRunCostPerHrSrc: z.array(z.nativeEnum(CostInputSources)),
  burRunCostPerUnitSrc: z.array(z.nativeEnum(CostInputSources)),
  burSetupCostPerHrSrc: z.array(z.nativeEnum(CostInputSources)),
  burCostPerOpSrc: z.array(z.nativeEnum(CostInputSources)),
  serviceIdSrc: z.array(z.nativeEnum(CostInputSources)),
  srvChargePerUnitSrc: z.array(z.nativeEnum(CostInputSources)),
  srvBaseChargeSrc: z.array(z.nativeEnum(CostInputSources)),
  srvBaseChargeDefault: requiredDecimalString('Service Base Charge Default'),
  srvMinChargeSrc: z.array(z.nativeEnum(CostInputSources)),
  srvMinChargeDefault: nullableDecimalString,
  matMinCostSrc: z.array(z.nativeEnum(CostInputSources)),
  matMinCostDefault: nullableDecimalString,
  matFixedCostSrc: z.array(z.nativeEnum(CostInputSources)),
  matUnitCostSrc: z.array(z.nativeEnum(CostInputSources)),
  reqSrvUnitCostSrc: z.array(z.nativeEnum(CostInputSources)),
  reqLabUnitCostSrc: z.array(z.nativeEnum(CostInputSources)),
  reqBurUnitCostSrc: z.array(z.nativeEnum(CostInputSources)),
  reqBurPctSrc: z.array(z.nativeEnum(CostInputSources)),
  reqBurPerUnitSrc: z.array(z.nativeEnum(CostInputSources)),
  reqFixedQtySrc: z.array(z.nativeEnum(CostInputSources)),
  reqBasisQtySrc: z.array(z.nativeEnum(CostInputSources)),
  reqUnitsPerPieceSrc: z.array(z.nativeEnum(CostInputSources)),
  scrapFixedUnitsSrc: z.array(z.nativeEnum(CostInputSources)),
  scrapYieldPercentSrc: z.array(z.nativeEnum(CostInputSources)),
});

export type CostingConfigUpdateDefaultFormValues = z.input<
  typeof costingConfigUpdateSchema
>;
export type CostingConfigUpdateFormData = z.output<
  typeof costingConfigUpdateSchema
>;
