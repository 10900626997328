import {
  DISPLAY_MAGNITUDE_DESCRIPTION,
  DISPLAY_MAGNITUDE_MULTIPLIER,
  DISPLAY_MAGNITUDE_POSTFIX,
} from '@lib/enums/display-magnitude';
import type { DisplayMagnitude } from '@prisma/client';

// export function convertToDisplayMagnitude(
//   value: number,
//   magnitude: DisplayMagnitude,
// ) {
//   return value; // / DISPLAY_MAGNITUDE_MULTIPLIER[magnitude];
// }

export function convertFromDisplayMagnitude(
  value: number,
  magnitude: DisplayMagnitude,
) {
  return value * DISPLAY_MAGNITUDE_MULTIPLIER[magnitude];
}

export function appendDisplayMagnitudePostfix(
  value: string,
  magnitude: DisplayMagnitude,
) {
  return `${value}${DISPLAY_MAGNITUDE_POSTFIX[magnitude]}`;
}

export function appendDisplayMagnitudeDescription(
  value: string,
  magnitude: DisplayMagnitude,
) {
  return `${value}${DISPLAY_MAGNITUDE_DESCRIPTION[magnitude]}`;
}
