import { PermActions } from '@lib/permissions';
import type { PermissionsReqInput } from '@lib/requests';
import { ResourceEnum } from '@prisma/client';

export { route } from '@ui/util/route';

export type RouteItem = {
  label: string;
  path: string;
  permissions?: PermissionsReqInput;
};

export type RoutesConfig = {
  [key: string]: RouteItem;
};

export const RoutesConfig = {
  quoteQueue: {
    label: 'Quote Queue',
    path: '/quotes',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteNew: {
    label: 'Create Quote',
    path: '/quote/new',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.WRITE] },
  },
  quoteShow: {
    label: 'View Quote',
    path: '/quote/:id',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteView: {
    label: 'View Quote',
    path: '/quote/view/:id',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteFeedback: {
    label: 'Quote Feedback',
    path: '/quote/:id/feedback',
  },
  customers: {
    label: 'Customers',
    path: '/customers',
    permissions: {
      [ResourceEnum.ALPHA]: [PermActions.READ],
      [ResourceEnum.CUSTOMER]: [PermActions.READ],
    },
  },
  dashboard: {
    label: 'Dashboard',
    path: '/dashboard',
    permissions: { [ResourceEnum.ALPHA]: [PermActions.READ] },
  },
  metrics: {
    label: 'Metrics',
    path: '/metrics',
    permissions: {
      [ResourceEnum.BETA]: [PermActions.READ],
      [ResourceEnum.METRICS]: [PermActions.READ],
    },
  },

  admin: {
    label: 'Admin',
    path: '/admin',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminConfig: {
    label: 'Config',
    path: '/admin/config',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_SITE]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminUsers: {
    label: 'Users',
    path: '/admin/users',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_USERS]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminSites: {
    label: 'Sites',
    path: '/admin/sites',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_SITE]: [PermActions.READ],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminMetrics: {
    label: 'Metrics',
    path: '/admin/metrics',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_METRICS]: [PermActions.READ],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  profile: {
    label: 'Profile',
    path: '/profile',
  },
} as const satisfies RoutesConfig;
