import type { WritableConfiguratorStoreState } from '@ui/features/configurator/types';
import type { ConfiguratorAction } from './types';
import { removeDependencyRelations } from '@ui/features/configurator/store/helpers/create-field';

export type RemoveOperationAction = ConfiguratorAction<
  'removeOperation',
  { stepId: string }
>;

export const removeOperation = (stepId: string): RemoveOperationAction => ({
  type: 'removeOperation',
  payload: {
    stepId,
  },
});

export function removeOperationAction(
  state: WritableConfiguratorStoreState,
  stepId: string,
) {
  const fieldKeysToRemove = state.blueprint.operations
    .filter((o) => o.stepId === stepId)
    .flatMap((o) => o.fieldKeys);

  /**
   * Clear operation blueprints
   */
  state.blueprint.operations = state.blueprint.operations.filter(
    (operation) => operation.stepId !== stepId,
  );

  /**
   * Clear fields
   *
   * This has to be done in 2 phases in order to avoid deleting fields that are
   * dependend on by fields that are yet to be deleted. First we remove the
   * dependencies, then clean up the fields.
   */
  for (const fieldKey of fieldKeysToRemove) {
    removeDependencyRelations(state.fields[fieldKey], state.fields);
  }

  for (const fieldKey of fieldKeysToRemove) {
    delete state.fields[fieldKey];
  }
}
