import type {
  Configuration,
  ConfiguratorBlueprintState,
  ConfiguratorFieldState,
  ConfiguratorStoreState,
} from '@ui/features/configurator/types';
import { createInitialBlueprintFromStep } from './blueprint-helpers';
import { convertConfiguration } from './configuration-helpers';

export function initializeConfiguratorStore(
  incomingConfiguration: Configuration,
): Omit<ConfiguratorStoreState, 'quantityDisplayMagnitude' | 'site'> {
  const configuration = convertConfiguration(incomingConfiguration);

  const fields: ConfiguratorFieldState = {};
  const blueprint: ConfiguratorBlueprintState = {
    prerequisites: [],
    volumeCalculations: [],
    operations: [],
    additionalCosts: [],
    computed: [],
    availableOperations: [],
    stepKeyToIdMap: {},
  };

  for (const step of incomingConfiguration.steps) {
    createInitialBlueprintFromStep(step, configuration, fields, blueprint);
  }

  return {
    configuration,
    fields,
    blueprint,
    costs: null,
  };
}
