import type { GeneratedAlways, Insertable } from 'kysely';
import type { Generated, Selectable } from 'kysely';
import type { VisWorkOrderStatus, VisWorkOrderType } from './work-order';

// Note: Not explicitly defined in DB & could drift over time
export enum VisReqQtyPerType {
  START = 'S',
  END = 'E',
}

export interface VisRequirementTable {
  act_burden_cost: Generated<number>;
  act_labor_cost: Generated<number>;
  act_material_cost: Generated<number>;
  act_service_cost: Generated<number>;
  allocated_qty: Generated<number>;
  alt_part_parent_piece_no: number | null;
  bid_rate_category_id: string | null;
  burden_per_unit: Generated<number>;
  burden_percent: Generated<number>;
  calc_fixed_scrap: number | null;
  calc_qty: number | null;
  close_date: Date | null;
  dim_expression: string | null;
  dimensions: string | null;
  discontinue_date: Date | null;
  dispatched: string | null;
  drawing_file: string | null;
  drawing_id: string | null;
  drawing_rev_no: string | null;
  due_date: Date | null;
  effective_date: Date | null;
  est_burden_cost: Generated<number>;
  est_labor_cost: Generated<number>;
  est_material_cost: Generated<number>;
  est_service_cost: Generated<number>;
  fixed_cost: number | null;
  fixed_qty: Generated<number>;
  fulfilled_qty: Generated<number>;
  height: number | null;
  inherit_warehouse: string | null;
  issued_qty: Generated<number>;
  length: number | null;
  location_id: string | null;
  mfg_name: string | null;
  mfg_part_id: string | null;
  operation_seq_no: number;
  orig_stage_revision_id: string | null;
  part_id: string | null;
  piece_no: number;
  planning_leadtime: number | null;
  protect_cost: Generated<string>;
  qty_per: number;
  qty_per_type: VisReqQtyPerType;
  reference: string | null;
  rem_burden_cost: Generated<number>;
  rem_labor_cost: Generated<number>;
  rem_material_cost: Generated<number>;
  rem_service_cost: Generated<number>;
  required_date: Date | null;
  required_for_setup: string | null;
  rowid: GeneratedAlways<number>;
  scrap_percent: Generated<number>;
  site_id: string;
  status: VisWorkOrderStatus;
  status_eff_date: Generated<Date>;
  subord_wo_sub_id: string | null;
  udf_layout_id: string | null;
  unit_burden_cost: Generated<number>;
  unit_labor_cost: Generated<number>;
  unit_material_cost: Generated<number>;
  unit_service_cost: Generated<number>;
  usage_um: string | null;
  user_1: string | null;
  user_10: string | null;
  user_2: string | null;
  user_3: string | null;
  user_4: string | null;
  user_5: string | null;
  user_6: string | null;
  user_7: string | null;
  user_8: string | null;
  user_9: string | null;
  vendor_id: string | null;
  vendor_part_id: string | null;
  warehouse_id: string | null;
  wbs_cdrl: string | null;
  wbs_clin: string | null;
  wbs_code: string | null;
  wbs_customer_wbs: string | null;
  wbs_description: string | null;
  wbs_tdc: string | null;
  width: number | null;
  workorder_base_id: string;
  workorder_lot_id: string;
  workorder_split_id: string;
  workorder_sub_id: string;
  workorder_type: VisWorkOrderType;
}

export type VisRequirement = Selectable<VisRequirementTable>;
export type VisRequirementInsert = Insertable<VisRequirementTable>;
export type VisRequirementUpdate = VisRequirementInsert;
