import { DisplayMagnitude } from '@prisma/client';

export const DISPLAY_MAGNITUDE_OPTIONS = [
  {
    label: "1's",
    value: DisplayMagnitude.ONES,
  },
  {
    label: "1000's (M)",
    value: DisplayMagnitude.THOUSANDS,
  },
];

export const DISPLAY_MAGNITUDE_MULTIPLIER = {
  [DisplayMagnitude.ONES]: 1,
  [DisplayMagnitude.THOUSANDS]: 1000,
} satisfies Record<DisplayMagnitude, number>;

export const DISPLAY_MAGNITUDE_POSTFIX = {
  [DisplayMagnitude.ONES]: '',
  [DisplayMagnitude.THOUSANDS]: 'M',
} satisfies Record<DisplayMagnitude, string>;

export const DISPLAY_MAGNITUDE_DESCRIPTION = {
  [DisplayMagnitude.ONES]: '',
  [DisplayMagnitude.THOUSANDS]: ' (in thousands)',
} satisfies Record<DisplayMagnitude, string>;
