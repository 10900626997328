import type { PaginationParams } from '@lib/models';
import {
  USER_SORT_FIELDS,
  type UserSearchQuery,
  type UserSortQuery,
} from '@lib/requests';
import type {
  MSGraphUserListResponse,
  PaginatedUserWithSettingsResponse,
  UserWithSettingsResponse,
} from '@lib/responses';
import { isInSet } from '@lib/util';
import type { SortingState } from '@tanstack/react-table';
import type { API } from '../api';

export class AdminUserService {
  constructor(private api: API) {}

  searchMSGraphUsers = (search: string) => {
    return this.api.get<MSGraphUserListResponse>(
      '/admin/users/ms-graph/users',
      { search },
    );
  };

  searchUsers = (
    search: string,
    { page, perPage }: PaginationParams,
    sort: SortingState = [],
  ) => {
    const query: UserSearchQuery = {
      search,
      page,
      perPage,
      sort: this.serializeSort(sort),
    };
    return this.api.get<PaginatedUserWithSettingsResponse>(
      '/admin/users',
      query,
    );
  };

  save = (user: UserWithSettingsResponse) => {
    return this.api.post<UserWithSettingsResponse>('/admin/users', user);
  };

  private serializeSort(sort: SortingState): UserSortQuery[] {
    const serializedSort = sort?.reduce((acc, cur) => {
      const { id, desc } = cur;

      if (isInSet(USER_SORT_FIELDS, id)) {
        acc.push(`${id}:${desc ? 'desc' : 'asc'}`);
      }

      return acc;
    }, [] as UserSortQuery[]);

    return serializedSort;
  }
}
