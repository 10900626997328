import { hasPermissions } from '@lib/permissions/permissions';
import type { PermissionsReqInput } from '@lib/requests/permissions';
import ErrorUnauthorized from '@ui/components/Errors/ErrorUnauthorized';
import { useAuth } from '@ui/state/auth';

type WithUserType = {
  children: React.ReactNode;
  permissions: PermissionsReqInput;
  showUnauthorized?: boolean;
};

export const WithPermission = ({
  children,
  permissions,
  showUnauthorized,
}: WithUserType) => {
  const { user } = useAuth();

  if (!user) {
    console.warn('WithPermission: expected user to be defined');
    return null;
  }

  if (!permissions || !Object.keys(permissions).length) {
    console.warn('WithPermission: missing component permissions');
    return null;
  }

  if (hasPermissions(permissions, user)) {
    return <>{children}</>;
  }

  return showUnauthorized ? <ErrorUnauthorized /> : null;
};
