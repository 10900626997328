import {
  PREREQUISITE_FIELD_IDS,
  PREREQUISITE_STEP_KEYS,
} from '@ui/features/configurator/data/constants/default-configuration/prerequisites';
import { SCRAP_YIELD_NONE_OPTION } from '@ui/features/configurator/data/constants/input-sources';
import type {
  ConfigurationFieldSchema,
  ConfigurationStepSchema,
} from '@ui/features/configurator/types';
import {
  contributionCostField,
  contributionPercentField,
  fixedScrapField,
  grossCostField,
  notesField,
  scrapYieldPercentageField,
  scrapYieldType,
} from './operations';

// Step Keys
const STEP_KEYS = {
  outside_service: 'outside_service',
} as const;

export const EXTERNAL_OPERATION_STEP_KEYS = STEP_KEYS;

// Field IDs
const FIELD_IDS = {
  service: 'service',
  standard_service_pricing: 'standard_service_pricing',
  vendor: 'vendor',
  transport_days: 'transport_days',
  base_charge: 'base_charge',
  base_charge_display: 'base_charge_display',
  unit_of_measure: 'unit_of_measure',
  minimum_charge: 'minimum_charge',
  minimum_charge_display: 'minimum_charge_display',
  price_per_unit: 'price_per_unit',
  price_per_unit_of_measure: 'price_per_unit_of_measure',
  price_per_unit_display: 'price_per_unit_display',
  estimated_service_cost: 'estimated_service_cost',
} as const;

export const EXTERNAL_OPERATION_FIELD_IDS = FIELD_IDS;

// Fields
const serviceField = {
  name: 'Service',
  field_id: FIELD_IDS.service,
  input_type: 'service',
  field_level: 'operation',
  description: 'Service and Vendor require a Quantity and Weight per Piece',
  input_source: {
    source_type: 'dynamic',
    source_key: 'service',
  },
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: PREREQUISITE_STEP_KEYS.material,
      field_id: PREREQUISITE_FIELD_IDS.material_quantity_per,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
} satisfies ConfigurationFieldSchema;

const standardServicePricingField = {
  name: 'Use Standard Pricing',
  field_id: FIELD_IDS.standard_service_pricing,
  input_type: 'boolean',
  hide_field: true,
  field_level: 'operation',
  default_value: 'true',
  validations: [],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
} satisfies ConfigurationFieldSchema;

const vendorField = {
  name: 'Vendor',
  field_id: FIELD_IDS.vendor,
  input_type: 'single_choice',
  hide_field: true,
  field_level: 'operation',
  input_source: {
    source_type: 'dynamic',
    source_key: 'vendor',
  },
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const unitOfMeasureField = {
  name: 'Unit of Measure',
  field_id: FIELD_IDS.unit_of_measure,
  input_type: 'single_choice',
  field_level: 'operation',
  hide_field: true,
  validations: [{ validate: 'required' }],
  input_source: {
    source_type: 'static',
    data_key: 'unit_of_measure',
  },
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const baseChargeField = {
  name: 'Base Charge',
  field_id: FIELD_IDS.base_charge,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'operation',
  hide_field: true,
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const minimumChargeField = {
  name: 'Minimum Charge',
  field_id: FIELD_IDS.minimum_charge,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'operation',
  hide_field: true,
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const pricePerUnitOfMeasureField = {
  name: 'Price Per Unit of Measure',
  field_id: FIELD_IDS.price_per_unit_of_measure,
  input_type: 'number',
  left_icon: 'dollar',
  field_level: 'operation',
  hide_field: true,
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const pricePerUnitField = {
  name: 'Price Per Unit',
  field_id: FIELD_IDS.price_per_unit,
  input_type: 'number',
  left_icon: 'dollar',
  hide_field: true,
  field_level: 'operation',
  validations: [{ validate: 'required' }],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const transportDaysField = {
  name: 'Transport Days',
  field_id: FIELD_IDS.transport_days,
  input_type: 'number',
  field_level: 'operation',
  validations: [],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.service,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const estimatedServiceCostField = {
  name: 'Estimated Service Cost',
  field_id: FIELD_IDS.estimated_service_cost,
  input_type: 'number',
  field_level: 'summary',
  hide_field: true,
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const externalScrapYieldType = {
  ...scrapYieldType,
  default_value: SCRAP_YIELD_NONE_OPTION,
} satisfies ConfigurationFieldSchema;

const baseChargeDisplayField = {
  name: 'Base Charge',
  field_id: FIELD_IDS.base_charge_display,
  input_type: 'display',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.base_charge,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
} satisfies ConfigurationFieldSchema;

const minimumChargeDisplayField = {
  name: 'Minimum Charge',
  field_id: FIELD_IDS.minimum_charge_display,
  input_type: 'display',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.minimum_charge,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
} satisfies ConfigurationFieldSchema;

const pricePerUnitDisplayField = {
  name: 'Price Per Each',
  field_id: FIELD_IDS.price_per_unit_display,
  input_type: 'display',
  left_icon: 'dollar',
  field_level: 'summary',
  validations: [],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.price_per_unit,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
} satisfies ConfigurationFieldSchema;

// Steps
export const defaultExternalServiceStep = {
  id: 'AFE4DAF3-A81D-4D00-A960-4C8A62950510',
  step_key: STEP_KEYS.outside_service,
  name: 'Outside Service',
  step_type: 'operation',
  operation_type: 'external',
  required: false,
  reusable: true,
  fields: [
    serviceField,
    standardServicePricingField,
    vendorField,
    unitOfMeasureField,
    baseChargeField,
    minimumChargeField,
    pricePerUnitOfMeasureField,
    pricePerUnitField,
    transportDaysField,
    externalScrapYieldType,
    scrapYieldPercentageField,
    fixedScrapField,
    notesField,
    baseChargeDisplayField,
    minimumChargeDisplayField,
    pricePerUnitDisplayField,
    estimatedServiceCostField,
    contributionCostField,
    /**
     * @NOTE(shawk): Gross Cost is by default hidden for internal operations
     * because it is calculated to include an extraneous extra material cost
     * for Operation 10 (since we attach material to that operation).
     *
     * For Outside Operations, it appears to be correct.
     *
     * @ref https://github.com/mwcomponents/quickquote/pull/562
     */
    { ...grossCostField, hide_field: false },
    contributionPercentField,
  ],
} satisfies ConfigurationStepSchema;
