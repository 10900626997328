import type { CostCalculations } from './costs';
import { type RoundPrecision, round } from './util';

// allow specific calculations to be rounded to a different precision
const roundPrecisionDefault = { min: 2, small: 4 };
const roundPrecisionByProp: {
  [key in keyof MarginCalculations]?: RoundPrecision;
} = {
  contributionMarginRatio: { min: 4, small: 4, max: 4 },
  contributionMarginPercent: { min: 2, small: 2, max: 2 },
  grossMarginRatio: { min: 4, small: 4, max: 4 },
  grossMarginPercent: { min: 2, small: 2, max: 2 },
};

// creates a wrapper around the object that rounds final calculations,
// allowing internal calculations to be done with higher precision
const handler = {
  get: (
    target: MarginCalculations,
    prop: keyof MarginCalculations,
  ): number | undefined => {
    const value = Reflect.get(target, prop);
    return typeof value === 'number'
      ? round(value, roundPrecisionByProp[prop] ?? roundPrecisionDefault)
      : value;
  },
};

export type MarginCalculations = {
  /** The total contribution margin rounded to the closest 4 decimal places.*/
  contributionMargin: number;
  /** The contribution margin ratio as percentage rounded to the closest 3 decimal places. */
  contributionMarginRatio: number;
  /** The contribution margin ratio as percentage rounded to 4 significant digits. */
  contributionMarginPercent: number;
  /** The total margin as decimal rounded to the closest 4 decimal places. */
  grossMargin: number;
  /** The gross margin ratio as percentage rounded to the closest 3 decimal places. */
  grossMarginRatio: number;
  /** The gross margin ratio as percentage rounded to 4 significant digits. */
  grossMarginPercent: number;
};

/**
 * Creates margin calculations for a given price and costs.
 * Can be either total or unit price & costs but **must be consistent**.
 * Individual margins will be 0 if we don't have enough data to calculate.
 */
export const calcMargins = (
  price: number,
  costCalcs: CostCalculations,
): MarginCalculations => {
  const calculations = {
    get contributionMargin(): number {
      const { contributionCost: variableCost } = costCalcs;
      // careful to prevent dividing by 0
      return variableCost && price && price - variableCost;
    },
    get contributionMarginRatio(): number {
      // careful to prevent dividing by 0
      return price && this.contributionMargin / price;
    },
    get contributionMarginPercent(): number {
      return this.contributionMarginRatio * 100;
    },
    get grossMargin(): number {
      const { grossCost: totalCost } = costCalcs;
      return totalCost && price - totalCost;
    },
    get grossMarginRatio(): number {
      // careful to prevent dividing by 0
      return price && this.grossMargin / price;
    },
    get grossMarginPercent(): number {
      return this.grossMarginRatio * 100;
    },
  };

  return new Proxy(calculations, handler);
};
