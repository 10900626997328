import type { PaginationParams, WithPagination } from '@lib/models';
import type {
  ConfiguratorCreatePartRequest,
  ConfiguratorSnapshotSearchQuery,
} from '@lib/requests';
import type {
  ConfiguratorCreatePartResponse,
  ConfiguratorSnapshotGetManyResponse,
  SelectOptionWithMetadata,
} from '@lib/responses';
import { configurations } from '@ui/features/configurator/data';
import type {
  Configuration,
  ConfiguratorDynamicFieldDefinition,
} from '@ui/features/configurator/types';
import type { PaginationState } from '@ui/hooks/pagination';
import type { API } from './api';

export class ConfiguratorService {
  constructor(private api: API) {}

  // TODO: configurator - Wire up to real service when database-backed
  // configurations are available
  getConfiguration = (id: string): Configuration => {
    const configuration = configurations.find(
      (configuration) => id === configuration.id,
    );

    if (!configuration) {
      throw new Error('Configuration not found');
    }

    return configuration;
  };

  // TODO: configurator - Wire up to real service when database-backed
  // configurations are available
  getConfigurations = (): Configuration[] => {
    return configurations;
  };

  /**
   * Fetches the records for a given field then maps them to select options
   * based on it's FieldDefinition.
   */
  getFieldOptions = <
    Meta extends Record<string, unknown>,
    Params extends Record<string, unknown>,
  >({
    fieldDefinition,
    query,
    pagination: { page, perPage },
    params,
  }: {
    fieldDefinition: ConfiguratorDynamicFieldDefinition<Meta, Params>;
    query: string;
    pagination: PaginationState;
    productCode?: string;
    params?: Params;
  }): Promise<WithPagination<SelectOptionWithMetadata<string, Meta>>> => {
    if (!fieldDefinition.endpoints.list) {
      throw new Error(
        'Cannot query for field options without an endpoint specified',
      );
    }

    return this.api
      .get(fieldDefinition.endpoints.list, {
        q: query,
        page,
        perPage,
        params,
      })
      .then((result) => {
        return {
          data: fieldDefinition.mapOptions(result.data),
          pagination: result.pagination,
        };
      });
  };

  getSingleOption = <
    Meta extends Record<string, unknown>,
    Params extends Record<string, unknown>,
  >({
    id,
    fieldDefinition,
    params,
  }: {
    id: string;
    fieldDefinition: ConfiguratorDynamicFieldDefinition<Meta, Params>;
    params?: Params;
  }): Promise<SelectOptionWithMetadata<string, Meta>> => {
    if (!fieldDefinition.endpoints.get) {
      throw new Error(
        'Cannot query for field options without an endpoint specified',
      );
    }

    return this.api
      .get(`${fieldDefinition.endpoints.get}/${encodeURIComponent(id)}`, params)
      .then(fieldDefinition.mapOption);
  };

  createConfiguratedPart = async (
    data: ConfiguratorCreatePartRequest,
  ): Promise<ConfiguratorCreatePartResponse> => {
    return this.api.post('/configurator/part', data);
  };

  searchConfiguratorSnapshots = (
    search: string,
    { page, perPage }: PaginationParams,
  ) => {
    const query: ConfiguratorSnapshotSearchQuery = {
      search,
      page,
      perPage,
    };
    return this.api.get<ConfiguratorSnapshotGetManyResponse>(
      '/configurator/snapshot/search',
      query,
    );
  };
}

/**
 * @TODO(shawk): There are cases where this results in an infinite loop of
 * HMR invalidations causing vite to repeatedly fire off HMR update checks.
 * Bummer, dude.
 *
 * DEVELOPMENT ONLY (if HMR is enabled)
 *
 * Propagate HMR updates to importers (allows for patching the configurator
 * store with updated static configuration data).
 *
 * Ref: https://vitejs.dev/guide/api-hmr
 */
// if (import.meta.hot) {
//   import.meta.hot.accept(() => {
//     import.meta.hot?.invalidate();
//   });
// }
