import {
  DollarSignIcon,
  ErrorIcon,
  FileIcon,
  HashtagIcon,
  PercentIcon,
  StickyNoteIcon,
} from '@ui/components/Icons';
import type { CustomIconProps } from '@ui/components/Icons/types';
import theme from '@ui/config/theme';
import type { ConfiguratorInputIconOption } from '../../types';

const configuratorIcons: Record<ConfiguratorInputIconOption, React.FC> = {
  dollar: DollarSignIcon,
  error: ErrorIcon,
  file: FileIcon,
  hashtag: HashtagIcon,
  percent: PercentIcon,
  stickyNote: StickyNoteIcon,
};

type ConfiguratorInputIconProps = CustomIconProps & {
  icon?: ConfiguratorInputIconOption;
};

/**
 * Renders an icon defined in a configuration.
 *
 * Should be used with configurator input components.
 */
export const ConfiguratorInputIcon = ({
  icon,
  ...props
}: ConfiguratorInputIconProps) => {
  if (icon) {
    const Icon = configuratorIcons[icon];

    return <Icon width={10} color={theme.colors.input.icon} {...props} />;
  }

  return null;
};
