import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import type { ConfiguratorCalculatedNumberFieldType } from '@ui/features/configurator/types';
import { memo } from 'react';
import { ConfiguratorInputIcon } from './InputIcon';
import { ConfiguratorInputWrapper } from './InputWrapper';
import { inputStyles } from './styles';
import type { ConfiguratorInputProps } from './types';

const CalculatedNumberInputComponent = ({
  field,
  ...widths
}: ConfiguratorInputProps<ConfiguratorCalculatedNumberFieldType>) => {
  return (
    <ConfiguratorInputWrapper field={field} {...widths}>
      <InputGroup>
        {field.leftIcon && (
          <InputLeftElement pointerEvents="none">
            <ConfiguratorInputIcon icon={field.leftIcon} />
          </InputLeftElement>
        )}

        <Input
          id={field.fieldKey}
          type="text"
          isDisabled
          value={
            (field.value as ConfiguratorCalculatedNumberFieldType['value']) ??
            ''
          }
          placeholder={field.placeholder}
          {...inputStyles}
        />

        {field.rightIcon && (
          <InputRightElement pointerEvents="none">
            <ConfiguratorInputIcon icon={field.rightIcon} />
          </InputRightElement>
        )}
      </InputGroup>
    </ConfiguratorInputWrapper>
  );
};

/**
 * Calculated (disabled) text input for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorCalculatedNumberInput = memo(
  CalculatedNumberInputComponent,
);
