import { UnitsPerPieceType as KyUnitsPerPieceType } from '@lib/kysely/qq-type-gen/enums';
import { VisReqQtyPerType } from '@lib/kysely/visual/tables/requirement';
import { UnitsPerPieceType } from '@prisma/client';

export const perPieceTypeSnapMap = {
  [VisReqQtyPerType.START]: UnitsPerPieceType.START,
  [VisReqQtyPerType.END]: UnitsPerPieceType.END,
} as const satisfies Record<VisReqQtyPerType, UnitsPerPieceType>;

export const perPieceTypeKyselyMap = {
  [VisReqQtyPerType.START]: KyUnitsPerPieceType.START,
  [VisReqQtyPerType.END]: KyUnitsPerPieceType.END,
} as const satisfies Record<VisReqQtyPerType, KyUnitsPerPieceType>;

export function mapSnapPerPieceType(vals: string[]): UnitsPerPieceType[] {
  return vals.map((v) => {
    if (v in perPieceTypeSnapMap) {
      return perPieceTypeSnapMap[v as VisReqQtyPerType];
    }

    return UnitsPerPieceType.START;
  });
}

export function unmapSnapPerPieceType(
  vals: UnitsPerPieceType[],
): VisReqQtyPerType[] {
  return vals.map((v) => {
    const visualPerPieceType = Object.entries(perPieceTypeSnapMap).find(
      ([_k, val]) => v === val,
    );

    if (visualPerPieceType) {
      return visualPerPieceType[0] as VisReqQtyPerType;
    }

    return VisReqQtyPerType.START;
  });
}

export function mapKyselyPerPieceType(vals: string[]): KyUnitsPerPieceType[] {
  return vals.map((v) => {
    if (v in perPieceTypeKyselyMap) {
      return perPieceTypeKyselyMap[v as VisReqQtyPerType];
    }

    return KyUnitsPerPieceType.START;
  });
}

export function unmapKyselyPerPieceType(
  vals: KyUnitsPerPieceType[],
): VisReqQtyPerType[] {
  return vals.map((v) => {
    const visualPerPieceType = Object.entries(perPieceTypeKyselyMap).find(
      ([_k, val]) => v === val,
    );

    if (visualPerPieceType) {
      return visualPerPieceType[0] as VisReqQtyPerType;
    }

    return VisReqQtyPerType.START;
  });
}
