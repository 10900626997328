import type { WritableConfiguratorStoreState } from '@ui/features/configurator/types';
import type { ConfiguratorAction } from './types';

export type SetFieldErrorAction = ConfiguratorAction<
  'setFieldError',
  { fieldKey: string; error: string | null }
>;

export const setFieldError = (
  fieldKey: string,
  error: string | null,
): SetFieldErrorAction => ({
  type: 'setFieldError',
  payload: {
    fieldKey,
    error,
  },
});

/**
 * Updates a step's error and validation state directly.
 */
export function setFieldErrorAction(
  state: WritableConfiguratorStoreState,
  fieldKey: string,
  error: string | null,
) {
  if (error) {
    state.fields[fieldKey].isInvalid = true;
    state.fields[fieldKey].errors = [error];
  } else {
    state.fields[fieldKey].isInvalid = false;
    state.fields[fieldKey].errors = [];
  }
}
