import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { WarningIcon } from '@ui/components/Icons';
import { DataSourceError } from '@ui/features/configurator/data';
import { ErrorMonitoring } from '@ui/services/error-monitoring';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';

export function ConfiguratorFieldErrorBoundary({
  children,
}: {
  children: React.ReactNode;
}) {
  const queryClient = useQueryClient();

  return (
    <ErrorBoundary
      onError={(error, info) => {
        ErrorMonitoring.captureException(error, {
          extra: {
            errorBoundary: 'ConfiguratorField',
            errorInfo: info,
          },
        });
      }}
      onReset={async (details) => {
        if (details.reason === 'imperative-api') {
          if (details.args[0] instanceof DataSourceError) {
            await details.args[0].resetQuery(queryClient);
          }
        }
      }}
      fallbackRender={(props) => <FallbackUI {...props} />}
    >
      {children}
    </ErrorBoundary>
  );
}

function FallbackUI({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <Box minWidth={180}>
      <Flex alignItems="center" gap={1}>
        <WarningIcon color="mw.darkYellow" />

        <Text fontWeight="semibold" fontSize="small">
          Whoops!
        </Text>
      </Flex>

      <Text fontSize="x-small" mb={1}>
        {error.message}
      </Text>

      <Button
        onClick={() => resetErrorBoundary(error)}
        size="xs"
        variant="outline"
      >
        Try again
      </Button>
    </Box>
  );
}
