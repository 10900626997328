import type { ValidationResult } from './types';

export const validResult: ValidationResult = {
  valid: true,
  errors: [],
};

export function buildValidResult(): ValidationResult {
  return structuredClone(validResult);
}
