import { type EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import type { SiteWithConfigResponse } from '@lib/responses/site';
import type { UserWithSettingsResponse } from '@lib/responses/user';
import { siteService, userService } from '@ui/services';
import { useEffect, useMemo, useState } from 'react';
import { AuthContext } from './context';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<UserWithSettingsResponse | null>(null);
  const [currentSite, setCurrentSiteState] =
    useState<SiteWithConfigResponse | null>(null);

  const [isError, setIsError] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const { instance: msalInstance } = useMsal();

  // we will listen to account removed events so we can handle if the user is
  // logged out from another tab/window
  useEffect(() => {
    msalInstance.enableAccountStorageEvents();
    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.ACCOUNT_REMOVED) {
        handleOutsideLogout();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = async () => {
    // api call to get user data
    try {
      const userResponse = await userService.getUser();
      setIsLoggedOut(false);
      setIsError(false);
      setUser(userResponse);

      const sites = userResponse.sites ?? [];

      // if no active site set, use the first one in the user.sites array
      if (!userResponse.lastActiveSiteId && sites.length) {
        setCurrentSite(sites[0].id);
      } else if (userResponse.lastActiveSiteId) {
        setCurrentSite(userResponse.lastActiveSiteId);
      }
    } catch (error) {
      console.error('Get user error: ', error);
      setIsError(true);
    }
  };

  /**
   * Fetches site from API and site config
   * @param siteId site uuid
   */
  const setCurrentSite = async (siteId: string) => {
    const userResponse = await userService.setActiveSite(siteId);

    if (!userResponse.lastActiveSite) {
      return;
    }

    const siteWithConfig = await siteService.getWithConfig(
      userResponse.lastActiveSite.code,
    );

    setCurrentSiteState(siteWithConfig);
  };

  const logout = () => {
    const currentAccount = msalInstance.getActiveAccount();
    // state will be reset by redirect
    msalInstance.logoutRedirect({ account: currentAccount });
  };

  const handleOutsideLogout = () => {
    setIsLoggedOut(true);
    setUser(null);
    setIsError(false);
  };

  const value = useMemo(
    () => ({
      user,
      getProfile,
      logout,
      isError,
      isLoggedOut,
      currentSite,
      setCurrentSite,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, isError, isLoggedOut, currentSite],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
