import type {
  ConfigurationFieldSchema,
  ConfigurationStepSchema,
} from '@ui/features/configurator/types';

// Step Keys
const STEP_KEYS = {
  base_fields: 'base_fields',
  material: 'material',
  description: 'description',
} as const;

export const PREREQUISITE_STEP_KEYS = STEP_KEYS;

// Field IDs
const FIELD_IDS = {
  quantity: 'quantity',
  customer_part_id: 'customer_part_id',
  product_code: 'product_code',
  material: 'material',
  material_cost: 'material_cost',
  material_quantity_per: 'material_quantity_per',
  description: 'description',
} as const;

export const PREREQUISITE_FIELD_IDS = FIELD_IDS;

// Fields
export const quantityField = {
  name: 'Quantity',
  field_id: FIELD_IDS.quantity,
  input_type: 'number',
  left_icon: 'hashtag',
  validations: [
    {
      validate: 'required',
      allow_zero: false,
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

export const customerPartIdField = {
  name: 'Customer Part ID',
  field_id: FIELD_IDS.customer_part_id,
  input_type: 'text',
  validations: [],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

export const productCodeField = {
  name: 'Product Code',
  field_id: FIELD_IDS.product_code,
  input_type: 'single_choice',
  field_level: 'operation',
  input_source: {
    source_type: 'static',
    data_key: 'product_code',
  },
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const materialField = {
  name: 'Material',
  field_id: FIELD_IDS.material,
  input_type: 'material',
  input_source: {
    source_type: 'dynamic',
    source_key: 'material',
  },
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

const materialCostField = {
  name: 'Material Cost',
  field_id: FIELD_IDS.material_cost,
  input_type: 'number',
  left_icon: 'dollar',
  validations: [
    {
      validate: 'required',
      allow_zero: false,
    },
  ],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.material,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
} satisfies ConfigurationFieldSchema;

const materialQuantityPerField = {
  name: 'Weight Per Piece (lbs)',
  field_id: FIELD_IDS.material_quantity_per,
  input_type: 'number',
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [
    {
      step_id: null,
      field_id: FIELD_IDS.material,
      dependency_type: 'simple',
    },
  ],
  dependency_mode: 'enable',
  clear_on_noninteractive: true,
  calculator: 'VolumeCalculator',
} satisfies ConfigurationFieldSchema;

const descriptionField = {
  name: 'Description',
  field_id: FIELD_IDS.description,
  input_type: 'description',
  validations: [{ validate: 'required' }],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

// Steps
export const defaultBaseFieldsStep = {
  id: 'C182AABE-CCF6-4D8B-9649-E9B4F6E21E6A',
  step_key: STEP_KEYS.base_fields,
  name: 'Base Fields',
  step_type: 'prerequisite',
  fields: [quantityField, productCodeField, customerPartIdField],
} satisfies ConfigurationStepSchema;

export const defaultMaterialStep = {
  id: '581CC3F5-6997-4D21-A5AF-1EC104252B00',
  step_key: STEP_KEYS.material,
  name: 'Material',
  step_type: 'prerequisite',
  fields: [materialField, materialCostField, materialQuantityPerField],
} satisfies ConfigurationStepSchema;

export const defaultDescriptionStep = {
  id: '549B361B-7A30-44AB-9600-616A693B154B',
  step_key: STEP_KEYS.description,
  name: 'Description',
  step_type: 'prerequisite',
  fields: [descriptionField],
} satisfies ConfigurationStepSchema;
