import { PricingStrategy } from '@prisma/client';
import type { Constructor } from 'type-fest/source/basic';
import type { AbstractPricingStrategy } from './abstract';
import { FixedPricingStrategy } from './fixed';
import { RafCustomPricingStrategy } from './raf-custom';
import { TargetContributionStrategy } from './target-contribution';
import { TargetGrossStrategy } from './target-gross';

// "register" new strategies here and in PricingStrategy enum (db schema)

export const PricingStrategyMap: Readonly<
  Record<PricingStrategy, Constructor<AbstractPricingStrategy>>
> = {
  [PricingStrategy.TARGET_CONTR_MARGIN]: TargetContributionStrategy,
  [PricingStrategy.TARGET_GROSS_MARGIN]: TargetGrossStrategy,
  [PricingStrategy.FIXED]: FixedPricingStrategy,
  [PricingStrategy.RAF_CUSTOM]: RafCustomPricingStrategy,
} as const;
