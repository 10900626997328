import type { WritableConfiguratorStoreState } from '@ui/features/configurator/types';
import type { ConfiguratorAction } from './types';

export type MoveOperationAction = ConfiguratorAction<
  'moveOperation',
  { stepKey: string; index: number }
>;

export const moveOperation = (
  stepKey: string,
  index: number,
): MoveOperationAction => ({
  type: 'moveOperation',
  payload: {
    stepKey,
    index,
  },
});

export function moveOperationAction(
  state: WritableConfiguratorStoreState,
  stepId: string,
  index: number,
) {
  const currentIndex = state.blueprint.operations.findIndex(
    (operation) => operation.stepId === stepId,
  );

  if (currentIndex === index) {
    return;
  }

  const [operation] = state.blueprint.operations.splice(currentIndex, 1);

  state.blueprint.operations.splice(index, 0, operation);
}
