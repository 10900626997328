import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import type { UserResponse } from '@lib/responses/user';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

interface IdleLogoutProps {
  timeoutSeconds: number;
  promptBeforeSeconds: number;
  user: UserResponse | null;
  logout: () => void;
}

const IdleLogout: React.FC<IdleLogoutProps> = ({
  timeoutSeconds,
  promptBeforeSeconds,
  user,
  logout,
}) => {
  const [remaining, setRemaining] = useState<number>(timeoutSeconds);
  const [open, setOpen] = useState<boolean>(false);

  const timeout = timeoutSeconds * 1000;
  const promptBeforeIdle = promptBeforeSeconds * 1000;

  const onIdle = () => {
    handleIdleLogout();
  };
  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { start, getRemainingTime, isLeader, activate } = useIdleTimer({
    onIdle,
    onActive,
    timeout,
    promptBeforeIdle,
    onPrompt,
    crossTab: true,
    leaderElection: true,
    syncTimers: 1000,
    startManually: true,
    startOnMount: false,
  });

  useEffect(() => {
    // start the timer when user is set
    if (user) {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStillHere = () => {
    activate();
    setOpen(false);
  };

  const handleIdleLogout = () => {
    if (isLeader()) {
      logout();
    }
  };

  const formatTime = (seconds: number): string => {
    if (seconds < 60) {
      return `${String(seconds)} seconds`;
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const paddedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${minutes}:${paddedSeconds.padStart(2, '0')}`;
  };

  return open ? (
    <Modal isOpen={open} onClose={handleStillHere} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody textAlign="center" pt="16px">
          {`${formatTime(remaining)} until you are logged out for inactivity`}
        </ModalBody>
        <ModalFooter alignItems={'center'}>
          <Center w="100%">
            <Button colorScheme="blue" onClick={handleStillHere}>
              Stay logged in
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : null;
};

export default IdleLogout;
