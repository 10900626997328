import {
  Box,
  type BoxProps,
  Link,
  Text,
  type TextProps,
  Tooltip,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { NavigationItem } from '.';

interface NavItemProps {
  item: NavigationItem;
  curPath: string;
  isCollapsed: boolean;
  containerProps?: BoxProps;
  textProps?: TextProps;
}

export const NavItem: FC<NavItemProps> = ({
  curPath,
  item,
  containerProps = {},
  textProps = {},
  isCollapsed,
}) => {
  return (
    <Link
      key={`${item.key}-${item.path.replace('/', '')}`}
      as={RouterLink}
      to={item.path}
      width={'100%'}
      height={'50px'}
      alignItems="center"
      display={'flex'}
      textDecoration="none !important"
      title={item.label}
      justifyContent={isCollapsed ? 'center' : 'flex-start'}
      color={'mw.white'}
      opacity={curPath === item.path ? 1 : 0.72}
      _hover={{
        opacity: 1,
      }}
      margin={0}
    >
      <Box
        display={isCollapsed ? 'inline-block' : 'flex'}
        fontSize={16}
        alignItems="center"
        marginLeft={isCollapsed ? 0 : 4}
        {...containerProps}
      >
        <Tooltip label={item.label} placement="right" isDisabled={!isCollapsed}>
          <FontAwesomeIcon icon={item.icon} width={36} />
        </Tooltip>

        <Text
          display={'inline-block'}
          opacity={!isCollapsed ? 1 : 0}
          maxWidth={!isCollapsed ? 'unset' : 0}
          maxHeight={!isCollapsed ? 'unset' : 0}
          wordBreak="break-word"
          overflow="hidden"
          marginLeft={!isCollapsed ? 3 : 0}
          fontSize={'.875rem'}
          fontWeight={'semibold'}
          fontFamily={'navigationItem'}
          {...textProps}
        >
          {item.label}
        </Text>
      </Box>
    </Link>
  );
};
