import is from '@sindresorhus/is';
import { z } from 'zod';

export const requiredDecimalString = (label: string) =>
  z
    .string()
    .min(1, { message: `${label} can’t be blank` })
    .and(
      z.custom<`${number}`>(is.numericString, {
        message: `${label} Must be a number`,
      }),
    );

export const nullableDecimalString = z
  .string()
  // @NOTE(shawk): we can't easily use `z.coerce.number()` without erroneously
  // coercing nulls and empty strings to `0`
  .refine(is.numericString, {
    message: 'Must be a number',
  })
  .nullable()
  .or(z.literal('').transform(() => null));

// @NOTE(shawk): nullable ints come out of the database as numbers but are
// strings when validating form data
export const nullableInt = z.union([
  // map empty string to `null`
  z
    .literal('')
    .nullable()
    .transform(() => null),
  // validate number is positive and truncate any decimal values
  z
    .number()
    .gt(0)
    .or(z.string().pipe(z.coerce.number().gte(0)))
    .transform((val) => Number.parseInt(`${val}`, 10)),
]);
