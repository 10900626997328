import type {
  ConfiguratorFieldState,
  ConfiguratorRequiredValidation,
} from '@ui/features/configurator/types';
import { validResult } from './result';
import type { ValidationResult } from './types';

export function validateRequired(
  fields: ConfiguratorFieldState,
  fieldKey: string,
  { allow_zero = true }: ConfiguratorRequiredValidation,
): ValidationResult {
  const field = fields[fieldKey];

  if (field.value === 0) {
    if (allow_zero) {
      return validResult;
    }

    return {
      valid: false,
      errors: [`${field.name} cannot be zero`],
    };
  }

  if (!field.value) {
    return {
      valid: false,
      errors: [`${field.name} is required`],
    };
  }

  return validResult;
}
