import { paginationSchema } from '@lib/models/pagination';
import type { TupleElements } from '@lib/util/types';
import type { Prisma } from '@prisma/client';
import { z } from 'zod';
import { type SortDirection, type SortQuery, _SORT_DIRECTIONS } from './common';

export type UserSettingsUpdateRequest = {
  displayName: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  signatureTitle: string;
  emailQuoteToCustomer: boolean;
  visualUserName: string;
};

export const UserRolesUpdateQuery = z.object({
  roleIds: z.string().uuid().or(z.array(z.string().uuid()).nonempty()),
});
export type UserRolesUpdateQuery = z.infer<typeof UserRolesUpdateQuery>;

// TODO: combine below and in ./quote.ts into a generic/common search type
const _USER_SORT_FIELDS = ['displayName', 'email'] as const;

export const USER_SORT_FIELDS = new Set(_USER_SORT_FIELDS);

export type UserSortRequestFields = TupleElements<typeof _USER_SORT_FIELDS>;

export type UserSortQuery = SortQuery<UserSortRequestFields>;
export const UserSortQuery = z
  .string()
  .superRefine((val, ctx): val is UserSortQuery => {
    const [field, direction] = val.split(':');
    // test for valid sort field
    if (!USER_SORT_FIELDS.has(field as UserSortRequestFields)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: `invalid sort field, must be one of: ${_USER_SORT_FIELDS.join(
          ', ',
        )}`,
      });
      return z.NEVER;
    }
    // test for valid sort direction
    if (!_SORT_DIRECTIONS.includes(direction as SortDirection)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: `invalid sort direction, must be one of: ${_SORT_DIRECTIONS.join(
          ', ',
        )}`,
      });
      return z.NEVER;
    }

    return true;
  });

export const UserGetManyQuery = z
  .object({ sort: z.array(UserSortQuery).default([]) })
  .merge(paginationSchema);

export type UserGetManyQuery = z.infer<typeof UserGetManyQuery>;

export const UserSearchQuery = UserGetManyQuery.extend({
  search: z.string().optional(),
});
export type UserSearchQuery = z.infer<typeof UserSearchQuery>;

export type UserCreateRequest = Prisma.UserUncheckedCreateInput;
