import { VisOpScrapYieldType } from '@lib/kysely/visual/tables/operation';
import { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import { RunRateType, ScrapYieldType } from '@prisma/client';

export const runRateTypeToSnapMap = {
  [VisOpRunRateType.PCS_PER_HR]: RunRateType.PCS_PER_HR,
  [VisOpRunRateType.HRS_PER_PC]: RunRateType.HRS_PER_PC,
  [VisOpRunRateType.MIN_PER_PC]: RunRateType.MIN_PER_PC,
  [VisOpRunRateType.DAYS_PER_PC]: RunRateType.DAYS_PER_PC,
  [VisOpRunRateType.HRS_PER_LOAD]: RunRateType.HRS_PER_LOAD,
  [VisOpRunRateType.DAYS_PER_LOAD]: RunRateType.DAYS_PER_LOAD,
  [VisOpRunRateType.PCS_PER_MIN]: RunRateType.PCS_PER_MIN,
  [VisOpRunRateType.PCS_PER_DAY]: RunRateType.PCS_PER_DAY,
  [VisOpRunRateType.LOADS_PER_HOUR]: RunRateType.LOADS_PER_HR,
  [VisOpRunRateType.LOADS_PER_DAY]: RunRateType.LOADS_PER_DAY,
} as const satisfies Record<VisOpRunRateType, RunRateType>;

export const runRateTypeToVisualMap = {
  [RunRateType.PCS_PER_HR]: VisOpRunRateType.PCS_PER_HR,
  [RunRateType.HRS_PER_PC]: VisOpRunRateType.HRS_PER_PC,
  [RunRateType.MIN_PER_PC]: VisOpRunRateType.MIN_PER_PC,
  [RunRateType.DAYS_PER_PC]: VisOpRunRateType.DAYS_PER_PC,
  [RunRateType.HRS_PER_LOAD]: VisOpRunRateType.HRS_PER_LOAD,
  [RunRateType.DAYS_PER_LOAD]: VisOpRunRateType.DAYS_PER_LOAD,
  [RunRateType.PCS_PER_MIN]: VisOpRunRateType.PCS_PER_MIN,
  [RunRateType.PCS_PER_DAY]: VisOpRunRateType.PCS_PER_DAY,
  [RunRateType.LOADS_PER_HR]: VisOpRunRateType.LOADS_PER_HOUR,
  [RunRateType.LOADS_PER_DAY]: VisOpRunRateType.LOADS_PER_DAY,
} as const satisfies Record<RunRateType, VisOpRunRateType>;

export const scrapYieldTypeToSnapMap = {
  [VisOpScrapYieldType.SCRAP]: ScrapYieldType.SCRAP,
  [VisOpScrapYieldType.YIELD]: ScrapYieldType.YIELD,
} as const satisfies Record<VisOpScrapYieldType, ScrapYieldType>;

export const scrapYieldTypeToVisualMap = {
  [ScrapYieldType.SCRAP]: VisOpScrapYieldType.SCRAP,
  [ScrapYieldType.YIELD]: VisOpScrapYieldType.YIELD,
} as const satisfies Record<ScrapYieldType, VisOpScrapYieldType>;
