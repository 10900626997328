import type { ModalProps } from '@ui/components/layout/Modal';
import { useStickyState } from '@ui/hooks';
import { useMemo, useState } from 'react';
import { GlobalStateContext } from './context';

export function GlobalStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeModal, setActiveModal] = useState<ModalProps | null>(null);
  // @TODO(NT): We should just yoink this out completely
  // @SEE: https://mwcomponents.atlassian.net/browse/MW-870
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [collapsedNavigation, setCollapsedNavigation] = useStickyState<any>(
    true,
    'mwqq_collapsedNavigation',
  );

  const value = useMemo(
    () => ({
      activeModal,
      setActiveModal,
      collapsedNavigation,
      setCollapsedNavigation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeModal, collapsedNavigation],
  );

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
}
