import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { isError } from '@sindresorhus/is';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { setFieldError, updateField } from '@ui/features/configurator/store';
import { getNumberFieldValueFromEvent } from '@ui/features/configurator/store/helpers/field-helpers';
import type { ConfiguratorNumberFieldType } from '@ui/features/configurator/types';
import { memo, type ChangeEvent } from 'react';
import { ConfiguratorInputIcon } from './InputIcon';
import { ConfiguratorInputWrapper } from './InputWrapper';
import { inputStyles } from './styles';
import type { ConfiguratorInputProps } from './types';

function NumberInputComponent({
  field,
  onChange,
  ...widths
}: ConfiguratorInputProps<ConfiguratorNumberFieldType> & {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  const { dispatch } = useConfiguratorStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      dispatch(updateField(field.fieldKey, getNumberFieldValueFromEvent(e)));
    } catch (e: unknown) {
      dispatch(
        setFieldError(
          field.fieldKey,
          isError(e) ? e.message : 'Unexpected error',
        ),
      );
    }
  };

  return (
    <ConfiguratorInputWrapper field={field} {...widths}>
      <InputGroup>
        {field.leftIcon && (
          <InputLeftElement pointerEvents="none">
            <ConfiguratorInputIcon icon={field.leftIcon} />
          </InputLeftElement>
        )}

        <Input
          id={field.fieldKey}
          type="number"
          onChange={onChange ?? handleChange}
          value={field.value ?? ''}
          placeholder={field.placeholder}
          {...inputStyles}
        />

        {field.rightIcon && (
          <InputRightElement pointerEvents="none">
            <ConfiguratorInputIcon icon={field.rightIcon} />
          </InputRightElement>
        )}
      </InputGroup>
    </ConfiguratorInputWrapper>
  );
}

/**
 * Number input for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorNumberInput = memo(NumberInputComponent);
