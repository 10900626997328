import type { API } from '../api';

export class AdminMetricsService {
  constructor(private api: API) {}

  getByDate = async (startDate: Date, endDate: Date) => {
    const query = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    return this.api.get('/admin/metrics', query);
  };
}
