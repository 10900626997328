import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import type { CustomIconProps } from './types';

/**
 * Renders a file icon with an optional badge.
 *
 * @see Docs https://fontawesome.com/icons/file-lines?f=classic&s=regular
 */
export const FileIcon: React.FC<CustomIconProps> = (props) => {
  return <Icon icon={faFileLines} {...props} />;
};
