import type { VisServiceQueryRecord } from '@lib/kysely/visual/queries';
import { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import { z } from 'zod';

export const configuratorServiceSchema = z.object({
  id: z.string().transform((s) => s.toUpperCase()),
  description: z
    .string()
    .nullable()
    .transform((s) => s?.toUpperCase() ?? null),
  run: z.number().nullable(),
  run_type: z
    .string()
    .nullable()
    .transform((v) => (v ? v.trim() : null))
    .pipe(z.nativeEnum(VisOpRunRateType))
    .nullable(),
  vendor_id: z
    .string()
    .nullable()
    .transform((s) => s?.toUpperCase() ?? null),
}) satisfies Zod.ZodSchema<VisServiceQueryRecord>;
