import {
  Button,
  type ButtonProps,
  HStack,
  Modal as M,
  type ModalProps as MProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useGlobalState } from '@ui/state/global';

type ButtonPropsWithLabel = ButtonProps & {
  label?: string | null;
};

export type ModalProps = {
  title?: string | null;
  children?: React.ReactNode;
  isOpen?: boolean;
  onClose?: (() => void) | null;
  buttons?: ButtonPropsWithLabel[] | null;
  selectionRequired?: boolean | null;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl';
  initialFocusRef?: MProps['initialFocusRef'];
};

export function Modal({
  isOpen = true,
  onClose,
  title,
  children,
  buttons,
  selectionRequired,
  size,
  initialFocusRef,
}: ModalProps) {
  const { setActiveModal } = useGlobalState();

  // Support for taking getting a callback when the modal is closed
  const handleClose = () => {
    onClose?.();
    setActiveModal(null);
  };
  return (
    <M
      size={size}
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={!selectionRequired}
      closeOnEsc={!selectionRequired}
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent>
        {title && <ModalHeader>{title}</ModalHeader>}

        {!selectionRequired && <ModalCloseButton title="Close" />}
        {children && <ModalBody>{children}</ModalBody>}

        <ModalFooter>
          <HStack>
            {buttons?.map((button, i) => (
              <Button
                key={`${button.label}-${i}`}
                {...{ borderRadius: '0px' }}
                {...button}
                {...(button.label ? { children: button.label } : {})}
              />
            ))}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </M>
  );
}
