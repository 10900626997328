export const inputStyles = {
  backgroundColor: 'white',
  borderColor: 'mw.lightGrey',
  borderRadius: 0,
  outlineOffset: 0,
  outline: '1px solid transparent',
  _hover: {
    borderColor: 'mw.blue',
  },
  _focus: {
    outlineColor: 'mw.blue',
  },
  _invalid: {
    borderColor: 'snap.red.600',
    backgroundColor: 'snap.red.600/20',

    _hover: {
      backgroundColor: 'snap.red.600/10',
    },

    _focus: {
      backgroundColor: 'snap.red.600/10',
      outlineColor: 'snap.red.600',
    },
  },
};
