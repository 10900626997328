import { VolumeCalculator } from '@ui/features/configurator/screens/PartConfiguration/Calculators/VolumeCalculator';
import type { ConfiguratorFieldType } from '../../types';

export type FieldCalculatorProps = {
  field: ConfiguratorFieldType;
};

export type FieldCalculatorComponentType =
  React.FC<FieldCalculatorProps> | null;

type FieldCalculatorComponents = {
  [key: string]: FieldCalculatorComponentType;
};

export const FIELD_CALCULATOR_COMPONENTS: FieldCalculatorComponents = {
  VolumeCalculator: VolumeCalculator,
};
