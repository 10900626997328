import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from '@ui/components/Button';
import { CalculatorIcon } from '@ui/components/Icons';
import { Heading } from '@ui/components/layout/Heading';
import type { CalculatedWeight } from '@ui/features/configurator/calculations/volume';
import { ConfiguratorField } from '@ui/features/configurator/components/inputs/ConfiguratorField';
import { inputStyles } from '@ui/features/configurator/components/inputs/styles';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { updateField } from '@ui/features/configurator/store';
import { useCalculateVolume } from '@ui/features/configurator/store/helpers/calculate-volume';
import type { FieldCalculatorProps } from '@ui/features/configurator/store/helpers/field-calculators';
import { isFieldDisabled } from '@ui/features/configurator/store/helpers/field-helpers';
import { useState } from 'react';

export const VolumeCalculator = ({ field }: FieldCalculatorProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showModal, setShowModal] = useState(isOpen);

  const handleCloseComplete = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
    onOpen();
  };

  return (
    <>
      <IconButton
        aria-label="Test"
        isDisabled={isFieldDisabled(field)}
        icon={<CalculatorIcon color="currentColor" fontSize="md" />}
        size="sm"
        variant="none"
        verticalAlign="inherit"
        color="mw.grey"
        _hover={{
          color: 'mw.darkBlue',
        }}
        onClick={handleOpen}
      />

      {showModal && (
        <CalculatorModal
          isOpen={isOpen}
          field={field}
          onClose={onClose}
          onCloseComplete={handleCloseComplete}
        />
      )}
    </>
  );
};

type CalculatorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCloseComplete: () => void;
} & FieldCalculatorProps;

const CalculatorModal = ({
  field,
  isOpen,
  onClose,
  onCloseComplete,
}: CalculatorModalProps) => {
  const { dispatch, blueprint, fields } = useConfiguratorStore();
  const {
    shouldUseMetric,
    fieldKeys: {
      useMetric,
      headDiameter,
      headHeight,
      shankDiameter,
      shankLength,
      shankTwoDiameter,
      shankTwoLength,
      shankThreeDiameter,
      shankThreeLength,
      materialDensity,
      customDensity,
    },
    volumeTotals,
    weightTotals,
  } = useCalculateVolume({ blueprint, fields });

  const handleAccept = () => {
    if (weightTotals?.weightInPounds) {
      dispatch(updateField(field.fieldKey, weightTotals.weightInPounds));
      onClose();
    }
  };

  const hasResults = Boolean(weightTotals?.weightInPounds);
  const resultsMatchCurrentFieldValue =
    hasResults && weightTotals?.weightInPounds === Number(field.value);
  const isUpdateButtonDisabled = !hasResults || resultsMatchCurrentFieldValue;

  const inputSizes = {
    minWidth: '200px',
    maxWidth: '200px',
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Calculate Weight</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={4} marginBottom={4}>
            <ConfiguratorField fieldKey={useMetric} />
          </Flex>

          <Flex gap={4} marginBottom={4}>
            <ConfiguratorField fieldKey={headDiameter} />
            <ConfiguratorField fieldKey={headHeight} />
          </Flex>

          <Flex gap={4} marginBottom={2}>
            <ConfiguratorField fieldKey={shankDiameter} />
            <ConfiguratorField fieldKey={shankLength} />
          </Flex>

          <Accordion allowToggle marginBottom={4}>
            <AccordionItem border="none">
              <>
                <AccordionButton
                  cursor="pointer"
                  fontWeight="bold"
                  px={0}
                  py={2}
                  _hover={{
                    bg: 'white',
                  }}
                >
                  <Flex>
                    <AccordionIcon mr={2} />
                    Additional Shank/Shoulder Dimensions
                  </Flex>
                </AccordionButton>
                <AccordionPanel px={0} py={0}>
                  <Flex gap={4} marginTop={2} marginBottom={4}>
                    <ConfiguratorField fieldKey={shankTwoDiameter} />
                    <ConfiguratorField fieldKey={shankTwoLength} />
                  </Flex>

                  <Flex gap={4} marginBottom={4}>
                    <ConfiguratorField fieldKey={shankThreeDiameter} />
                    <ConfiguratorField fieldKey={shankThreeLength} />
                  </Flex>

                  <Divider mt={4} />
                </AccordionPanel>
              </>
            </AccordionItem>
          </Accordion>

          <Flex gap={4} marginBottom={4}>
            <FormControl
              isDisabled
              display="flex"
              flexDirection="column"
              gap={1}
              {...inputSizes}
            >
              <FormLabel fontSize="sm" fontWeight="medium" margin={0}>
                Total Volume (in³)
              </FormLabel>
              <InputGroup>
                <Input
                  id="volumeInCubicInches"
                  type="number"
                  disabled
                  value={formatNum(volumeTotals?.volumeInCubicInches) ?? ''}
                  {...inputStyles}
                />
              </InputGroup>
            </FormControl>
          </Flex>

          <Divider my={4} />

          <Flex gap={4} marginBottom={4}>
            <ConfiguratorField fieldKey={materialDensity} />
            <ConfiguratorField fieldKey={customDensity} />
          </Flex>

          <CalculatorResults
            shouldUseMetric={shouldUseMetric}
            weightTotals={weightTotals}
          />
        </ModalBody>
        <ModalFooter display="flex" justifyContent="end" gap={2}>
          <Button onClick={handleAccept} isDisabled={isUpdateButtonDisabled}>
            Update "{field.name}"
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const CalculatorResults = ({
  shouldUseMetric,
  weightTotals,
}: {
  shouldUseMetric: boolean;
  weightTotals: CalculatedWeight | null;
}) => {
  return (
    <>
      <Heading fontSize="md" mt={6} mb={3}>
        Results
      </Heading>
      <TableContainer as={Card}>
        <Table variant="simple" size="md">
          <Thead>
            <Tr>
              <Th>Total</Th>
              {shouldUseMetric && <Th isNumeric>Metric</Th>}
              <Th isNumeric>English</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td fontSize="sm">Weight</Td>
              {shouldUseMetric && (
                <Td fontSize="sm" isNumeric>
                  {formatNum(weightTotals?.weightInGrams) ?? '--'} g
                </Td>
              )}
              <Td fontSize="sm" isNumeric>
                {formatNum(weightTotals?.weightInPounds) ?? '--'} lb
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

const formatNum = (value: number | undefined) => {
  if (typeof value === 'number') {
    return value.toFixed(5);
  }
  return null;
};
