import {
  Box,
  Icon as ChakraIcon,
  type IconProps as ChakraIconProps,
} from '@chakra-ui/react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Show } from '../Show';
import { IconBadge, type IconBadgeProps } from './IconBadge';

export type IconProps = Omit<ChakraIconProps, 'children'> & {
  icon: IconProp;
  badge?: IconBadgeProps & {
    show?: boolean;
  };
};

/**
 * Renders an FontAwesome icon with an optional badge.
 *
 * @see Docs https://chakra-ui.com/docs/components/icon
 * @see Docs https://docs.fontawesome.com/web/use-with/react/add-icons#add-individual-icons-explicitly
 */
export const Icon = React.forwardRef<HTMLDivElement, IconProps>(
  ({ badge, ...props }, ref) => {
    return (
      <Box position="relative" lineHeight={0} ref={ref}>
        <ChakraIcon as={FontAwesomeIcon} {...props} />
        <Show when={badge?.show}>
          <IconBadge {...badge} />
        </Show>
      </Box>
    );
  },
);
