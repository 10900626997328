import { Box, Text } from '@chakra-ui/react';
import { Show } from '@ui/components/Show';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { updateField } from '@ui/features/configurator/store';
import type { ConfiguratorSingleChoiceFieldType } from '@ui/features/configurator/types';
import { memo, useCallback } from 'react';
import {
  ConfiguratorBaseSingleChoiceInput,
  type SelectedItem,
} from './BaseSingleChoiceInput';
import type { ConfiguratorInputProps } from './types';

function SingleChoiceComponent({
  field,
  ...widths
}: ConfiguratorInputProps<ConfiguratorSingleChoiceFieldType>) {
  const { dispatch } = useConfiguratorStore();

  // @ts-expect-error `meta` missing from single choice input type
  const description = field.value?.meta?.description;

  if (!field.inputSource) {
    throw new Error(
      `single_choice inputs must have an associated input_source, but ${field.fieldKey} had none`,
    );
  }

  const handleSelectedItemChange = useCallback(
    async (item: SelectedItem | null) => {
      dispatch(updateField(field.fieldKey, item));
    },
    [dispatch, field.fieldKey],
  );

  return (
    <Box
      maxWidth={widths.maxWidth ?? '200px'}
      minWidth={widths.minWidth ?? '200px'}
    >
      <ConfiguratorBaseSingleChoiceInput
        field={field}
        onItemSelected={handleSelectedItemChange}
        {...widths}
      />

      <Show when={description}>
        <Text mt={2} fontSize="small">
          {description}
        </Text>
      </Show>
    </Box>
  );
}

/**
 * Searchable select for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorSingleChoiceInput = memo(SingleChoiceComponent);
