import type { UnknownRecord } from 'type-fest';
import { ValidationError } from '../errors';

export class PricingStrategyError extends ValidationError {
  name = 'PricingStrategyError';
  severity = 7500;
  constructor(
    public message: string,
    public context: UnknownRecord = {},
    public severityOverride?: number,
  ) {
    super();
    this.severity = severityOverride ?? this.severity;
  }
}
