type VolumeUOM = 'English' | 'Metric';
type VolumeFunc = (diameter: number, height: number) => number;
type VolumeParams = {
  diameter: number;
  height: number;
  volumeFunc: VolumeFunc;
  uom: VolumeUOM;
};

export type ColdHeadedFastenerVolumeCalcInput = {
  uom: VolumeUOM;
  headDiameter: number;
  headHeight: number;
  shankDiameter: number;
  shankLength: number;
  shankTwoDiameter?: number;
  shankTwoLength?: number;
  shankThreeDiameter?: number;
  shankThreeLength?: number;
};

export type CalculatedVolume = {
  volumeInCubicInches: number;
  volumeInCubicMillimeters: number;
};

export type CalculatedWeight = {
  weightInPounds: number;
  weightInGrams: number;
};

export const calculateFastenerVolume = ({
  uom = 'English',
  headDiameter,
  headHeight,
  shankDiameter,
  shankLength,
  shankTwoDiameter,
  shankTwoLength,
  shankThreeDiameter,
  shankThreeLength,
}: ColdHeadedFastenerVolumeCalcInput) => {
  const inputs = [
    {
      diameter: headDiameter,
      height: headHeight,
      volumeFunc: rightCylinderVolume,
      uom,
    },
    {
      diameter: shankDiameter,
      height: shankLength,
      volumeFunc: rightCylinderVolume,
      uom,
    },
  ];

  const additionalInputs = [];
  if (shankTwoDiameter && shankTwoLength) {
    additionalInputs.push({
      diameter: shankTwoDiameter,
      height: shankTwoLength,
      volumeFunc: rightCylinderVolume,
      uom,
    });
  }
  if (shankThreeDiameter && shankThreeLength) {
    additionalInputs.push({
      diameter: shankThreeDiameter,
      height: shankThreeLength,
      volumeFunc: rightCylinderVolume,
      uom,
    });
  }

  return calculateVolume(inputs.concat(additionalInputs));
};

export const calculateFastenerWeight = (
  density: number,
  volume: number,
): CalculatedWeight => {
  // expected density value is pounds per cubic inch
  const weightInPounds = mass(density, volume);
  const weightInGrams = weightInPounds * 453.59237;

  return {
    weightInPounds,
    weightInGrams,
  };
};

const calculateVolume = (inputs: VolumeParams[]): CalculatedVolume => {
  const volumeInCubicInches = sumVolumes(inputs);

  const volumeInCubicMillimeters =
    convertCubicInchesToCubicMillimeters(volumeInCubicInches);

  return {
    volumeInCubicInches,
    volumeInCubicMillimeters,
  };
};

const processDimensions = ({ diameter, height, uom }: VolumeParams) => {
  // if input in mm, convert to inches
  if (uom === 'Metric') {
    return {
      diameter: convertMillimetersToInches(diameter),
      height: convertMillimetersToInches(height),
    };
  }
  return {
    diameter,
    height,
  };
};

const sumVolumes = (params: VolumeParams[]) => {
  return params.reduce((acc, input) => {
    const { diameter, height } = processDimensions(input);
    return acc + input.volumeFunc(diameter, height);
  }, 0);
};

const mass = (density: number, volume: number) => {
  return density * volume;
};

const TOTAL_MILLIMETERS_PER_INCH = 25.4;

const convertMillimetersToInches = (millimeters: number) => {
  return millimeters / TOTAL_MILLIMETERS_PER_INCH;
};

const TOTAL_CUBIC_MILLIMETERS_PER_CUBIC_INCH = 16387.064;

const convertCubicInchesToCubicMillimeters = (cubicInches: number) => {
  return cubicInches * TOTAL_CUBIC_MILLIMETERS_PER_CUBIC_INCH;
};

export const rightCylinderVolume: VolumeFunc = (
  diameter: number,
  height: number,
) => {
  return Math.PI * (diameter / 2) ** 2 * height;
};
