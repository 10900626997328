import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
} from '@chakra-ui/react';
import { isAxiosError } from 'axios';

export type QueryErrorProps = {
  title?: string;
  error?: Error;
  onRetry?: () => void;
  isRetrying?: boolean;
};

export function QueryError({
  title,
  error,
  onRetry,
  isRetrying = false,
}: QueryErrorProps) {
  return (
    <Box padding={6} width="100%">
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>{title || 'Error loading!'}</AlertTitle>
        {error && (
          <AlertDescription>
            {isAxiosError(error)
              ? error.response?.data.message
              : 'Something went wrong...'}
          </AlertDescription>
        )}

        {onRetry && (
          <Button
            variant="ghost"
            onClick={onRetry}
            ml="auto"
            isLoading={isRetrying}
            loadingText="Retrying..."
          >
            Try again
          </Button>
        )}
      </Alert>
    </Box>
  );
}
