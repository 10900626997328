export type ValidationErrorSeverity = number;

export abstract class ValidationError extends Error {
  public abstract name: string;
  public abstract message: string;
  public abstract readonly severity: ValidationErrorSeverity;
  // Severity classes:
  // 0-4999: info
  // 5000-7999: warning
  // 8000-9999: error
}

/**
 * Taken from ts-essentials
 *
 * https://github.com/ts-essentials/ts-essentials/tree/master/lib/functions/unreachable-case-error
 */
export class UnreachableCaseError extends Error {
  /**
   * This error is thrown when a `never` type is reached in a switch
   * statement or similar. This provides both a compile-time error
   * and a runtime error.
   */
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`);
  }
}
