import type { SalesRepParams } from '@lib/requests/sales-rep';
import type { SalesRepWithEmployeeResponse } from '@lib/responses/sales-rep';
import type { API } from './api';

export class SalesRepService {
  constructor(private api: API) {}

  getSalesRepWithEmployee = (params: SalesRepParams) => {
    return this.api.get<SalesRepWithEmployeeResponse>(
      `/sales-rep/${params.salesRepId}`,
    );
  };
}
