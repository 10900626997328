import type {
  ConfiguratorAvailableOperationType,
  WritableConfiguratorStoreState,
} from '@ui/features/configurator/types';
import { getUntrackedObject } from 'react-tracked';
import { createBlueprintAndFieldsFromStep } from '../helpers/blueprint-helpers';
import type { ConfiguratorAction } from './types';

export type AddOperationAction = ConfiguratorAction<
  'addOperation',
  { operation: ConfiguratorAvailableOperationType }
>;

export type CreateOperationAction = ConfiguratorAction<
  'createOperation',
  { operation: ConfiguratorAvailableOperationType }
>;

export const addOperation = (
  operation: ConfiguratorAvailableOperationType,
): AddOperationAction => ({
  type: 'addOperation',
  payload: {
    operation,
  },
});

export const createOperation = (
  operation: ConfiguratorAvailableOperationType,
): CreateOperationAction => ({
  type: 'createOperation',
  payload: {
    operation,
  },
});

export function addOperationAction(
  state: WritableConfiguratorStoreState,
  trackedOperation: ConfiguratorAvailableOperationType,
) {
  const operation = getUntrackedObject(trackedOperation);

  if (!operation) {
    throw new Error('Operation could not be found');
  }

  if (operation.step.step_type !== 'operation') {
    throw new Error(`Expected operation step, got ${operation.step.step_type}`);
  }

  createBlueprintAndFieldsFromStep(
    operation.step,
    state.configuration,
    state.blueprint,
    state.fields,
  );

  if (!operation.step.reusable) {
    state.blueprint.availableOperations =
      state.blueprint.availableOperations.filter(
        (availableOperation) => availableOperation.id !== operation.id,
      );
  }
}

export function createOperationAction(
  state: WritableConfiguratorStoreState,
  operation: ConfiguratorAvailableOperationType,
) {
  if (operation.step.step_type !== 'operation') {
    throw new Error(`Expected operation step, got ${operation.step.step_type}`);
  }

  createBlueprintAndFieldsFromStep(
    operation.step,
    state.configuration,
    state.blueprint,
    state.fields,
    operation.id,
  );

  if (!operation.step.reusable) {
    state.blueprint.availableOperations =
      state.blueprint.availableOperations.filter(
        (availableOperation) => availableOperation.id !== operation.id,
      );
  }
}
