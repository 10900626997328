import type {
  ConfigurationFieldSchema,
  ConfigurationStepSchema,
} from '@ui/features/configurator/types';

// Step Keys
const STEP_KEYS = {
  computed_fields: 'computed_fields',
} as const;

export const COMPUTED_FIELDS_STEP_KEYS = STEP_KEYS;

// Field IDs
const FIELD_IDS = {
  cost: 'cost',
};

export const COMPUTED_FIELDS_FIELD_IDS = FIELD_IDS;

// Fields
const costField = {
  name: 'Cost',
  field_id: FIELD_IDS.cost,
  input_type: 'number',
  left_icon: 'dollar',
  validations: [
    {
      validate: 'required',
    },
  ],
  dependencies: [],
  dependency_mode: null,
} satisfies ConfigurationFieldSchema;

// Steps
export const defaultComputedFieldsStep = {
  id: 'D9196DD2-1913-4E2F-BCDD-C9D01E2D9A57',
  step_key: STEP_KEYS.computed_fields,
  name: 'Computed Fields',
  step_type: 'computed',
  fields: [costField],
} satisfies ConfigurationStepSchema;
