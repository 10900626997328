import { useAuth } from '@ui/state/auth';
import { useEffect } from 'react';
import { FullScreenLoader } from './FullScreenLoader';
import { QueryError } from './query';

const WithUser = ({ children }: { children?: React.ReactNode }) => {
  const { user, isError, getProfile } = useAuth();

  useEffect(() => {
    // if we know logged in but no auth.user -> fetch user profile
    if (!isError && !user) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getProfile is not memoized properly
  }, [user, isError]);

  if (!user) {
    return isError ? (
      <QueryError title="Error getting user information" />
    ) : (
      <FullScreenLoader loadingText="loading user data" />
    );
  }
  return <>{children}</>;
};

export default WithUser;
