import { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import type {
  ConfiguratorFieldState,
  ConfiguratorFunctionValidation,
} from '@ui/features/configurator/types';
import { getSingleChoiceFieldValue } from '../helpers/field-helpers';
import { validResult } from './result';
import type { ValidationResult } from './types';

export function validateRunTypeIsPcsPerHr(
  fields: ConfiguratorFieldState,
  fieldKey: string,
  _: ConfiguratorFunctionValidation,
): ValidationResult {
  const field = fields[fieldKey];
  const value = getSingleChoiceFieldValue<string>(field, '');

  // Bypass this validation if there's no value.
  // The user will already see the "required" validation error.
  if (!value) {
    return validResult;
  }

  if (value !== VisOpRunRateType.PCS_PER_HR) {
    return {
      valid: false,
      errors: [
        `Run Types other than ${VisOpRunRateType.PCS_PER_HR} are not supported`,
      ],
    };
  }

  return validResult;
}
