import type { ResolvedOpCostsInput } from '../../part-history';
import { type CostCalculations, aggCostCalcs, zeroCostCalcs } from '../core';
import { calcRequirementCost } from '../requirement';
import { calcOperationCosts } from './calculator';
import type { NestedOpCostCalcs, NestedReqCostCalcs } from './types';
import { type CalcOpCostsInputsRaw, calcOpCostsInputsRawSchema } from './zod';

export interface AggCostCalculations {
  agg: CostCalculations; //TODO: confusing naming (orig was only aggs)
  nested: NestedOpCostCalcs;
}

/** @returns null if missing/invalid cost inputs*/
export const calcCostAggs = (
  opCostsInput: ResolvedOpCostsInput[],
): AggCostCalculations | null => {
  // validate we have all required fields for all ops & reqs
  const calcInputs = opCostsInput.map((costInput) => {
    const updatedReqs = costInput.requirements.map((reqInput) => {
      return {
        ...reqInput,
        qty: reqInput.calcQtyFromOp,
        subOpsCosts: [], //TODO: might want to pass in op cost inputs instead of pre-calcd, or at least just schema validate for the inputs here
      };
    });

    return {
      ...costInput,
      qty: costInput.calcQty,
      runHrs: costInput.calcRunHrs,
      requirements: updatedReqs,
    };
  });

  const validResp = calcOpCostsInputsRawSchema.array().safeParse(calcInputs);

  if (!validResp.success) {
    console.error('failed to validate cost inputs', validResp.error.errors);
    // TODO: !! need some sort of injectable event emitter in calcs to send errors to UI
    return null;
  }
  const validCalcInputs = validResp.data;

  const makeOpCalcsReducer = (
    nestedOps: NestedOpCostCalcs,
    opInput: CalcOpCostsInputsRaw,
  ): NestedOpCostCalcs => {
    const { requirements, sequenceNum, ...restOpInput } = opInput;

    const reqCalcs = requirements.reduce((nestedReqs, reqInput) => {
      const { pieceNum, subOpsCosts, ...calcReqInput } = reqInput;
      // recursively calculate sub-ops (assemblies)
      const subOpsCalcs = subOpsCosts.reduce(
        makeOpCalcsReducer,
        {} as NestedOpCostCalcs,
      );
      const reqCalcs = calcRequirementCost({
        ...calcReqInput,
        subOpsCosts: Object.values(subOpsCalcs),
      });

      nestedReqs[pieceNum] = { ...reqCalcs, subOps: subOpsCalcs };
      return nestedReqs;
    }, {} as NestedReqCostCalcs);

    const calcOpInput = {
      ...restOpInput,
      requirements: Object.values(reqCalcs),
    };
    const opCalcs = calcOperationCosts(calcOpInput);

    // TODO: should have opLevel and reqLevel costs split in addition
    // similar to visual

    nestedOps[sequenceNum] = {
      ...opCalcs,
      requirements: reqCalcs,
    };
    return nestedOps;
  };

  const nestedCalcs = validCalcInputs.reduce(
    makeOpCalcsReducer,
    {} as NestedOpCostCalcs,
  );
  const calcsAgg = Object.values(nestedCalcs).reduce(
    aggCostCalcs,
    zeroCostCalcs(),
  ); // agg req costs already factored into op costs

  return {
    agg: calcsAgg,
    nested: nestedCalcs,
  };
};
