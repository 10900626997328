import type {
  Configuration,
  ConfiguratorConfiguration,
} from '@ui/features/configurator/types';

export function convertConfiguration(
  configuration: Configuration,
): ConfiguratorConfiguration {
  return {
    id: configuration.id,
    name: configuration.name,
    steps: Object.fromEntries(
      configuration.steps.map((step) => [step.id, step]),
    ),
  };
}
