import type { SiteWithConfigResponse, SitesResponse } from '@lib/responses';
import type { API } from './api';

export class SiteService {
  constructor(private api: API) {}

  getAllSites = () => {
    return this.api.get<SitesResponse>('/sites');
  };

  getWithConfig = (siteCode: string) => {
    return this.api.get<SiteWithConfigResponse>('/sites/with-config', {
      siteCode,
    });
  };
}
