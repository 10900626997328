import type {
  CostingConfigCreateRequest,
  CostingConfigUpdateRequest,
  PricingConfigCreateRequest,
  PricingConfigUpdateRequest,
  QuotingConfigCreateRequest,
  QuotingConfigUpdateRequest,
} from '@lib/requests';
import type {
  AllConfigsResponse,
  CostingConfigResponse,
  PricingConfigResponse,
  QuotingConfigResponse,
} from '@lib/responses';
import type { API } from '../api';

export class AdminConfigsService {
  constructor(private api: API) {}

  getAll = async (siteId: string) => {
    return this.api.get<AllConfigsResponse>(`/admin/configs/${siteId}`);
  };

  getQuotingConfigs = async (siteId: string) => {
    return this.api.get<QuotingConfigResponse[]>(
      `/admin/configs/${siteId}/quoting`,
    );
  };

  createQuotingConfig = async (
    siteId: string,
    data: QuotingConfigCreateRequest,
  ) => {
    return this.api.post<QuotingConfigResponse>(
      `/admin/configs/${siteId}/quoting`,
      data,
    );
  };

  updateQuotingConfig = async (
    siteId: string,
    id: string,
    data: QuotingConfigUpdateRequest,
  ) => {
    return this.api.put<QuotingConfigResponse>(
      `/admin/configs/${siteId}/quoting/${id}`,
      data,
    );
  };

  getPricingConfigs = async (siteId: string) => {
    return this.api.get<PricingConfigResponse[]>(
      `/admin/configs/${siteId}/pricing`,
    );
  };

  createPricingConfig = async (
    siteId: string,
    data: PricingConfigCreateRequest,
  ) => {
    return this.api.post<PricingConfigResponse>(
      `/admin/configs/${siteId}/pricing`,
      data,
    );
  };

  updatePricingConfig = async (
    siteId: string,
    id: string,
    data: PricingConfigUpdateRequest,
  ) => {
    return this.api.put<PricingConfigResponse>(
      `/admin/configs/${siteId}/pricing/${id}`,
      data,
    );
  };

  getCostingConfigs = async (siteId: string) => {
    return this.api.get<CostingConfigResponse[]>(
      `/admin/configs/${siteId}/costing`,
    );
  };

  createCostingConfig = async (
    siteId: string,
    data: CostingConfigCreateRequest,
  ) => {
    return this.api.post<CostingConfigResponse>(
      `/admin/configs/${siteId}/costing`,
      data,
    );
  };

  updateCostingConfig = async (
    siteId: string,
    id: string,
    data: CostingConfigUpdateRequest,
  ) => {
    return this.api.put<CostingConfigResponse>(
      `/admin/configs/${siteId}/costing/${id}`,
      data,
    );
  };
}
