import { UnreachableCaseError } from '@lib/validation';
import type { ConfiguratorFieldType } from '@ui/features/configurator/types';

/**
 * Formats a field value for display.
 *
 * Throws if the field is `inputType: "display"` as there is no suitable value
 * to format for display.
 */
export function getDisplayValue(field: ConfiguratorFieldType) {
  switch (field.inputType) {
    case 'boolean':
      return field.value ? 'True' : 'False';

    case 'calculated_number':
    case 'number':
    case 'text':
    case 'textarea':
      return field.value;

    case 'single_choice':
    case 'operation_type':
    case 'material':
    case 'service':
    case 'description':
      return field.value?.value;

    case 'display':
      throw new Error(
        'Cannot proxy display value of field with `inputType: "display"`',
      );
    default:
      throw new UnreachableCaseError(field);
  }
}
