/**
 * Returns the index of the previous item in the array.
 *
 * If the current index is 0, it returns the index of the last item in the array.
 */
export function decrementIndex(currentIndex: number, array: unknown[]) {
  if (currentIndex === 0) {
    return array.length - 1;
  }

  return currentIndex - 1;
}

/**
 * Returns the index of the next item in the array.
 *
 * If the current index is the last item in the array, it returns the index of the first item in the array.
 */

export function incrementIndex(currentIndex: number, array: unknown[]) {
  if (currentIndex === array.length - 1) {
    return 0;
  }

  return currentIndex + 1;
}

export function calculateOperationSequenceNumber(index: number) {
  return (index + 1) * 10;
}
