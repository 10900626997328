import { Checkbox, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { CircleInfoIcon } from '@ui/components/Icons';
import { ConfiguratorInputWrapper } from '@ui/features/configurator/components/inputs/InputWrapper';
import { usePreferredServiceVendors } from '@ui/features/configurator/hooks/use-preferred-service-vendors';
import { isFieldDisabled } from '@ui/features/configurator/store/helpers/field-helpers';
import type { ConfiguratorServiceFieldType } from '@ui/features/configurator/types';
import { memo } from 'react';
import { ConfiguratorBaseSingleChoiceInput } from './BaseSingleChoiceInput';
import type { ConfiguratorInputProps } from './types';
import { ConfiguratorNumberInput } from '@ui/features/configurator/components/inputs/NumberInput';

/**
 * A component that renders the Service, Use Standard Pricing, and Vendor fields.
 * It also orchestrates some shared behavior among them.
 *
 * @ref packages/ui/src/features/configurator/data/docs/2024-10-09-preferred-service-vendors.md
 */
function ServiceInputComponent({
  field,
  ...widths
}: ConfiguratorInputProps<ConfiguratorServiceFieldType>) {
  if (field.inputType !== 'service') {
    throw new Error('Invalid input type');
  }

  if (!field.inputSource) {
    throw new Error(
      `service inputs must have an associated input_source, but ${field.fieldKey} had none`,
    );
  }

  const {
    vendorField,
    unitOfMeasureField,
    baseChargeField,
    minimumChargeField,
    pricePerUnitOfMeasureField,
    standardServicePricingField,
    isPending,
    notice,
    onServiceChange,
    onUseStandardPricingChange,
    onVendorChange,
    onUnitOfMeasureChange,
    onPricePerUnitOfMeasureChange,
  } = usePreferredServiceVendors(field);

  const useStandardPricing = standardServicePricingField.value === true;

  return (
    <>
      <ConfiguratorBaseSingleChoiceInput
        field={field}
        onItemSelected={onServiceChange}
        isPending={isPending}
        isDisabled={isPending}
        {...widths}
      />

      <ConfiguratorInputWrapper
        field={{ ...standardServicePricingField, isAlwaysHidden: false }}
        {...widths}
        hideLabel
      >
        <Checkbox
          id={standardServicePricingField.fieldKey}
          isInvalid={standardServicePricingField.isInvalid}
          isChecked={standardServicePricingField.value ?? false}
          onChange={(e) => onUseStandardPricingChange(e.target.checked)}
          isDisabled={isFieldDisabled(standardServicePricingField) || isPending}
        >
          {standardServicePricingField.name}

          {standardServicePricingField.description && (
            <Tooltip
              label={standardServicePricingField.description}
              placement="end"
            >
              <IconButton
                size="xs"
                variant="none"
                verticalAlign="inherit"
                aria-label="Description"
                icon={<CircleInfoIcon color="mw.lightGrey" fontSize="md" />}
              />
            </Tooltip>
          )}
        </Checkbox>
      </ConfiguratorInputWrapper>

      {notice ? (
        <Text fontSize={12} color="mw.grey">
          {notice}
        </Text>
      ) : null}

      <ConfiguratorBaseSingleChoiceInput
        field={{ ...vendorField, isAlwaysHidden: false }}
        onItemSelected={onVendorChange}
        isPending={isPending}
        isDisabled={isPending}
        params={{
          serviceId: useStandardPricing ? field.value?.value : undefined,
        }}
        {...widths}
      />

      <ConfiguratorBaseSingleChoiceInput
        field={{ ...unitOfMeasureField, isAlwaysHidden: false }}
        onItemSelected={onUnitOfMeasureChange}
        isPending={isPending}
        isDisabled={isPending}
        {...widths}
      />

      <ConfiguratorNumberInput
        field={{ ...baseChargeField, isAlwaysHidden: false }}
        {...widths}
      />

      <ConfiguratorNumberInput
        field={{ ...minimumChargeField, isAlwaysHidden: false }}
        {...widths}
      />

      <ConfiguratorNumberInput
        field={{ ...pricePerUnitOfMeasureField, isAlwaysHidden: false }}
        onChange={onPricePerUnitOfMeasureChange}
        {...widths}
      />
    </>
  );
}

/**
 * Searchable select for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorServiceInput = memo(ServiceInputComponent);
