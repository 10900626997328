import { VisOperationTypeScrapYieldType } from '@lib/kysely/visual/tables/operation-type';
import { ScrapYieldType } from '@prisma/client';
import type {
  ConfiguratorBlueprintState,
  ConfiguratorBooleanFieldType,
  ConfiguratorCalculatedNumberFieldType,
  ConfiguratorDescriptionFieldType,
  ConfiguratorDescriptionValue,
  ConfiguratorFieldState,
  ConfiguratorFieldType,
  ConfiguratorMaterialFieldType,
  ConfiguratorNumberFieldType,
  ConfiguratorServiceFieldType,
  ConfiguratorSingleChoiceFieldType,
  ConfiguratorTextFieldType,
} from '@ui/features/configurator/types';
import { OPERATION_FIELD_IDS } from '../../data/constants/default-configuration';
import { createFieldKey } from './create-field-key';
import type { ChangeEvent } from 'react';
import is from '@sindresorhus/is';

export function isFieldDisabled(field: ConfiguratorFieldType) {
  return field.isDisabledFromDependency || field.isDisabledProgrammatically;
}

export function getBooleanFieldValue(
  field: ConfiguratorFieldType,
  defaultValue = false,
) {
  if (field.inputType !== 'boolean') {
    throw new Error(`Expected boolean field, got ${field.inputType}`);
  }

  return field.value ?? defaultValue;
}

export function getSingleChoiceFieldValue<T = string>(
  field: ConfiguratorFieldType,
  defaultValue: T,
) {
  if (field.inputType !== 'single_choice') {
    throw new Error(`Expected single choice field, got ${field.inputType}`);
  }

  return (field.value?.value as T) ?? defaultValue;
}

export function getSingleChoiceFieldLabel(
  field: ConfiguratorFieldType,
  defaultValue: string,
): string {
  if (field.inputType !== 'single_choice') {
    throw new Error(`Expected single choice field, got ${field.inputType}`);
  }

  return (field.value?.label as string) ?? defaultValue;
}

export function getSingleChoiceFieldMeta<T>(
  field: ConfiguratorFieldType,
  defaultValue: T,
): T {
  if (field.inputType !== 'single_choice') {
    throw new Error(`Expected single choice field, got ${field.inputType}`);
  }

  return (field.value?.meta as T) ?? defaultValue;
}

export function getNumberFieldValue(
  field: ConfiguratorFieldType,
  defaultValue = 0,
): number {
  if (field.inputType !== 'number') {
    throw new Error(`Expected number field, got ${field.inputType}`);
  }

  return field.value ?? defaultValue;
}

export function getTextFieldValue(
  field: ConfiguratorFieldType,
  defaultValue = '',
): string {
  if (field.inputType !== 'text') {
    throw new Error(`Expected text field, got ${field.inputType}`);
  }

  return field.value ?? defaultValue;
}

export function getTextareaFieldValue(
  field: ConfiguratorFieldType,
  defaultValue = '',
): string {
  if (field.inputType !== 'textarea') {
    throw new Error(`Expected textarea field, got ${field.inputType}`);
  }

  return field.value ?? defaultValue;
}

export function getDescriptionFieldValue(
  field: ConfiguratorFieldType,
  defaultValue = { value: '', override: false },
): NonNullable<ConfiguratorDescriptionValue> {
  if (field.inputType !== 'description') {
    throw new Error(`Expected description field, got ${field.inputType}`);
  }

  return field.value ?? defaultValue;
}

export function getMaterialFieldValue<T = string>(
  field: ConfiguratorFieldType,
  defaultValue: T,
) {
  if (field.inputType !== 'material') {
    throw new Error(`Expected material field, got ${field.inputType}`);
  }

  return (field.value?.value as T) ?? defaultValue;
}

export function getServiceFieldValue<T = string>(
  field: ConfiguratorFieldType,
  defaultValue: T,
) {
  if (field.inputType !== 'service') {
    throw new Error(`Invalid service field with key ${field.fieldKey}`);
  }

  return (field.value?.value as T) ?? defaultValue;
}

export function getOperationTypeFieldValue<T = string>(
  field: ConfiguratorFieldType,
  defaultValue: T,
) {
  if (field.inputType !== 'operation_type') {
    throw new Error(`Invalid operation_type field with key ${field.fieldKey}`);
  }

  return (field.value?.value as T) ?? defaultValue;
}

/**
 * Gets a field from the Configurator store given its `fieldKey` if that field exists.
 *
 * Use this when you know the exact field you're looking for.
 */
export function maybeGetField(
  fieldKey: string,
  fields: ConfiguratorFieldState,
): ConfiguratorFieldType | undefined {
  return fields[fieldKey];
}

/**
 * Gets a field from the Configurator store given its `fieldKey`.
 *
 * Throws an error if not found.
 *
 * Use this when you know the exact field you're looking for.
 */
export function getField(
  fieldKey: string,
  fields: ConfiguratorFieldState,
): ConfiguratorFieldType {
  const field = maybeGetField(fieldKey, fields);

  if (!field) {
    throw new Error(`Field ${getField} not found.`);
  }

  return field;
}

/**
 * Gets a field from the Configurator store given its `stepId` and `fieldId` if that field exists.
 *
 * Use this if you don't have the field key immediately available.
 */
export function maybeGetFieldByStepId(
  stepId: string,
  fieldId: string,
  fields: ConfiguratorFieldState,
): ConfiguratorFieldType | undefined {
  return maybeGetField(createFieldKey(stepId, fieldId), fields);
}

/**
 * Gets a field from the Configurator store given its `stepId` and `fieldId`.
 *
 * Throws an error if not found.
 *
 * Use this if you don't have the field key immediately available.
 */
export function getFieldByStepId(
  stepId: string,
  fieldId: string,
  fields: ConfiguratorFieldState,
): ConfiguratorFieldType {
  const field = maybeGetFieldByStepId(stepId, fieldId, fields);

  if (!field) {
    throw new Error(`Field ${createFieldKey(stepId, fieldId)} not found.`);
  }

  return field;
}

/**
 * Gets all fields for a given `stepKey` and `fieldId`.
 *
 * Use this if you know the step key, but not the step id or if you want all a field for all reusable operations.
 */
export function maybeGetAllFieldsByStepKey(
  stepKey: string,
  fieldId: string,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
): ConfiguratorFieldType[] {
  const stepIds = blueprint.stepKeyToIdMap[stepKey] ?? [];

  const collectedFields: ConfiguratorFieldType[] = [];

  for (const stepId of stepIds) {
    const field = maybeGetFieldByStepId(stepId, fieldId, fields);

    if (field) {
      collectedFields.push(field);
    }
  }

  return collectedFields;
}

/**
 * Gets all fields for a given `stepKey` and `fieldId`.
 *
 * Throws an error if none found.
 *
 * Use this if you know the step key, but not the step id or if you want all a field for all reusable operations.
 */
export function getAllFieldsByStepKey(
  stepKey: string,
  fieldId: string,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
): ConfiguratorFieldType[] {
  const collectedFields = maybeGetAllFieldsByStepKey(
    stepKey,
    fieldId,
    fields,
    blueprint,
  );

  if (collectedFields.length === 0) {
    throw new Error(`No fields found for ${stepKey}.${fieldId}`);
  }

  return collectedFields;
}

/**
 * Gets a field for a given `fieldId` from the only step with a given `stepKey`.
 *
 * Throws an error if:
 *  - The step is not found
 *  - More than one step is found
 *  - No fields are found for the step
 *
 * Use this if you know the step key, but not the step id and you want to ensure you only get have one field.
 */
export function getOnlyFieldByStepKey(
  stepKey: string,
  fieldId: string,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
): ConfiguratorFieldType {
  const step = blueprint.stepKeyToIdMap[stepKey];

  if (!step) {
    throw new Error(`No step found for ${stepKey}`);
  }

  if (step.length > 1) {
    throw new Error(`More than one step found for ${stepKey}`);
  }

  try {
    const stepId = step[0];

    return getFieldByStepId(stepId, fieldId, fields);
  } catch {
    throw new Error(`No field found for ${stepKey}.${fieldId}`);
  }
}

/**
 * Ensures that the given field is of type `ConfiguratorNumberFieldType`.
 */
export function ensureNumberField(
  field: ConfiguratorFieldType,
): ConfiguratorNumberFieldType {
  if (field.inputType !== 'number') {
    throw new Error(`Field ${field.fieldId} is not a number field`);
  }

  return field;
}

/**
 * Ensures that the given field is of type `ConfiguratorCalculatedNumberFieldType`.
 */
export function ensureCalculatedNumberField(
  field: ConfiguratorFieldType,
): ConfiguratorCalculatedNumberFieldType {
  if (field.inputType !== 'calculated_number') {
    throw new Error(`Field ${field.fieldId} is not a calculated number field`);
  }

  return field;
}

/**
 * Ensures that the given field is of type `ConfiguratorSingleChoiceFieldType`.
 */
export function ensureSingleChoiceField<Meta>(field: ConfiguratorFieldType) {
  if (field.inputType !== 'single_choice') {
    throw new Error(`Invalid single choice field with key ${field.fieldKey}`);
  }

  return field as ConfiguratorSingleChoiceFieldType<Meta>;
}

/**
 * Ensures that the given field is of type `ConfiguratorBooleanFieldType`.
 */
export function ensureBooleanField(
  field: ConfiguratorFieldType,
): ConfiguratorBooleanFieldType {
  if (field.inputType !== 'boolean') {
    throw new Error(`Field ${field.fieldId} is not a boolean field`);
  }

  return field;
}

/**
 * Ensures that the given field is of type `ConfiguratorTextFieldType`.
 */
export function ensureTextField(
  field: ConfiguratorFieldType,
): ConfiguratorTextFieldType {
  if (field.inputType !== 'text') {
    throw new Error(`Field ${field.fieldId} is not a text field`);
  }

  return field;
}

/**
 * Ensures that the given field is of type `ConfiguratorDescriptionFieldType`.
 */
export function ensureDescriptionField(
  field: ConfiguratorFieldType,
): ConfiguratorDescriptionFieldType {
  if (field.inputType !== 'description') {
    throw new Error(`Field ${field.fieldId} is not a description field`);
  }

  return field;
}

/**
 * Ensures that the given field is of type `ConfiguratorMaterialFieldType`.
 */
export function ensureMaterialField(
  field: ConfiguratorFieldType,
): ConfiguratorMaterialFieldType {
  if (field.inputType !== 'material') {
    throw new Error(`Field ${field.fieldId} is not a material field`);
  }

  return field;
}

/**
 * Ensures that the given field is of type `ConfiguratorServiceFieldType`.
 */
export function ensureServiceField(
  field: ConfiguratorFieldType,
): ConfiguratorServiceFieldType {
  if (field.inputType !== 'service') {
    throw new Error(`Field ${field.fieldId} is not a service field`);
  }

  return field;
}

/**
 * Gets the value of the scrap yield type field, returning `ScrapYieldType.SCRAP`
 * if the value is not set or is not in the enum.
 *
 * Scrap from Visual uses "S" or "Y" while SNAP uses "SCRAP" or "YIELD".
 * This maps our values to the appropriate enum.
 */
export function getScrapYieldTypeValue(field: ConfiguratorFieldType) {
  const scrapEnumMap: Record<string, ScrapYieldType> = {
    [VisOperationTypeScrapYieldType.SCRAP]: ScrapYieldType.SCRAP,
    [VisOperationTypeScrapYieldType.YIELD]: ScrapYieldType.YIELD,
    N: ScrapYieldType.SCRAP,
  };

  if (
    field.inputType !== 'single_choice' ||
    field.fieldId !== OPERATION_FIELD_IDS.scrap_yield_type
  ) {
    throw new Error(`Field ${field.fieldId} is not a scrap yield type field`);
  }

  if (!field.value) {
    return ScrapYieldType.SCRAP;
  }

  return scrapEnumMap[field.value.value];
}

export function getNumberFieldValueFromEvent(e: ChangeEvent<HTMLInputElement>) {
  const value = e.target.value;

  if (value === '') {
    return null;
  }

  if (!is.numericString(value)) {
    throw new Error('Must be a number');
  }

  return Number(value);
}
