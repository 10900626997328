import {
  materialFieldDefinition,
  washerMaterialFieldDefinition,
} from './material';
import { operationTypeFieldDefinition } from './operation_type';
import { resourceFieldDefinition } from './resource';
import { serviceFieldDefinition } from './service';
import { vendorFieldDefinition } from './vendor';

/**
 * Maps field IDs to validation, serialization, and select options.
 */
export const fieldDefinitionMap = {
  material: materialFieldDefinition,
  washer_material: washerMaterialFieldDefinition,
  operation_type: operationTypeFieldDefinition,
  resource: resourceFieldDefinition,
  service: serviceFieldDefinition,
  vendor: vendorFieldDefinition,
} as const;

export function getFieldDefinition(sourceKey: string) {
  if (!(sourceKey in fieldDefinitionMap)) {
    throw new Error(
      `No FieldDefinition found for ${sourceKey}. Expected one of ${Object.keys(
        fieldDefinitionMap,
      ).join(', ')}.`,
    );
  }

  return fieldDefinitionMap[sourceKey as keyof typeof fieldDefinitionMap];
}
