import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import type { CustomIconProps } from './types';

/**
 * Renders a dollar sign icon with an optional badge.
 *
 * @see Docs https://fontawesome.com/icons/dollar-sign?f=classic&s=solid
 */
export const DollarSignIcon: React.FC<CustomIconProps> = (props) => {
  return <Icon icon={faDollarSign} {...props} />;
};
