import { useContext } from 'react';
import { createTrackedSelector } from 'react-tracked';
import { useStore } from 'zustand';
import { ConfiguratorStoreContext } from '../store/context';
import type { ConfiguratorStore } from '../types/store';

function useConfiguratorStoreContext<T>(
  selector: (state: ConfiguratorStore) => T,
) {
  const store = useContext(ConfiguratorStoreContext);
  if (!store) {
    throw new Error('Missing StoreProvider');
  }
  return useStore(store, selector);
}

/**
 * Hook for accessing configurator store
 *
 * @docs https://react-tracked.js.org/
 */
export const useConfiguratorStore = createTrackedSelector(
  useConfiguratorStoreContext,
);
