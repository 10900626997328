import { calcQuoteLine } from '../quote-line';
import { maybeParseNum, round } from '../util';
import { RecalcLineData } from './types';

function calculateAdjustment(
  initial: number,
  percent: number,
  amount: number,
  quantity: number,
) {
  return initial * (percent / 100) + amount / quantity;
}

/**
 * Calculates unit price from markup adjustments.
 */
export function calculateUnitPriceFromMarkup(lineData: RecalcLineData): number {
  let input = lineData;
  const quantity = maybeParseNum(input.quantity, 0);

  if (!quantity) {
    throw new Error('Unable to suggest a new price. No quantity provided.');
  }

  if (
    !lineData.unitMaterialCost ||
    !lineData.unitLaborCost ||
    !lineData.unitServiceCost ||
    !lineData.unitBurdenCost
  ) {
    const {
      unitCosts: { materialCost, laborCost, serviceCost, burdenCost },
    } = calcQuoteLine(input);
    input = {
      ...lineData,
      unitMaterialCost: materialCost,
      unitLaborCost: laborCost,
      unitServiceCost: serviceCost,
      unitBurdenCost: burdenCost,
    };
  }

  // @NOTE(NT): We store both purchase material and fabricated material
  // for markup, but only presently use one of them (purchase material).
  // When we better support of subassemblies, we will need to revive
  // fabricated material calculations.

  const unitMaterialCost = maybeParseNum(input.unitMaterialCost, 0);
  const purchaseMaterialPercent = maybeParseNum(
    input.purchaseMaterialPercent,
    0,
  );
  const purchaseMaterialAmount = maybeParseNum(input.purchaseMaterialAmount, 0);

  const purchaseMaterialAdjustment = calculateAdjustment(
    unitMaterialCost,
    purchaseMaterialPercent,
    purchaseMaterialAmount,
    quantity,
  );

  const newUnitMaterialCost = unitMaterialCost + purchaseMaterialAdjustment;

  // The rest of these are just one set of percent and amount
  const unitLaborCost = maybeParseNum(input.unitLaborCost, 0);
  const laborCostPercent = maybeParseNum(input.laborCostPercent, 0);
  const laborCostAmount = maybeParseNum(input.laborCostAmount, 0);

  const newUnitLaborCost =
    unitLaborCost +
    calculateAdjustment(
      unitLaborCost,
      laborCostPercent,
      laborCostAmount,
      quantity,
    );

  const unitServiceCost = maybeParseNum(input.unitServiceCost, 0);
  const serviceCostPercent = maybeParseNum(input.serviceCostPercent, 0);
  const serviceCostAmount = maybeParseNum(input.serviceCostAmount, 0);

  const newUnitServiceCost =
    unitServiceCost +
    calculateAdjustment(
      unitServiceCost,
      serviceCostPercent,
      serviceCostAmount,
      quantity,
    );

  const unitBurdenCost = maybeParseNum(input.unitBurdenCost, 0);
  const burdenCostPercent = maybeParseNum(input.burdenCostPercent, 0);
  const burdenCostAmount = maybeParseNum(input.burdenCostAmount, 0);

  const newUnitBurdenCost =
    unitBurdenCost +
    calculateAdjustment(
      unitBurdenCost,
      burdenCostPercent,
      burdenCostAmount,
      quantity,
    );

  return round(
    newUnitMaterialCost +
      newUnitLaborCost +
      newUnitServiceCost +
      newUnitBurdenCost,
    {
      min: 2,
      small: 3,
    },
  );
}
