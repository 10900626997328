import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { faBuilding, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@ui/config/theme';
import { useAuth } from '@ui/state/auth';
import { scrollbarCSS } from '@ui/util/css';
import { menuProps } from '@ui/util/menuProps';

type SiteSelectorProps = {
  isCollapsed: boolean;
};

export const SiteSelector = ({ isCollapsed }: SiteSelectorProps) => {
  const auth = useAuth();

  const { user, currentSite, setCurrentSite } = auth;

  if (!user) {
    return null;
  }

  if (user.sites.length === 0) {
    return null;
  }

  const sites = user.sites;

  return (
    <Menu>
      <MenuButton
        width="100%"
        height="50px"
        opacity={0.72}
        _hover={{ opacity: 1 }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize={18}
        >
          <Tooltip label="Sites" placement="right" isDisabled={false}>
            <FontAwesomeIcon
              style={{ margin: 0 }}
              icon={faBuilding}
              width={36}
            />
          </Tooltip>

          {!isCollapsed && currentSite && (
            <Text
              fontWeight={'semibold'}
              fontFamily={'navigationItem'}
              marginLeft={1}
            >
              {currentSite.code}
            </Text>
          )}
        </Box>
      </MenuButton>
      <MenuList
        borderRadius={0}
        color={'mw.lightGrey'}
        backgroundColor={'mw.darkGrey'}
        p={'1.5rem'}
        border="none"
      >
        <MenuGroup title="Current Site">
          <Box
            maxHeight="225px"
            overflow="auto"
            css={scrollbarCSS({
              background: theme.colors.mw.darkGrey,
              thumb: theme.colors.mw.lightGrey,
            })}
          >
            {sites.map((site) => {
              return (
                <MenuItem
                  key={site.id}
                  value={site.id}
                  {...menuProps.item}
                  onClick={() => setCurrentSite(site.id)}
                >
                  <Flex>
                    <Box width="24px">
                      {site.id === currentSite?.id && (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                    </Box>

                    <Box width="45px" fontWeight="bold" marginLeft={2}>
                      {site.code}
                    </Box>

                    <Box
                      width="225px"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {site.name}
                    </Box>
                  </Flex>
                </MenuItem>
              );
            })}
          </Box>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
