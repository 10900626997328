import type {
  ConfiguratorFieldState,
  ConfiguratorNumericalityValidation,
} from '@ui/features/configurator/types';
import { getNumberFieldValue } from '../helpers/field-helpers';
import { validResult } from './result';
import type { ValidationResult } from './types';

/**
 * Validate the value of a number input
 */
export function validateNumericality(
  fields: ConfiguratorFieldState,
  fieldKey: string,
  { greater_than, less_than }: ConfiguratorNumericalityValidation,
): ValidationResult {
  const field = fields[fieldKey];
  const value = getNumberFieldValue(field);

  if (field.value == null) {
    return validResult;
  }

  if (typeof greater_than === 'number' && value <= greater_than) {
    return {
      valid: false,
      errors: [`${field.name} must be greater than ${greater_than}`],
    };
  }

  if (typeof less_than === 'number' && value >= less_than) {
    return {
      valid: false,
      errors: [`${field.name} must be less than ${less_than}`],
    };
  }

  return validResult;
}
