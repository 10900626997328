import { maybeParseObjVals } from '@lib/calculations';
import type { PricingConfigResponse } from '@lib/responses';
import { PricingStrategyError } from '@lib/validation/calcs/pricing';
import type { PricingConfig, PricingStrategy } from '@prisma/client';
import type { UnknownRecord } from 'type-fest';
import type { PricingCalculatorData } from '../calculator';
import { calculateUnitPriceFromMarkup } from '../markup';
import type {
  CustomerTiersData,
  PriceParts,
  PricingCalculatorStrategy,
  RecalcLineData,
} from '../types';

export abstract class AbstractPricingStrategy
  implements PricingCalculatorStrategy
{
  abstract readonly name: PricingStrategy;

  protected readonly site;
  protected readonly partHistoryCalcs;
  protected readonly partHistory;
  protected readonly quoteData;
  protected readonly partId;

  constructor(
    protected pricingConfig: PricingConfig | PricingConfigResponse,
    protected data: PricingCalculatorData,
  ) {
    const { site, partHistoryCalcs, partHistory, quoteData } = data;
    this.site = site;
    this.partHistoryCalcs = partHistoryCalcs;
    this.partHistory = partHistory;
    this.partId = partHistory?.partId;
    this.quoteData = quoteData;
  }

  abstract calcInitPrice(qty: number): number | null;
  abstract recalcPrice(lineData: RecalcLineData): PriceParts;
  /** Optionally override in concrete implementations if special behavior is
   * needed [Default calls main recalcPrice] */
  public recalcPriceOnQtyChange(lineData: RecalcLineData): PriceParts {
    return this.recalcPrice(lineData);
  }
  /** Optionally override in concrete implementations if special behavior is
   * needed [Default calls main recalcPrice] */
  public recalcPriceOnCostChange(lineData: RecalcLineData): PriceParts {
    return this.recalcPrice(lineData);
  }
  /** Optionally override in concrete implementations if special behavior is
   * needed [Default calls main recalcPrice] */
  public recalcPriceOnOptionChange(lineData: RecalcLineData): PriceParts {
    return this.recalcPrice(lineData);
  }
  /** Optionally override in concrete implementations if special behavior is
   * needed [Default passes through input prices *as is*] */
  public recalcPriceOnCustomerTierChange(
    lineData: RecalcLineData,
    _tiers: CustomerTiersData,
  ): PriceParts {
    return this.maybeParsePriceParts(lineData);
  }

  public recalcPriceOnMarkupChange(lineData: RecalcLineData): PriceParts {
    const unitPrice = calculateUnitPriceFromMarkup(lineData);
    const baseUnitPrice = this.maybeParsePriceParts(lineData).baseUnitPrice;

    return { unitPrice, baseUnitPrice };
  }

  /** Helper to parse price parts as numbers or null
   *
   * TODO: remove "detailed" split from ql calcs and just use qlCalcs.pricing instead of this
   * like `const { unitPrice, baseUnitPrice } = calcQuoteLine(lineData).pricing`;
   */
  protected maybeParsePriceParts({
    unitPrice,
    baseUnitPrice,
  }: RecalcLineData): PriceParts {
    return maybeParseObjVals(
      {
        unitPrice,
        baseUnitPrice,
      },
      { defaultVal: null },
    );
  }

  /** Helper to set unit and base unit prices to the same*/
  protected makeEqualPriceParts(newPrice: number | null = null): PriceParts {
    return {
      unitPrice: newPrice,
      baseUnitPrice: newPrice,
    };
  }
  /** Helper to attach context to a pricing strategy error. Can pipe from
   * concrete overrides like
   *```
      return super.throwWithContext(
         message,
         {
            ...concreteAddCtx,
            concreteBaseCtxField: this.concreteField
         },
         severityOverride,
      )
    ``` */
  public throwWithContext(
    message: string,
    addCtx: UnknownRecord = {},
    severityOverride?: number,
  ): never {
    throw new PricingStrategyError(
      message,
      {
        strategy: this.name,
        site: this.site.code,
        partId: this.partId,
        pricingConfig: this.pricingConfig,
        ...addCtx,
      },
      severityOverride,
    );
  }
}
