import {
  type AuthenticationResult,
  type Configuration,
  type EventMessage,
  EventType,
  PublicClientApplication,
  type RedirectRequest,
} from '@azure/msal-browser';
import { EnvConfig } from './env';

const CLIENT_ID = EnvConfig.getKey('AD_CLIENT_ID');
const AUTHORITY = EnvConfig.getKey('AD_AUTHORITY_URL');
const SCOPES = EnvConfig.getKey('AD_SCOPES').split(',');

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: AUTHORITY,
    redirectUri: '/auth/redirect',
    postLogoutRedirectUri: '/logout/redirect',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // local storage allows us to share cache across tabs
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: SCOPES,
};

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});
