import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import type { CustomIconProps } from './types';

/**
 * Renders a hashtag icon with an optional badge.
 *
 * @see Docs https://fontawesome.com/icons/hashtag?f=classic&s=solid
 */
export const HashtagIcon: React.FC<CustomIconProps> = (props) => {
  return <Icon icon={faHashtag} {...props} />;
};
