import type {
  Generated,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import type { VisOpRunRateType } from '../types/operation';
import type { VisWorkOrderStatus, VisWorkOrderType } from './work-order';

export enum VisOpScrapYieldType {
  SCRAP = 'S',
  YIELD = 'Y',
}

export interface VisOperationTable {
  accum_downtime: string | null;
  act_atl_bur_cost: Generated<number>;
  act_atl_lab_cost: Generated<number>;
  act_atl_ser_cost: Generated<number>;
  act_run_hrs: Generated<number>;
  act_setup_hrs: Generated<number>;
  act_ttl_bur_cost: Generated<number>;
  act_ttl_lab_cost: Generated<number>;
  act_ttl_mat_cost: Generated<number>;
  act_ttl_ser_cost: Generated<number>;
  allocated_qty: Generated<number>;
  apply_calendar: string | null;
  begin_traceability: Generated<string>;
  bid_rate_category_id: string | null;
  bur_per_hr_run: Generated<number>;
  bur_per_hr_setup: Generated<number>;
  bur_per_operation: Generated<number>;
  bur_per_unit_run: Generated<number>;
  bur_percent_run: Generated<number>;
  bur_percent_setup: Generated<number>;
  calc_end_qty: number | null;
  calc_start_qty: number | null;
  capacity_usage_max: number | null;
  capacity_usage_min: number | null;
  close_date: Date | null;
  comb_disp_sequence: Generated<number>;
  completed_qty: Generated<number>;
  could_finish_date: Date | null;
  deviated_qty: Generated<number>;
  dispatch_sequence: Generated<number>;
  dispatched_qty: Generated<number>;
  drawing_file: string | null;
  drawing_id: string | null;
  drawing_rev_no: string | null;
  est_atl_bur_cost: Generated<number>;
  est_atl_lab_cost: Generated<number>;
  est_atl_ser_cost: Generated<number>;
  est_ttl_bur_cost: Generated<number>;
  est_ttl_lab_cost: Generated<number>;
  est_ttl_mat_cost: Generated<number>;
  est_ttl_ser_cost: Generated<number>;
  fixed_scrap_units: Generated<number>;
  fulfilled_qty: Generated<number>;
  isdeterminant: Generated<string>;
  last_disp_date: Date | null;
  last_recv_date: Date | null;
  least_min_move_qty: number | null;
  load_size_qty: number | null;
  max_downtime: number | null;
  max_gap_prev_op: number | null;
  max_qty_complete: Generated<number | null>;
  milestone_id: string | null;
  milestone_seq_no: number | null;
  milestone_sub_id: string | null;
  min_segment_size: number | null;
  minimum_move_qty: number | null;
  move_hrs: number | null;
  num_mem_to_sched: number | null;
  operation_type: string | null;
  overlap_setup: Generated<string>;
  override_qtys: Generated<string>;
  percent_compl: Generated<string | null>;
  post_milestone: string | null;
  prd_insp_plan_id: string | null;
  pred_seq_no: number | null;
  pred_sub_id: string | null;
  proj_milestone_op: string | null;
  protect_cost: Generated<string>;
  qty_compl_by_hrs: Generated<string | null>;
  rem_atl_bur_cost: Generated<number>;
  rem_atl_lab_cost: Generated<number>;
  rem_atl_ser_cost: Generated<number>;
  rem_ttl_bur_cost: Generated<number>;
  rem_ttl_lab_cost: Generated<number>;
  rem_ttl_mat_cost: Generated<number>;
  rem_ttl_ser_cost: Generated<number>;
  resource_id: string;
  rowid: GeneratedAlways<number>;
  run: number | null;
  run_cost_per_hr: Generated<number>;
  run_cost_per_unit: Generated<number>;
  run_hrs: number | null;
  run_inspect_req: string | null;
  run_qty_per_cycle: number | null;
  run_type: VisOpRunRateType;
  sched_capacity_usage: number | null;
  sched_finish_date: Date | null;
  sched_start_date: Date | null;
  schedule_type: number | null;
  scrap_yield_pct: number | null;
  scrap_yield_type: VisOpScrapYieldType;
  sequence_no: number;
  service_base_chg: Generated<number>;
  service_begin_date: Date | null;
  service_buffer: number | null;
  service_id: string | null;
  service_min_chg: Generated<number>;
  service_part_id: string | null;
  setup_completed: Generated<string>;
  setup_cost_per_hr: Generated<number>;
  setup_hrs: number | null;
  setup_inspect_req: string | null;
  site_id: string;
  spc_qty: number | null;
  split_adjustment: Generated<number>;
  status: Generated<VisWorkOrderStatus>;
  status_eff_date: Generated<Date>;
  test_id: string | null;
  transit_days: number | null;
  udf_layout_id: string | null;
  user_1: string | null;
  user_10: string | null;
  user_2: string | null;
  user_3: string | null;
  user_4: string | null;
  user_5: string | null;
  user_6: string | null;
  user_7: string | null;
  user_8: string | null;
  user_9: string | null;
  vendor_id: string | null;
  vendor_service_id: string | null;
  warehouse_id: string | null;
  wbs_code: string | null;
  wbs_duration: number | null;
  wbs_end_date: Date | null;
  wbs_start_date: Date | null;
  workorder_base_id: string;
  workorder_lot_id: string;
  workorder_split_id: string;
  workorder_sub_id: string;
  workorder_type: VisWorkOrderType;
}

export type VisOperation = Selectable<VisOperationTable>;
export type VisOperationInsert = Insertable<VisOperationTable>;
export type VisOperationUpdate = Updateable<VisOperationTable>;
