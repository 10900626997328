import { Box } from '@chakra-ui/react';
import type React from 'react';
import type { ReactNode } from 'react';

type PageWrapperProps = {
  children?: ReactNode;
};

const PageWrapper: React.FC<PageWrapperProps> = (props) => (
  <Box width="100%" height="100vh" overflow="auto" {...props}>
    {props.children}
  </Box>
);

export default PageWrapper;
