import type { WritableConfiguratorStoreState } from '@ui/features/configurator/types';
import type { ConfiguratorAction } from './types';

export type ToggleFieldEditableAction = ConfiguratorAction<
  'toggleFieldEditable',
  { fieldKey: string; isEditable: boolean }
>;

export const toggleFieldEditable = (
  fieldKey: string,
  isEditable: boolean,
): ToggleFieldEditableAction => ({
  type: 'toggleFieldEditable',
  payload: {
    fieldKey,
    isEditable,
  },
});

/**
 * Updates a field marking it as programmatically disabled
 */
export function toggleFieldEditableAction(
  state: WritableConfiguratorStoreState,
  fieldKey: string,
  isEditable: boolean,
) {
  state.fields[fieldKey].isDisabledProgrammatically = !isEditable;
}
