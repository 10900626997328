import type { DataType, Property } from 'csstype';

export function scrollbarCSS({
  background,
  thumb,
}: {
  background: DataType.Color;
  thumb: DataType.Color;
}) {
  return {
    scrollbarWidth: 'thin' as Property.ScrollbarWidth,
    scrollbarColor: `${thumb} ${background}`,
    '&::-webkit-scrollbar': {
      background,
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: thumb,
      borderRadius: '3px',
    },
  };
}
