import type {
  PartEngineeringResponse,
  SplitEngineeringsResponse,
} from '@lib/responses/part-engineering';
import type { API } from './api';

export class PartEngineeringService {
  constructor(private api: API) {}

  mostRecent = (siteId: string, partId: string) => {
    return this.api.get<PartEngineeringResponse>(
      `/part-engineering/most-recent/${siteId}/${partId}`,
    );
  };

  // using HTTP PUT since this technically updates data
  //  on the server, but the data is sourced from Visual
  //  rather than sent from the client
  syncFromVisual = async (partId: string) => {
    return this.api.put<SplitEngineeringsResponse>(
      `/part/history/${encodeURIComponent(partId)}/engineering/sync`,
      {},
    );
  };
}
