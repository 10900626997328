import type { ConfiguratorStoreState } from '@ui/features/configurator/types';

import {
  FIELD_IDS,
  STEP_KEYS,
} from '@ui/features/configurator/data/fixtures/cold-headed-fastener';

import {
  getBooleanFieldValue,
  getNumberFieldValue,
  getOnlyFieldByStepKey,
  getSingleChoiceFieldValue,
} from './field-helpers';

import {
  type CalculatedVolume,
  type CalculatedWeight,
  calculateFastenerVolume,
  calculateFastenerWeight,
} from '@ui/features/configurator/calculations/volume';
import { DENSITY_OTHER_OPTION } from '../../data';

function getVolumeField(
  id: keyof typeof FIELD_IDS,
  { blueprint, fields }: Pick<ConfiguratorStoreState, 'blueprint' | 'fields'>,
) {
  return getOnlyFieldByStepKey(
    STEP_KEYS.volume,
    FIELD_IDS[id],
    fields,
    blueprint,
  );
}

export function useVolumeCalculationsFields({
  blueprint,
  fields,
}: Pick<ConfiguratorStoreState, 'blueprint' | 'fields'>) {
  const useMetricField = getVolumeField('volume_use_metric', {
    fields,
    blueprint,
  });
  const headDiameterField = getVolumeField('volume_head_diameter', {
    fields,
    blueprint,
  });
  const headHeightField = getVolumeField('volume_head_height', {
    fields,
    blueprint,
  });
  const shankDiameterField = getVolumeField('volume_shank_diameter', {
    fields,
    blueprint,
  });
  const shankLengthField = getVolumeField('volume_shank_length', {
    fields,
    blueprint,
  });
  const shankTwoDiameterField = getVolumeField('volume_shank_two_diameter', {
    fields,
    blueprint,
  });
  const shankTwoLengthField = getVolumeField('volume_shank_two_length', {
    fields,
    blueprint,
  });
  const shankThreeDiameterField = getVolumeField(
    'volume_shank_three_diameter',
    {
      fields,
      blueprint,
    },
  );
  const shankThreeLengthField = getVolumeField('volume_shank_three_length', {
    fields,
    blueprint,
  });
  const materialDensityField = getVolumeField('volume_density', {
    fields,
    blueprint,
  });
  const customDensityField = getVolumeField('volume_custom_density', {
    fields,
    blueprint,
  });

  return {
    useMetricField,
    headDiameterField,
    headHeightField,
    shankDiameterField,
    shankLengthField,
    shankTwoDiameterField,
    shankTwoLengthField,
    shankThreeDiameterField,
    shankThreeLengthField,
    materialDensityField,
    customDensityField,
  };
}

export function useCalculateVolume({
  blueprint,
  fields,
}: Pick<ConfiguratorStoreState, 'blueprint' | 'fields'>) {
  const {
    useMetricField,
    headDiameterField,
    headHeightField,
    shankDiameterField,
    shankLengthField,
    shankTwoDiameterField,
    shankTwoLengthField,
    shankThreeDiameterField,
    shankThreeLengthField,
    materialDensityField,
    customDensityField,
  } = useVolumeCalculationsFields({ blueprint, fields });

  const shouldUseMetric = getBooleanFieldValue(useMetricField);
  const customDensity = getNumberFieldValue(customDensityField);
  const materialDensity = getSingleChoiceFieldValue(materialDensityField, null);

  let density: number | null = null;
  if (
    materialDensity === DENSITY_OTHER_OPTION.value &&
    typeof customDensity === 'number'
  ) {
    density = customDensity;
  } else if (materialDensity) {
    density = Number.parseFloat(materialDensity);
  }

  const headDiameter = getNumberFieldValue(headDiameterField);
  const headHeight = getNumberFieldValue(headHeightField);
  const shankDiameter = getNumberFieldValue(shankDiameterField);
  const shankLength = getNumberFieldValue(shankLengthField);
  const shankTwoDiameter = getNumberFieldValue(shankTwoDiameterField);
  const shankTwoLength = getNumberFieldValue(shankTwoLengthField);
  const shankThreeDiameter = getNumberFieldValue(shankThreeDiameterField);
  const shankThreeLength = getNumberFieldValue(shankThreeLengthField);

  const dimensionFieldsFilled = [
    headDiameter,
    headHeight,
    shankDiameter,
    shankLength,
  ].every((n) => n && n > 0);

  const additionalShankFieldsBothEmptyOrValid = [
    [shankTwoDiameter, shankTwoLength],
    [shankThreeDiameter, shankThreeLength],
  ].every(([diameter, length]) => {
    return (!diameter && !length) || (diameter > 0 && length > 0);
  });

  const values = {
    shouldUseMetric,
    fieldKeys: {
      useMetric: useMetricField.fieldKey,
      headDiameter: headDiameterField.fieldKey,
      headHeight: headHeightField.fieldKey,
      shankDiameter: shankDiameterField.fieldKey,
      shankLength: shankLengthField.fieldKey,
      shankTwoDiameter: shankTwoDiameterField.fieldKey,
      shankTwoLength: shankTwoLengthField.fieldKey,
      shankThreeDiameter: shankThreeDiameterField.fieldKey,
      shankThreeLength: shankThreeLengthField.fieldKey,
      materialDensity: materialDensityField.fieldKey,
      customDensity: customDensityField.fieldKey,
    },
    volumeTotals: null as CalculatedVolume | null,
    weightTotals: null as CalculatedWeight | null,
  };

  if (dimensionFieldsFilled && additionalShankFieldsBothEmptyOrValid) {
    const volume = calculateFastenerVolume({
      uom: shouldUseMetric ? 'Metric' : 'English',
      headDiameter,
      headHeight,
      shankDiameter,
      shankLength,
      shankTwoDiameter,
      shankTwoLength,
      shankThreeDiameter,
      shankThreeLength,
    });
    values.volumeTotals = volume;

    if (density) {
      values.weightTotals = calculateFastenerWeight(
        volume.volumeInCubicInches,
        density,
      );
    }
  }

  return values;
}
