import { Checkbox, Textarea } from '@chakra-ui/react';
import { updateField } from '@ui/features/configurator/store';
import { memo } from 'react';
import { useConfiguratorStore } from '../../hooks/use-configurator-store';
import type { ConfiguratorDescriptionFieldType } from '../../types';
import { ConfiguratorInputWrapper } from './InputWrapper';
import { inputStyles } from './styles';
import type { ConfiguratorInputProps } from './types';

function DescriptionInputComponent({
  field,
  ...widths
}: ConfiguratorInputProps<ConfiguratorDescriptionFieldType>) {
  const { dispatch } = useConfiguratorStore();

  const { value, override } = field.value ?? { value: '', override: false };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateField(field.fieldKey, { value: e.target.value, override }));
  };

  const toggleOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateField(field.fieldKey, { value, override: e.target.checked }),
    );
  };

  return (
    <ConfiguratorInputWrapper field={field} {...widths} maxWidth="420px">
      <Textarea
        placeholder={field.placeholder}
        onChange={handleChange}
        value={value}
        disabled={!override}
        {...inputStyles}
        minHeight="7rem"
        _disabled={{
          bg: 'mw.panelGrey',
          color: '#666',
        }}
      />

      <Checkbox
        isChecked={override}
        onChange={toggleOverride}
        isRequired={false}
        name="description-override"
      >
        Override
      </Checkbox>
    </ConfiguratorInputWrapper>
  );
}

/**
 * Description input for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorDescriptionInput = memo(DescriptionInputComponent);
