import type { PermissionsReqInput } from '@lib/requests/permissions';
import { definedEntries } from '@lib/util/objects';
import type { UserWithRoles } from '@prisma-types';
import { ResourceEnum, type ResourcePermission } from '@prisma/client';
import is from '@sindresorhus/is';

import type { UserWithSettingsResponse } from '@lib/responses/user';

export enum PermActions {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}
export type PermissionMap = {
  [key in ResourceEnum]?: Set<PermActions>;
};

/**
 * Return a true or false response to whether a user has the permission(s) required
 *
 * @param roles
 * @param requiredPermissions
 * @returns true|false
 */
export const hasPermissions = (
  requiredPermissions: PermissionsReqInput,
  user: UserWithRoles | UserWithSettingsResponse,
): boolean => {
  const userPermissions: ResourcePermission[] = [...user.permissions].flat();

  if (!userPermissions.length) {
    return false;
  }

  // map bool flags to actions
  const userPermissionMap: PermissionMap = userPermissions.reduce(
    (acc, resourcePerm) => {
      const { resource, read, create, update, delete: del } = resourcePerm;
      const actions: PermActions[] = [];
      if (read) {
        actions.push(PermActions.READ);
      }
      if (create) {
        actions.push(PermActions.CREATE);
      }
      if (update) {
        actions.push(PermActions.UPDATE);
      }
      if (del) {
        actions.push(PermActions.DELETE);
      }

      acc[resource] = new Set(actions);
      return acc;
    },
    {} as PermissionMap,
  );

  // Special case for Super Admin
  const hasAnySuperAdminPermissions =
    userPermissionMap.SUPERADMIN && userPermissionMap.SUPERADMIN?.size > 0;
  if (hasAnySuperAdminPermissions) {
    return true;
  }

  const isUserPermitted = definedEntries(requiredPermissions).every(
    ([resource, actions]) => {
      const actionsSet = is.array(actions) ? new Set(actions) : actions;

      for (const action of actionsSet) {
        if (!userPermissionMap[resource]?.has(action)) {
          return false;
        }
      }
      return true;
    },
  );

  return isUserPermitted;
};
