import type { VisOperationTypeScrapYieldType } from '@lib/kysely/visual/tables/operation-type';
import type { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import type { PaginationParams } from '@lib/models';
import type { SelectOptionWithMetadata } from '@lib/responses';
import { configuratorOperationTypeSchema } from '@lib/validation';
import {
  createFieldKey,
  findStaticOption,
  updateField,
} from '@ui/features/configurator/store';
import type {
  ConfiguratorDynamicFieldDefinition,
  ConfiguratorFieldState,
  ConfiguratorOperationTypeFieldType,
  ConfiguratorStoreAction,
} from '@ui/features/configurator/types';
import { configuratorService } from '@ui/services';
import type { SelectedItem } from '../../components/inputs/BaseSingleChoiceInput';
import { SCRAP_YIELD_NONE_OPTION } from '../constants/input-sources';
import type { ConfiguratorResourceOptionMeta } from './resource';

export type ConfiguratorOperationTypeOptionMeta = {
  id: string;
  description: string | null;
  resourceId: string | null;
  setupHrs: number | null;
  setupCostPerHr: number | null;
  run: number | null;
  runType: VisOpRunRateType | null;
  runCostPerHr: number | null;
  runCostPerUnit: number | null;
  burPerHrRun: number | null;
  burPerHrSetup: number | null;
  burPerOperation: number | null;
  burPerUnitRun: number | null;
  burPercentRun: number | null;
  burPercentSetup: number | null;
  scrapYieldType: VisOperationTypeScrapYieldType | null;
  scrapYieldPct: number | null;
  fixedScrapUnits: number | null;
};

export const operationTypeFieldDefinition: ConfiguratorDynamicFieldDefinition<ConfiguratorOperationTypeOptionMeta> =
  {
    endpoints: {
      list: '/configurator/operation-types',
    },

    mapOption: (data) => {
      const operationType = configuratorOperationTypeSchema.parse(data);

      return {
        label: operationType.id,
        value: operationType.id,
        meta: {
          id: operationType.id,
          description: operationType.description,
          resourceId: operationType.resource_id,
          setupHrs: operationType.setup_hrs,
          setupCostPerHr: operationType.setup_cost_per_hr,
          run: operationType.run,
          runType: operationType.run_type,
          runCostPerHr: operationType.run_cost_per_hr,
          runCostPerUnit: operationType.run_cost_per_unit,
          burPerHrRun: operationType.bur_per_hr_run,
          burPerHrSetup: operationType.bur_per_hr_setup,
          burPerOperation: operationType.bur_per_operation,
          burPerUnitRun: operationType.bur_per_unit_run,
          burPercentRun: operationType.bur_percent_run,
          burPercentSetup: operationType.bur_percent_setup,
          scrapYieldType: operationType.scrap_yield_type,
          scrapYieldPct: operationType.scrap_yield_pct,
          fixedScrapUnits: operationType.fixed_scrap_units,
        },
      };
    },

    mapOptions: (operationTypes) => {
      return operationTypes.map(operationTypeFieldDefinition.mapOption);
    },

    fetchOne: () => {
      throw new Error('Not implemented');
    },

    search: ({
      query,
      pagination,
    }: {
      query: string;
      pagination: PaginationParams;
    }) => {
      return configuratorService.getFieldOptions({
        fieldDefinition: operationTypeFieldDefinition,
        query,
        pagination,
      });
    },
  };

/**
 * Builds a list of field updates based on a change in the selected Operation
 * Type.
 *
 * When an Operation Type changes, populates `resource`, `run`, `run_type`,
 * `scrap_yield_type`, and `scrap_yield_percentage` based on the metadata
 * attached to the selected Operation Type.
 */
export function buildOperationTypeFieldUpdates(
  fields: ConfiguratorFieldState,
  field: ConfiguratorOperationTypeFieldType,
  item: SelectedItem<ConfiguratorOperationTypeOptionMeta> | null,
  resourceOption: SelectOptionWithMetadata<
    string,
    ConfiguratorResourceOptionMeta
  > | null,
): ConfiguratorStoreAction[] {
  /**
   * All of these fields are expected to exist within the same Operation as the
   * Operation Type field that triggered this update, so we derive their field
   * keys using `field.stepKey`.
   */
  return [
    updateField(createFieldKey(field.stepId, 'resource'), resourceOption),
    updateField(createFieldKey(field.stepId, 'run'), item?.meta?.run ?? null),
    updateField(
      createFieldKey(field.stepId, 'run_type'),
      item?.meta?.runType
        ? findStaticOption(
            fields[createFieldKey(field.stepId, 'run_type')],
            fields,
            item.meta.runType,
          )
        : null,
    ),
    updateField(
      createFieldKey(field.stepId, 'scrap_yield_type'),
      findStaticOption(
        fields[createFieldKey(field.stepId, 'scrap_yield_type')],
        fields,
        item?.meta?.scrapYieldType ?? SCRAP_YIELD_NONE_OPTION.value,
      ),
    ),
    updateField(
      createFieldKey(field.stepId, 'scrap_yield_percentage'),
      item?.meta?.scrapYieldPct ? String(item.meta.scrapYieldPct) : null,
    ),
    updateField(
      createFieldKey(field.stepId, 'setup_hours'),
      item?.meta?.setupHrs ? item.meta.setupHrs : null,
    ),
    updateField(
      createFieldKey(field.stepId, 'setup_cost_per_hour'),
      resourceOption?.meta?.setupCostPerHr
        ? resourceOption.meta.setupCostPerHr
        : null,
    ),
    updateField(
      createFieldKey(field.stepId, 'setup_burden_per_hour'),
      resourceOption?.meta?.burdenPerHrSetup
        ? resourceOption.meta.burdenPerHrSetup
        : null,
    ),
    updateField(
      createFieldKey(field.stepId, 'run_cost_per_hour'),
      resourceOption?.meta?.runCostPerHr
        ? resourceOption.meta.runCostPerHr
        : null,
    ),
    updateField(
      createFieldKey(field.stepId, 'run_burden_per_hour'),
      resourceOption?.meta?.burdenPerHrRun
        ? resourceOption.meta.burdenPerHrRun
        : null,
    ),
  ];
}

export function getOperationTypeField(
  fields: ConfiguratorFieldState,
  fieldKey: string,
): ConfiguratorOperationTypeFieldType {
  const field = fields[fieldKey];

  if (field.inputType !== 'operation_type') {
    throw new Error(`Invalid operation type field with key ${fieldKey}`);
  }

  return field as ConfiguratorOperationTypeFieldType;
}
