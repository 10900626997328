import { Icon } from '@chakra-ui/react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@ui/config/theme';

export interface IconBadgeProps {
  color?: 'green' | 'yellow' | 'red';
}

/**
 * Renders a small circle icon intended to be attached to an icon.
 */
export const IconBadge: React.FC<IconBadgeProps> = ({ color = 'green' }) => {
  return (
    <Icon
      as={FontAwesomeIcon}
      icon={faCircle}
      position="absolute"
      top="-1"
      right="-1"
      color={theme.colors.mw[color]}
      w="2"
      h="2"
    />
  );
};
