import { calcQuoteLine, maybeParseNum } from '@lib/calculations';
import { calcPrice } from '@lib/calculations/pricing/helpers';
import { PricingStrategyError } from '@lib/validation/calcs/pricing';
import { PricingStrategy } from '@prisma/client';
import is from '@sindresorhus/is';
import type { PriceParts, RecalcLineData } from '../types';
import type { PricingCalculatorStrategy } from '../types';
import { AbstractPricingStrategy } from './abstract';

export class TargetContributionStrategy
  extends AbstractPricingStrategy
  implements PricingCalculatorStrategy
{
  public readonly name = PricingStrategy.TARGET_CONTR_MARGIN;

  calcInitPrice = (): number | null => {
    const unitCosts =
      this.partHistoryCalcs?.suggestions.resolvedCostCalcs?.unit;
    if (!unitCosts) {
      console.warn('Unable to suggest price: missing costs');
      return null;
    }
    const target = maybeParseNum(this.pricingConfig.targetContrMarginPct);
    if (!target) {
      throw new PricingStrategyError(
        'Unable to suggest price: missing target contribution margin',
      );
    }

    return calcPrice(unitCosts).fromContributionMarginPercent(target);
  };

  recalcPrice(lineData: RecalcLineData): PriceParts {
    // Assume current margin is the desired target and fallback to config'd target
    const target =
      lineData.contributionMarginPercent ??
      maybeParseNum(this.pricingConfig.targetContrMarginPct);

    if (is.undefined(target)) {
      this.throwWithContext(
        'Unable to recalculate price: missing target contribution margin',
      );
    }
    if (is.undefined(lineData.operationCosts)) {
      this.throwWithContext(
        'Unable to recalculate price from target contributiion margin: missing operation costs',
      );
    }
    const { pricing } = calcQuoteLine(lineData);

    return this.makeEqualPriceParts(
      pricing.fromContributionMarginPercent(target),
    );
  }
}
