import type { QueryClient } from '@tanstack/react-query';

export class DataSourceError extends Error {
  queryKey: unknown[];

  constructor(message: string, queryKey: unknown[]) {
    super(message);
    this.queryKey = queryKey;
  }

  async resetQuery(queryClient: QueryClient) {
    await queryClient.resetQueries({
      queryKey: this.queryKey,
    });
  }
}
