import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import type { CustomIconProps } from './types';

/**
 * Renders a Circle Info icon with an optional badge.
 *
 * @see Docs https://fontawesome.com/icons/circle-info?f=classic&s=solid
 */
export const CircleInfoIcon: React.FC<CustomIconProps> = (props) => {
  return <Icon icon={faCircleInfo} {...props} />;
};
