import { UnreachableCaseError } from '@lib/validation/errors';
import type {
  ConfiguratorFieldState,
  ConfiguratorFunctionValidation,
  ConfiguratorRequiredValidation,
} from '@ui/features/configurator/types';

import {
  type ValidationResult,
  buildValidResult,
  validateFunction,
  validateRequired,
} from '../validations';

export function validateField(
  fields: ConfiguratorFieldState,
  fieldKey: string,
) {
  const field = fields[fieldKey];

  const finalResult = buildValidResult();

  field.validations.reduce((acc, validation) => {
    let result: ValidationResult;

    switch (validation.validate) {
      case 'required':
        result = validateRequired(
          fields,
          fieldKey,
          validation as ConfiguratorRequiredValidation,
        );
        break;

      case 'function':
        result = validateFunction(
          fields,
          fieldKey,
          validation as ConfiguratorFunctionValidation,
        );
        break;

      default:
        throw new UnreachableCaseError(validation);
    }

    if (!result.valid) {
      acc.valid = false;

      if (result.errors.length > 0) {
        acc.errors.push(...result.errors);
      }
    }

    return acc;
  }, finalResult);

  field.isInvalid = !finalResult.valid;
  field.errors = finalResult.errors;
}
