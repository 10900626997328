import type { RoleWithPermissions } from '@lib/prisma/prisma-types/role';
import type { API } from '../api';

export class AdminRoleService {
  constructor(private api: API) {}

  getAll = () => {
    return this.api.get<RoleWithPermissions[]>('/admin/roles');
  };
}
