export const menuProps = {
  item: {
    color: 'mw.lightGrey',
    backgroundColor: 'transparent',
    p: '12px',
    fontSize: '.875rem',
    _hover: {
      backgroundColor: 'rgba(0,18,35,0.32)',
      textColor: 'mw.white',
    },
  },
};
