import type { VisShopResourceSiteQueryRecord } from '@lib/kysely/visual/queries';
import { z } from 'zod';

export const configuratorResourceSchema = z.object({
  resource_id: z.string().transform((s) => s.toUpperCase()),
  setup_cost_per_hr: z.number(),
  run_cost_per_hr: z.number(),
  run_cost_per_unit: z.number(),
  bur_per_hr_run: z.number(),
  bur_per_hr_setup: z.number(),
  bur_per_operation: z.number(),
  bur_per_unit_run: z.number(),
  bur_percent_run: z.number(),
  bur_percent_setup: z.number(),
  rowid: z.number(),
  resource_type: z.string(),
}) satisfies Zod.ZodSchema<VisShopResourceSiteQueryRecord>;
