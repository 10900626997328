import type { Generated, Selectable } from 'kysely';

export enum VisOperationTypeScrapYieldType {
  SCRAP = 'S',
  YIELD = 'Y',
}

export const scrapYieldTypeLabelMapping = {
  S: 'Scrap',
  Y: 'Yield',
} as const satisfies Record<VisOperationTypeScrapYieldType, string>;

export interface VisOperationTypeTable {
  begin_traceability: string | null;
  bur_per_hr_run: number | null;
  bur_per_hr_setup: number | null;
  bur_per_operation: number | null;
  bur_per_unit_run: number | null;
  bur_percent_run: number | null;
  bur_percent_setup: number | null;
  calc_end_qty: number | null;
  calc_start_qty: number | null;
  capacity_usage_max: number | null;
  capacity_usage_min: number | null;
  description: string | null;
  fixed_scrap_units: number | null;
  id: string;
  load_size_qty: number | null;
  min_segment_size: number | null;
  minimum_move_qty: number | null;
  move_hrs: number | null;
  override_qtys: string | null;
  resource_id: string | null;
  rowid: Generated<number>;
  run: number | null;
  run_cost_per_hr: number | null;
  run_cost_per_unit: number | null;
  run_type: string | null;
  schedule_type: number | null;
  scrap_yield_pct: number | null;
  scrap_yield_type: string | null;
  service_base_chg: number | null;
  service_id: string | null;
  service_min_chg: number | null;
  service_part_id: string | null;
  setup_cost_per_hr: number | null;
  setup_hrs: number | null;
  site_id: string;
  test_id: string | null;
  transit_days: number | null;
  vendor_id: string | null;
  vendor_service_id: string | null;
}

export type VisOperationType = Selectable<VisOperationTypeTable>;
