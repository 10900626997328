import type {
  ConfigurationDefaultStepSchema,
  ConfigurationFieldSchema,
  ConfigurationOperationStepSchema,
  ConfigurationStepSchema,
  ConfiguratorBlueprintState,
  ConfiguratorConfiguration,
  ConfiguratorFieldState,
  ConfiguratorOperationBlueprint,
} from '@ui/features/configurator/types';
import { createFieldsFromStep } from './create-field';
import { createFieldKey, createStepId } from './create-field-key';

export const blueprintStepMap = {
  prerequisite: 'prerequisites',
  volume_calculations: 'volumeCalculations',
  operation: 'operations',
  additional_costs: 'additionalCosts',
  computed: 'computed',
} as const;

export function createInitialBlueprintFromStep(
  step: ConfigurationStepSchema,
  configuration: ConfiguratorConfiguration,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
) {
  if (step.step_type === 'operation') {
    // Add the step to the list of available operations if:
    // - the step is reusable, regardless of its requirement
    // - the step is not reusable and not required (so that a user can select it once)
    if (step.reusable || !step.required) {
      blueprint.availableOperations.push({
        id: step.id,
        name: step.name,
        step: {
          ...step,
          required: false,
        },
      });
    }

    if (step.required) {
      createBlueprintAndFieldsFromStep(
        step,
        configuration,
        blueprint,
        fields,
        step.id,
      );
    }
  } else {
    createBlueprintAndFieldsFromStep(
      step,
      configuration,
      blueprint,
      fields,
      step.id,
    );
  }
}

export function createBlueprintAndFieldsFromStep(
  step: ConfigurationStepSchema,
  configuration: ConfiguratorConfiguration,
  blueprint: ConfiguratorBlueprintState,
  fields: ConfiguratorFieldState,
  id?: string,
) {
  const stepId = id ?? createStepId();

  addStepToStepMap(stepId, step, blueprint);
  createFieldsFromStep(stepId, step, configuration, fields, blueprint);

  if (step.step_type === 'operation') {
    blueprint.operations.push(createOperationBlueprintFromStep(step, stepId));
  } else {
    blueprint[blueprintStepMap[step.step_type]].push(
      createDefaultBlueprintFromStep(step, stepId),
    );
  }
}

function addStepToStepMap(
  stepId: string,
  step: ConfigurationStepSchema,
  blueprint: ConfiguratorBlueprintState,
) {
  if (blueprint.stepKeyToIdMap[step.step_key]) {
    blueprint.stepKeyToIdMap[step.step_key].push(stepId);
  } else {
    blueprint.stepKeyToIdMap[step.step_key] = [stepId];
  }
}

export function createOperationBlueprintFromStep(
  step: ConfigurationOperationStepSchema,
  stepId: string,
): ConfiguratorOperationBlueprint {
  const { operationFields, summaryFields, noteField } = splitOperationFields(
    step.fields,
    stepId,
  );

  return {
    stepId,
    stepType: step.step_type,
    name: step.name,
    operationType: step.operation_type,
    required: step.required,
    reusable: step.reusable,
    description: step.description,
    fieldKeys: operationFields,
    summaryFields,
    noteField,
  };
}

function splitOperationFields(
  fields: ConfigurationFieldSchema[],
  stepId: string,
): { operationFields: string[]; summaryFields: string[]; noteField: string } {
  const operationFields: string[] = [];
  const summaryFields: string[] = [];
  let noteField: string | undefined;

  for (const field of fields) {
    if (field.field_level === 'summary') {
      summaryFields.push(createFieldKey(stepId, field.field_id));
      // exclude notes fields from normal UI flow
    } else if (field.field_level === 'note') {
      if (noteField) {
        throw new Error('Multiple note fields found for operation');
      }

      noteField = createFieldKey(stepId, field.field_id);
    } else {
      operationFields.push(createFieldKey(stepId, field.field_id));
    }
  }

  if (!noteField) {
    throw new Error('No note field found for operation');
  }

  return { operationFields, summaryFields, noteField };
}

export function createDefaultBlueprintFromStep(
  step: ConfigurationDefaultStepSchema,
  stepId: string,
) {
  return {
    stepId,
    stepType: step.step_type,
    name: step.name,
    description: step.description,
    fieldKeys: step.fields.map((field) =>
      createFieldKey(stepId, field.field_id),
    ),
  };
}
