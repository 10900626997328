import type { MsalAuthenticationResult } from '@azure/msal-react';
import { Text } from '@chakra-ui/react';

export function AuthErrorMessage({ error }: MsalAuthenticationResult) {
  return (
    <Text variant="h6">
      An Auth Error Occurred: {error ? error.errorCode : 'unknown error'}
    </Text>
  );
}
