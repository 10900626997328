import { ConfigEntities, ERPDB } from '@prisma/client';
import { z } from 'zod';
import { nullableInt } from '../base';

export const adminSiteUpdateSchema = z.object({
  erpDB: z.nativeEnum(ERPDB),
  useConfigurator: z.boolean(),
  siteConfig: z.object({
    configEntityPriorities: z.array(z.nativeEnum(ConfigEntities)),
    historicWorkOrderMaxMonthsOld: nullableInt,
    quotingConfigId: z
      .string()
      .min(1, { message: 'Quoting Config is required' })
      .uuid(),
  }),
});

export type AdminSiteUpdateDefaultFormValues = z.input<
  typeof adminSiteUpdateSchema
>;

export type AdminSiteUpdateFormData = z.output<typeof adminSiteUpdateSchema>;
