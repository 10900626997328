// Note: Not explicitly defined in DB & could drift over time
export enum VisOpRunRateType {
  PCS_PER_HR = 'PCS/HR',
  HRS_PER_PC = 'HRS/PC',
  MIN_PER_PC = 'MIN/PC',
  DAYS_PER_PC = 'DAYS/PC',
  HRS_PER_LOAD = 'HRS/LOAD',
  DAYS_PER_LOAD = 'DAYS/LOAD',
  PCS_PER_MIN = 'PCS/MIN',
  PCS_PER_DAY = 'PCS/DAY',
  LOADS_PER_HOUR = 'LOADS/HR',
  LOADS_PER_DAY = 'LOADS/DAY',
}

export enum VisOpScrapYieldType {
  SCRAP = 'S',
  YIELD = 'Y',
}
