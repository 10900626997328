import { UnreachableCaseError } from '@lib/validation';
import { getOnlyFieldByStepKey } from '@ui/features/configurator/store/helpers/field-helpers';
import type {
  ConfigurationDependencyModeType,
  ConfigurationDependencyType,
  ConfigurationFieldSchema,
  ConfigurationStepSchema,
  ConfiguratorBlueprintState,
  ConfiguratorConfiguration,
  ConfiguratorDependentOnType,
  ConfiguratorFieldState,
  ConfiguratorFieldType,
} from '@ui/features/configurator/types';
import { createFieldKey } from './create-field-key';
import { FIELD_CALCULATOR_COMPONENTS } from './field-calculators';
import { checkForRequiredFieldValidity } from './resolve-dependencies';

export function createFieldsFromStep(
  stepId: string,
  step: ConfigurationStepSchema,
  configuration: ConfiguratorConfiguration,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
) {
  for (const field of step.fields) {
    createField(stepId, step, field, configuration, fields, blueprint);
  }
}

function createField(
  stepId: string,
  step: ConfigurationStepSchema,
  field: ConfigurationFieldSchema,
  configuration: ConfiguratorConfiguration,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
) {
  const fieldKey = createFieldKey(stepId, field.field_id);

  // Base case: If the field already exists, return early.
  // This can be called recursively if a required field was not created
  // before a dependent field.
  if (fields[fieldKey]) {
    return;
  }

  const dependencies = createDependencies(
    stepId,
    step,
    field,
    configuration,
    fields,
    blueprint,
  );

  const isRequired = field.validations.some(
    (validation) => validation.validate === 'required',
  );

  const { isDisabledFromDependency, isHidden } = determineDependencyState(
    field.dependency_mode,
    dependencies,
    fields,
  );

  const initialValue = getInitialFieldValue(field);

  fields[fieldKey] = {
    name: field.name,
    stepId,
    fieldKey,
    fieldId: field.field_id,
    value: initialValue,
    defaultValue: field.default_value,
    isRequired,
    isTouched: false,
    isDisabledFromDependency,
    isDisabledProgrammatically: false,
    isHidden,
    isAlwaysHidden: field.hide_field ?? false,
    isInvalid: false,
    inputType: field.input_type,
    inputSource: field.input_source,
    validations: field.validations,
    errors: [],
    dependentOn: dependencies,
    requiredBy: [],
    leftIcon: field.left_icon,
    rightIcon: field.right_icon,
    description: field.description,
    placeholder: field.placeholder,
    dependencyMode: field.dependency_mode,
    clearOnNonInteractive: field.clear_on_noninteractive,
    calculatorComponent: determineCalculatorComponent(field.calculator),
    // TODO: Configurator - The discriminated union on ConfiguratorFieldType is causing some type issues
    // TypeScript can't infer the appropriate type from the input_type.
    // we need to add some better type guards when we add this record.
  } as ConfiguratorFieldType;
}

/**
 * The schema's `default_value` (which is assumed to be a serialized value) may
 * need to be transformed based on the `input_type` to an appropriate value
 * before being set initially.
 */
function getInitialFieldValue(field: ConfigurationFieldSchema) {
  if (field.input_type === 'boolean') {
    return field.default_value === 'true';
  }

  return field.default_value ?? null;
}

function determineCalculatorComponent(calculator: string | undefined) {
  if (calculator) {
    return FIELD_CALCULATOR_COMPONENTS[calculator];
  }
  return null;
}

function determineDependencyState(
  dependencyMode: ConfigurationDependencyModeType,
  dependencies: ConfiguratorDependentOnType[],
  fields: ConfiguratorFieldState,
) {
  const isInteractive = checkForRequiredFieldValidity(dependencies, fields);

  if (dependencyMode === 'enable') {
    return {
      isDisabledFromDependency: !isInteractive,
      isHidden: false,
    };
  }

  return {
    isDisabledFromDependency: false,
    isHidden: !isInteractive,
  };
}

function createDependencies(
  stepId: string,
  step: ConfigurationStepSchema,
  field: ConfigurationFieldSchema,
  configuration: ConfiguratorConfiguration,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
): ConfiguratorDependentOnType[] {
  const dependencies = mapDependencies(
    stepId,
    field.dependencies,
    fields,
    blueprint,
  );

  createDependencyRelations(
    dependencies,
    stepId,
    step,
    field,
    configuration,
    fields,
    blueprint,
  );

  return dependencies;
}

function mapDependencies(
  stepId: string,
  dependencies: ConfigurationDependencyType[],
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
): ConfiguratorDependentOnType[] {
  return dependencies.map((dependency) => {
    // @NOTE(shawk): `dependency.step_id` can be a `stepKey` when referencing fields in other operations
    const resolvedStepId = resolveStepKeyOrStepId(
      dependency.step_id ?? stepId,
      dependency.field_id,
      fields,
      blueprint,
    );

    switch (dependency.dependency_type) {
      case 'simple':
        return {
          stepId: resolvedStepId,
          fieldId: dependency.field_id,
          dependencyType: dependency.dependency_type,
        };

      case 'boolean':
        return {
          stepId: resolvedStepId,
          fieldId: dependency.field_id,
          dependencyType: dependency.dependency_type,
          booleanValue: dependency.boolean_value,
        };

      case 'selected_value':
        return {
          stepId: resolvedStepId,
          fieldId: dependency.field_id,
          dependencyType: dependency.dependency_type,
          selectedValueOptions: dependency.selected_value_options,
        };

      default: {
        throw new UnreachableCaseError(dependency);
      }
    }
  });
}

function resolveStepKeyOrStepId(
  stepKeyOrStepId: string,
  fieldId: string,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
) {
  try {
    const field = getOnlyFieldByStepKey(
      stepKeyOrStepId,
      fieldId,
      fields,
      blueprint,
    );
    return field.stepId;
  } catch {
    return stepKeyOrStepId;
  }
}

function createDependencyRelations(
  dependencies: ConfiguratorDependentOnType[],
  stepId: string,
  step: ConfigurationStepSchema,
  field: ConfigurationFieldSchema,
  configuration: ConfiguratorConfiguration,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
) {
  for (const dependency of dependencies) {
    const dependencyStepId = dependency.stepId;
    const dependencyKey = createFieldKey(dependencyStepId, dependency.fieldId);

    ensureRequiredFieldExists(
      dependencyStepId,
      dependencyKey,
      dependency,
      stepId,
      step,
      configuration,
      fields,
      blueprint,
    );

    fields[dependencyKey].requiredBy.push({
      stepId,
      fieldId: field.field_id,
    });
  }
}

export function removeDependencyRelations(
  field: ConfiguratorFieldType,
  fields: ConfiguratorFieldState,
) {
  for (const dependentOn of field.dependentOn) {
    const dependentOnFieldKey = createFieldKey(
      dependentOn.stepId,
      dependentOn.fieldId,
    );
    const dependentOnField = fields[dependentOnFieldKey];

    /**
     * @NOTE(shawk): When loading a snapshot, all operations are removed to
     * create a clean slate for the new configurator snapshot state. Depending
     * on the order that this happens in and the dependency relationships
     * between fields across operations, a field may have been removed already
     * and will not be found.
     *
     * This isn't a problem, since during this process we're rebuilding the
     * full configurator state from the snapshot anyway.
     */
    if (dependentOnField) {
      dependentOnField.requiredBy = dependentOnField.requiredBy.filter((r) => {
        return !(r.fieldId === field.fieldId && r.stepId === field.stepId);
      });
    }
  }
}

function ensureRequiredFieldExists(
  dependencyStepId: string,
  dependencyKey: string,
  dependency: ConfiguratorDependentOnType,
  stepId: string,
  step: ConfigurationStepSchema,
  configuration: ConfiguratorConfiguration,
  fields: ConfiguratorFieldState,
  blueprint: ConfiguratorBlueprintState,
) {
  if (!fields[dependencyKey]) {
    const { requiredStep, requiredField } = findRequiredStepAndField(
      dependencyStepId,
      dependency,
      stepId,
      step,
      configuration,
    );

    createField(
      dependencyStepId,
      requiredStep,
      requiredField,
      configuration,
      fields,
      blueprint,
    );
  }
}

function findRequiredStepAndField(
  dependencyStepId: string,
  dependency: ConfiguratorDependentOnType,
  stepId: string,
  step: ConfigurationStepSchema,
  configuration: ConfiguratorConfiguration,
): {
  requiredStep: ConfigurationStepSchema;
  requiredField: ConfigurationFieldSchema;
} {
  const requiredStep = findRequiredStep(
    dependencyStepId,
    dependency,
    stepId,
    step,
    configuration,
  );

  const requiredField = findFieldInConfiguration(
    dependencyStepId,
    dependency.fieldId,
    configuration,
  );

  return { requiredStep, requiredField };
}

function findRequiredStep(
  dependencyStepId: string,
  dependency: ConfiguratorDependentOnType,
  stepId: string,
  step: ConfigurationStepSchema,
  configuration: ConfiguratorConfiguration,
): ConfigurationStepSchema {
  if (dependency.stepId !== stepId) {
    const requiredStep = findStepInConfiguration(
      dependencyStepId,
      configuration,
    );

    validateRequiredStep(requiredStep);

    return requiredStep;
  }

  return step;
}

function findStepInConfiguration(
  stepId: string,
  configuration: ConfiguratorConfiguration,
) {
  const step = configuration.steps[stepId];

  if (!step) {
    throw new Error(`Step ${stepId} not found in configuration`);
  }

  return step;
}

function findFieldInConfiguration(
  stepId: string,
  fieldId: string,
  configuration: ConfiguratorConfiguration,
) {
  const step = findStepInConfiguration(stepId, configuration);
  const field = step.fields.find((f) => f.field_id === fieldId);

  if (!field) {
    throw new Error(`Field ${fieldId} not found in step ${stepId}`);
  }

  return field;
}

function validateRequiredStep(step: ConfigurationStepSchema) {
  if (step.step_type === 'operation') {
    if (!step.required) {
      throw new Error(
        `Fields required by a field from a different step must be in a required step. Step "${step.name}" is not required.`,
      );
    }

    if (step.reusable) {
      throw new Error(
        `Fields required by a field from a different step cannot be in a reusable step. Step "${step.name}" is reusable.`,
      );
    }
  }
}
