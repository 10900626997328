import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import type { CustomIconProps } from './types';

/**
 * Renders a Warning icon with an optional badge.
 *
 * @see Docs https://fontawesome.com/icons/warning?f=classic&s=solid
 */
export const WarningIcon: React.FC<CustomIconProps> = (props) => {
  return <Icon icon={faWarning} {...props} />;
};
