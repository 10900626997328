import type {
  ConfiguratorBlueprintState,
  ConfiguratorBlueprintType,
  ConfiguratorFieldState,
  ConfiguratorValueType,
} from '@ui/features/configurator/types';

type ConfiguratorSnapshotBlueprintStep = {
  stepId: string;
  fields: Record<string, ConfiguratorValueType>;
  summaryFields?: Record<string, ConfiguratorValueType>;
};

type SnapshotValueType = {
  value: string;
  label: string;
  meta: Record<string, unknown>;
  override?: boolean;
};

export type ConfiguratorSavedSnapshot = {
  prerequisites: ConfiguratorSnapshotBlueprintStep[];
  volumeCalculations: ConfiguratorSnapshotBlueprintStep[];
  operations: ConfiguratorSnapshotBlueprintStep[];
  stepKeyToIdMap: Record<string, string[]>;
};

const getDispatchValue = (value: ConfiguratorValueType) => {
  let dispatchValue = value;
  if (typeof value === 'object' && value?.value) {
    const snapshotValue = value as SnapshotValueType;
    dispatchValue = {
      value: snapshotValue?.value,
      label: snapshotValue?.label ?? '',
      meta: snapshotValue?.meta ?? {},
      ...(snapshotValue?.override ? { override: snapshotValue?.override } : {}),
    };
  }
  return dispatchValue;
};

const processFields = (
  blueprintFields: ConfiguratorBlueprintType['fieldKeys'],
  valueFields: ConfiguratorFieldState,
) => {
  return blueprintFields.reduce(
    (memo, key) => {
      memo[key] = getDispatchValue(valueFields[key].value);
      return memo;
    },
    {} as Record<string, ConfiguratorValueType>,
  );
};

export const serializeConfiguratorSnapshot = (
  blueprint: ConfiguratorBlueprintState,
  fields: ConfiguratorFieldState,
): string => {
  const part = {
    prerequisites: blueprint.prerequisites.map((step) => ({
      stepId: step.stepId,
      fields: processFields(step.fieldKeys, fields),
    })),
    volumeCalculations: blueprint.volumeCalculations.map((step) => ({
      stepId: step.stepId,
      fields: processFields(step.fieldKeys, fields),
    })),
    operations: blueprint.operations.map((step) => ({
      stepId: step.stepId,
      fields: processFields(step.fieldKeys, fields),
      summaryFields: processFields(step.summaryFields, fields),
    })),
    stepKeyToIdMap: blueprint.stepKeyToIdMap,
  };
  return JSON.stringify(part);
};
