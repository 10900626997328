import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { ConfiguratorInputIcon } from '@ui/features/configurator/components/inputs/InputIcon';
import { ConfiguratorInputWrapper } from '@ui/features/configurator/components/inputs/InputWrapper';
import type { ConfiguratorInputProps } from '@ui/features/configurator/components/inputs/types';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { getFieldByStepId } from '@ui/features/configurator/store/helpers/field-helpers';
import { getDisplayValue } from '@ui/features/configurator/store/helpers/get-display-value';
import type { ConfiguratorDisplayFieldType } from '@ui/features/configurator/types';
import { memo } from 'react';

function DisplayComponent({
  field,
  ...widths
}: ConfiguratorInputProps<ConfiguratorDisplayFieldType>) {
  const { fields } = useConfiguratorStore();

  if (field.dependentOn.length !== 1) {
    throw new Error(
      `Display fields must specify exactly 1 field to depend on, but ${field.fieldKey} had ${field.dependentOn.length}`,
    );
  }

  const [target] = field.dependentOn;
  const targetField = getFieldByStepId(target.stepId, target.fieldId, fields);
  const displayValue = getDisplayValue(targetField);

  return (
    <ConfiguratorInputWrapper
      field={{
        ...field,
        isInvalid: false,
        isDisabledFromDependency: false,
        isDisabledProgrammatically: displayValue === null,
      }}
      {...widths}
      maxWidth="420px"
    >
      <InputGroup ml={displayValue && targetField.leftIcon ? -3 : undefined}>
        {displayValue && targetField.leftIcon && (
          <InputLeftElement pointerEvents="none">
            <ConfiguratorInputIcon icon={targetField.leftIcon} />
          </InputLeftElement>
        )}

        <Input
          readOnly
          type="text"
          value={displayValue ?? 'Not set'}
          border="none"
          background="none"
          px={0}
          fontStyle={displayValue === null ? 'italic' : undefined}
        />

        {displayValue && targetField.rightIcon && (
          <InputRightElement pointerEvents="none">
            <ConfiguratorInputIcon icon={targetField.rightIcon} />
          </InputRightElement>
        )}
      </InputGroup>
    </ConfiguratorInputWrapper>
  );
}

export const ConfiguratorDisplay = memo(DisplayComponent);
