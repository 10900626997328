/**
 * Type guard checks if an object has values (not null or undefined) for the given keys
 *
 * NOTE: shallow check only
 *
 * @returns true if the object has values for all the given keys, and typescript will know that the
 * values are defined
 */

/** Type guard checks is all if an array's elements exist (are not null | undefined)
 *
 * @returns true if none of the elements are null or undefined
 */
export const hasElements = <const T extends unknown[]>(
  arr: T,
): arr is { [i in keyof T]: Exclude<T[i], null | undefined> } => {
  return arr.every((arg) => arg != null);
};

export const isEmptyArr = (arr: unknown[]): arr is never[] => !arr.length;

export const isKeyOf = <T extends Record<string | number | symbol, unknown>>(
  obj: T,
  key: string | number | symbol,
): key is keyof T => {
  return key in obj;
};

export const isNonNullableProp = <T, K extends keyof T>(
  obj: T,
  key: K,
): obj is T & Required<Pick<T, K>> => {
  return obj[key] != null;
};

export const isDefinedProp = <T, K extends keyof T>(
  obj: T,
  key: K,
): obj is T & Record<K, Exclude<T[K], undefined>> => {
  return obj[key] != null;
};

export const isInSet = <T, E>(set: Set<T>, el: E): el is T & E => {
  return set.has(el as unknown as T);
};
