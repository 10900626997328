import is from '@sindresorhus/is';
import { inputSources } from '@ui/features/configurator/data';
import type {
  ConfiguratorFieldState,
  ConfiguratorFieldType,
} from '@ui/features/configurator/types';
import type { SelectOptions } from '@ui/util';

export function getStaticOptions(
  field: ConfiguratorFieldType,
  fields: ConfiguratorFieldState,
): SelectOptions {
  if (field.inputSource?.source_type !== 'static') {
    throw new Error('Field is not a static option');
  }

  const source = inputSources[field.inputSource.data_key];

  if (!source) {
    throw new Error(
      `No static options defined for "${field.inputSource.data_key}"`,
    );
  }

  if (is.function(source)) {
    return source(fields, field);
  }

  return source;
}

export function findStaticOption(
  field: ConfiguratorFieldType,
  fields: ConfiguratorFieldState,
  value: unknown,
) {
  const options = getStaticOptions(field, fields);
  const selectedOption = options.find((o) => o.value === value);

  return selectedOption ?? null;
}
