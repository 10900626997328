import { Box } from '@chakra-ui/react';
import Loading from './Loading';

export function FullScreenLoader({ loadingText }: { loadingText?: string }) {
  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Loading loadingText={loadingText} />
    </Box>
  );
}
