import { Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { Avatar as ChakraAvatar } from '@chakra-ui/react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../state/auth';
import { menuProps } from '../../util/menuProps';

type AvatarProps = {
  showDisplayName?: boolean;
};

const Avatar: FC<AvatarProps> = ({ showDisplayName }) => {
  const { user, logout } = useAuth();

  const buttonStyles = {
    width: '100%',
    '> span': {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
      width: '100%',
      gap: 10,
      justifyContent: 'center',
    },
  };

  return (
    <Menu>
      <MenuButton width="100%" css={showDisplayName ? buttonStyles : null}>
        <ChakraAvatar
          name={user?.displayName}
          bgColor="mw.darkBlue"
          textColor="white"
          size="sm"
        />
        <Text
          display={'inline-block'}
          maxWidth={showDisplayName ? 'unset' : 0}
          maxHeight={showDisplayName ? 'unset' : 0}
          opacity={showDisplayName ? 1 : 0}
          whiteSpace="nowrap"
          transition="max-width 160ms linear, max-height 160ms linear, opacity 200ms linear"
          overflow="hidden"
        >
          {user?.displayName}
        </Text>
      </MenuButton>
      <MenuList
        borderRadius={0}
        color={'mw.lightGrey'}
        backgroundColor={'mw.darkGrey'}
        p={'1.5rem'}
        border="none"
      >
        <MenuItem as={RouterLink} to="/profile" {...menuProps.item}>
          Profile
        </MenuItem>
        <MenuItem onClick={() => logout()} {...menuProps.item}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default Avatar;
