import { Center, Text } from '@chakra-ui/react';
import PageWrapper from '../PageWrapper';
import PageHeader from '../layout/PageHeader';

const ErrorUnauthorized = () => {
  return (
    <PageWrapper>
      <PageHeader title="Error: Unauthorized" />
      <Center>
        <Text color="red">You are unauthorized to view this page</Text>
      </Center>
    </PageWrapper>
  );
};

export default ErrorUnauthorized;
