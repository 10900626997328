import type { OpCostCalcsMemo } from '@lib/calculations/costs/operation';
import { operationCostCalcs } from '@lib/calculations/costs/operation';
import type { VisOpRunRateType } from '@lib/kysely/visual/types/operation';
import { runRateTypeToSnapMap } from '@lib/models';
import type { AvailableSite } from '@prisma-types';
import {
  type DisplayMagnitude,
  RunRateType,
  type ScrapYieldType,
  UnitsPerPieceType,
} from '@prisma/client';

export type ConfiguratorCostsOpInput = {
  resourceId: string;
  sequenceNum: number;
  run: number;
  runType: VisOpRunRateType;
  burdenPerOperation: number;
  burdenPerHrRun: number;
  burdenPerUnitRun: number;
  burdenPerHrSetup: number;
  runCostPerHr: number;
  runCostPerUnit: number;
  setupCostPerHr: number;
  setupHrs: number;
  serviceChargePerUnit: number;
  serviceBaseCharge: number;
  serviceMinCharge: number;
  serviceId: string;
  scrapYieldType: ScrapYieldType;
  scrapYieldPercent: number;
  fixedScrap: number;
};

export type ConfiguratorCostsInput = ConfiguratorCostsOpInput[];

export type ConfiguratorCostsOutput = OpCostCalcsMemo;

export function configuratorOpToSnap(
  site: AvailableSite,
  quantity: number,
  op: ConfiguratorCostsOpInput,
  quantityDisplayMagnitude: DisplayMagnitude,
  matPartId?: string | null,
  matUnitCost?: number,
  matQtyPerPiece?: number,
  washerMatPartId?: string | null,
  washerMatUnitCost?: number | null,
) {
  const runRateType = runRateTypeToSnapMap[op.runType];

  /**
   * `operationCostCalcs` doesn't know how to calculate costs for run rates
   * other than pieces per hour.
   */
  if (runRateType !== RunRateType.PCS_PER_HR) {
    throw new Error(
      `Expected operation ${op.sequenceNum} to have runType "PCS_PER_HR"; received "${runRateType}"`,
    );
  }

  /**
   * @WORKAROUND(shawk): Requested by the client on 10/16/2024 - they were seeing
   * numbers that "look way off" for ADF (which uses a quantity display magnitude
   * of THOUSANDS, i.e. qty: 1 means 1000 pieces).
   *
   * For sites that measure quantities in the thousands, we need to multiply
   * the run rate by 1000 before doing any math with it.
   *
   * The extra (seemingly redundant) Run Rate Type check here is important so that
   * this logic isn't applied incorrectly if/when we later support more than
   * just `PCS_PER_HR`.
   */
  const runRate = op.run;
  // if (
  //   quantityDisplayMagnitude === DisplayMagnitude.THOUSANDS &&
  //   runRateType === RunRateType.PCS_PER_HR
  // ) {
  //   runRate *= 1000;
  // }

  let pieceIdx = 0;

  /**
   * Create dummy requirements here to calculate material cost.
   * The only relevant fields for us here are unitsPerPiece, matUnitCost, and washerMatUnitCost for now
   */
  const requirements = [];
  if (matUnitCost && quantity && matQtyPerPiece) {
    requirements.push({
      basisQty: quantity,
      unitsPerPiece: matQtyPerPiece,
      matUnitCost,
      reqPartId: matPartId ?? null,
      reqPartOriginalSource: matPartId ? site.originalSource : null,
      pieceNum: ++pieceIdx * 10,

      // dummy everything else
      fixedQty: 0,
      qtyPerLineQty: 1,
      matMinCost: 0,
      matFixedCost: 0,
      labUnitCost: 0,
      burUnitCost: 0,
      srvUnitCost: 0,
      burPct: 0,
      burPerUnit: 0,
      usageUM: null,
      unitsPerPieceType: UnitsPerPieceType.START,
      calcQtyFromOp: 0,
      calcQtyFromLine: 0,
      qtyPerOpQty: 0,
    });
  }

  if (washerMatUnitCost && quantity) {
    requirements.push({
      basisQty: quantity,
      unitsPerPiece: 1, // @NOTE(shawk): SEMS/washer materials are always in Eaches (1 unit per piece)
      matUnitCost: washerMatUnitCost,
      reqPartId: washerMatPartId ?? null,
      reqPartOriginalSource: washerMatPartId ? site.originalSource : null,
      pieceNum: ++pieceIdx * 10,

      // dummy everything else
      fixedQty: 0,
      qtyPerLineQty: 1,
      matMinCost: 0,
      matFixedCost: 0,
      labUnitCost: 0,
      burUnitCost: 0,
      srvUnitCost: 0,
      burPct: 0,
      burPerUnit: 0,
      usageUM: null,
      unitsPerPieceType: UnitsPerPieceType.START,
      calcQtyFromOp: 0,
      calcQtyFromLine: 0,
      qtyPerOpQty: 0,
    });
  }

  return {
    // Operation metadata
    resourceId: op.resourceId,
    sequenceNum: op.sequenceNum,

    requirements,

    // Calculation values
    runRate,
    runRateType,
    setupHrs: op.setupHrs,
    labRunCostPerHr: op.runCostPerHr,
    labRunCostPerUnit: op.runCostPerUnit,
    labSetupCostPerHr: op.setupCostPerHr,
    burRunCostPerHr: op.burdenPerHrRun,
    burSetupCostPerHr: op.burdenPerHrSetup,
    burRunCostPerUnit: op.burdenPerUnitRun,
    burCostPerOperation: op.burdenPerOperation,

    // Service charge values
    srvChargePerUnit: op.serviceChargePerUnit,
    srvBaseCharge: op.serviceBaseCharge,
    srvMinCharge: op.serviceMinCharge,
    serviceId: op.serviceId,

    // Hard-code to 1.
    // This will be replaced by Jeremy's new scrap code with updated quantity calculations.
    qtyPerLineQty: 1,

    // @TODO(shawk): why do we pass both `runRate` and `qtyPerRunHr`? The latter
    // is what's used in the SNAP cost calcs, but appears to be a temporary way
    // to account for PCS/HR per the comment in packages/lib/src/calculations/costs/operation/types.ts#L28
    qtyPerRunHr: runRate,

    // @TODO: NT - Where should this come from?
    // It looks like this isn't used for PCS_PER_HR calculations
    estLoadSizeQty: 1,

    // These fields are not used by SNAP calcs or are unnecessary for the Configurator.
    basisQty: null,
    basisRunHrs: null,
    calcQty: null,
    calcRunHrs: null,
    runHrsPerLineQty: null,
    servicePartId: null,

    scrapFixedUnits: op.fixedScrap,
    scrapYieldType: op.scrapYieldType,
    scrapYieldPercent: op.scrapYieldPercent,

    /**
     * See: https://teams.microsoft.com/l/message/19:ad83bd7f418c4d46a0965d7c2dc44383@thread.v2/1725376049348?context=%7B%22contextType%22%3A%22chat%22%7D
     * calcStartQty is mostly for debug, tells you what the "input"
     * quantity for an operation based on scrap values and its given output
     */
    calcStartQty: null,
  };
}

export function calculateConfiguratorCosts(
  site: AvailableSite,
  quantity: number,
  ops: ConfiguratorCostsInput,
  matPartId: string | null,
  matUnitCost: number,
  matQtyPer: number,
  washerMatPartId: string | null,
  washerMatUnitCost: number | null,
  quantityDisplayMagnitude: DisplayMagnitude,
): ConfiguratorCostsOutput {
  const snapOps = ops.map((op) =>
    // only calculate requirements for op 10 because we only have one requirement material field right now
    // TODO: allow additional requirements for each op
    {
      if (op.sequenceNum === 10) {
        return configuratorOpToSnap(
          site,
          quantity,
          op,
          quantityDisplayMagnitude,
          matPartId,
          matUnitCost,
          matQtyPer,
          washerMatPartId,
          washerMatUnitCost,
        );
      }
      return configuratorOpToSnap(site, quantity, op, quantityDisplayMagnitude);
    },
  );

  return operationCostCalcs(snapOps, quantity);
}
