/**
 * PageHeader:
 *
 * A component that renders a page's title and subtitle, and an optional action buttons.
 * buttons are rendered in the top right corner of the header.
 * Buttons should contain actions specific to the page, such as "Add" or "Edit".
 *
 * Any site navigation links should be rendered in the NavigationBar instead.
 */
import { Box, Flex, Heading } from '@chakra-ui/react';
import type { FC } from 'react';

interface PageHeaderProps {
  title?: string;
  subtitle?: string | React.ReactNode;
  children?: React.ReactNode;
  bgColor?: string;
  textColor?: string;
}

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  subtitle,
  children,
  bgColor,
  textColor,
}) => {
  return (
    <Flex
      width="100%"
      alignItems="center"
      px={8}
      py={5}
      bg={bgColor ?? 'mw.panelGrey'}
      justifyContent="space-between"
      flexShrink={0}
      minHeight={20}
    >
      <Box>
        <Heading
          fontFamily="sans-serif"
          fontWeight={100}
          fontSize={30}
          color={textColor ?? 'mw.darkGrey'}
        >
          {title}
        </Heading>

        {subtitle && (
          <Heading
            fontFamily="Source+Sans+Pro, sans-serif"
            fontWeight="400"
            fontSize={16}
            mt={2}
            color={textColor ?? 'mw.darkGrey'}
          >
            {subtitle}
          </Heading>
        )}
      </Box>

      <Box marginLeft="auto" flex={1} justifyItems="center">
        <Flex
          alignContent="end"
          direction={{ base: 'column-reverse', md: 'row' }}
          gap={2}
          justifyContent="end"
        >
          {children}
        </Flex>
      </Box>
    </Flex>
  );
};

export default PageHeader;
