import { Heading as ChakraHeading, type HeadingProps } from '@chakra-ui/react';

export const Heading = ({ children, ...props }: HeadingProps) => {
  return (
    <ChakraHeading
      fontFamily={'Source+Sans+Pro, sans-serif'}
      fontWeight="bold"
      fontSize={18}
      mt={2}
      color={'mw.darkGrey'}
      {...props}
    >
      {children}
    </ChakraHeading>
  );
};
