import type { AdminSiteUpdateRequest } from '@lib/requests';
import type { AdminSiteIndexResponse, AdminSiteResponse } from '@lib/responses';
import type { API } from '../api';

export class AdminSiteService {
  constructor(private api: API) {}

  getAll = () => {
    return this.api.get<AdminSiteIndexResponse>('/admin/sites');
  };

  update = (id: string, site: AdminSiteUpdateRequest) => {
    return this.api.put<AdminSiteResponse>(`/admin/sites/${id}`, site);
  };
}
