import type { API } from './api';

export class DocumentService {
  constructor(private api: API) {}

  getDocument = (documentId: string) => {
    return this.api.getArrayBuffer<Uint8Array>(`/document/${documentId}`);
  };

  deleteDocument = (documentId: string) => {
    return this.api.delete<string>(`/document/${documentId}`);
  };
}
